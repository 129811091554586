import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
// import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import ShopIcon from "@mui/icons-material/Shop";
import { Link } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const PREFIX = "UserMenu";

const classes = {
  avatarButton: `${PREFIX}-avatarButton`,
  avatar: `${PREFIX}-avatar`,
  listItemIcon: `${PREFIX}-listItemIcon`,
};

const Root = styled("span")(({ theme }) => ({
  [`& .${classes.avatarButton}`]: {
    padding: 9,
    [theme.breakpoints.up("sm")]: {
      padding: 4,
    },
  },

  [`& .${classes.avatar}`]: {
    width: 30,
    height: 30,
    borderRadius: 30,

    [theme.breakpoints.up("sm")]: {
      width: 40,
      height: 40,
      borderRadius: 40,
    },
  },

  [`& .${classes.listItemIcon}`]: {
    marginRight: 16,
    minWidth: 40,
    justifyContent: "center",
  },
}));

export default function UserMenu({
  viewer,
  onClickSettings,
  onClickSignOut,
  onClickPurchases,
}) {
  const { t } = useTranslation("arena");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <Tooltip title={t("userMenu.tooltip")}>
        <IconButton
          className={classes.avatarButton + " js-user-settings"}
          onClick={handleClick}
          edge="end"
          size="large"
        >
          <Avatar
            src={viewer.photo?.url}
            className={classes.avatar}
            alt="Profile photo"
          >
            {(viewer.fullName || viewer.email)[0].toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem divider>
          <ListItemAvatar>
            <Avatar src={viewer.photo?.url}>
              {(viewer.fullName || viewer.email)[0].toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={viewer.fullName} secondary={viewer.email} />
        </ListItem>

        <MenuItem
          onClick={() => {
            onClickSettings();
            handleClose();
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsIcon color="disabled" />
          </ListItemIcon>
          <ListItemText primary={t("userMenu.settings")} />
        </MenuItem>

        <MenuItem
          component={Link}
          to="/purchases"
          onClick={() => {
            onClickPurchases();
            handleClose();
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <ShopIcon color="disabled" />
          </ListItemIcon>
          <ListItemText primary={t("userMenu.purchases")} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            onClickSignOut();
            handleClose();
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <PowerSettingsNewIcon color="disabled" />
          </ListItemIcon>
          <ListItemText primary={t("userMenu.logout")} />
        </MenuItem>
      </Menu>
    </Root>
  );
}

UserMenu.propTypes = {
  onClickPurchases: PropTypes.func,
  onClickSignOut: PropTypes.func.isRequired,
  onClickSettings: PropTypes.func.isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    email: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};
