import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { SponsorPlayerLogoQuery } from "core/queries";
import { useQuery } from "@apollo/client";
import {
  createSponsorImpression,
  createSponsorClick,
  trackAdPixel,
} from "core/utils/tracker";
import classNames from "classnames";
import ButtonBase from "@mui/material/ButtonBase";
import { getLogoSrcSet } from "core/utils/getSrcSet";
import { nanoid } from "nanoid";

const PREFIX = "SponsorPlayerLogo";

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  topLeft: `${PREFIX}-topLeft`,
  topRight: `${PREFIX}-topRight`,
  bottomLeft: `${PREFIX}-bottomLeft`,
  bottomRight: `${PREFIX}-bottomRight`,
  visible: `${PREFIX}-visible`,
  footerVisible: `${PREFIX}-footerVisible`,
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  display: "none",
  position: "absolute",
  zIndex: 1000,
  transition: "bottom 225ms cubic-bezier(0, 0, 0.2, 1)",

  width: 30,
  height: 30,

  ["@media (min-width: 375px)"]: {
    width: 40,
    height: 40,
  },

  [theme.breakpoints.up("sm")]: {
    width: 50,
    height: 50,
  },

  [theme.breakpoints.up("md")]: {
    width: 60,
    height: 60,
  },

  [theme.breakpoints.up("lg")]: {
    width: 70,
    height: 70,
  },

  [theme.breakpoints.up("xl")]: {
    width: 80,
    height: 80,
  },

  [`& .${classes.image}`]: {
    width: "100%",
    height: "100%",
    display: "block",
    margin: 0,
  },

  [`&.${classes.topLeft}`]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    ["@media (min-width: 375px)"]: {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(3),
      left: theme.spacing(3),
    },
  },

  [`&.${classes.topRight}`]: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    ["@media (min-width: 375px)"]: {
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  },

  [`&.${classes.bottomLeft}`]: {
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    ["@media (min-width: 375px)"]: {
      bottom: theme.spacing(2),
      left: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(3),
      left: theme.spacing(3),
    },

    [`&.${classes.footerVisible}`]: {
      bottom: 66,
      ["@media (min-width: 375px)"]: {
        bottom: 76,
      },
    },
  },

  [`&.${classes.bottomRight}`]: {
    bottom: theme.spacing(1),
    right: theme.spacing(1),

    ["@media (min-width: 375px)"]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },

    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },

    [`&.${classes.footerVisible}`]: {
      bottom: 66,
      ["@media (min-width: 375px)"]: {
        bottom: 76,
      },
    },
  },

  [`&.${classes.visible}`]: {
    display: "flex",
  },

  [`&.${classes.footerVisible}`]: {},
}));

const VIDEO_IN_EMBED = "VIDEO_IN_EMBED";
const STREAM_IN_EMBED = "STREAM_IN_EMBED";
const VIDEO_ON_SITE = "VIDEO_ON_SITE";
const STREAM_ON_SITE = "STREAM_ON_SITE";
const IMPRESSION = "IMPRESSION";
const SMALL = "SMALL";
const MEDIUM = "MEDIUM";
const LARGE = "LARGE";
const TOP_LEFT = "TOP_LEFT";
const TOP_RIGHT = "TOP_RIGHT";
const BOTTOM_LEFT = "BOTTOM_LEFT";
const BOTTOM_RIGHT = "BOTTOM_RIGHT";
const MAXRES = "MAXRES";

export default function SponsorPlayerLogo({
  place,
  contentId,
  rightholderId,
  platformId,
  visible,
  footerVisible,
  previewBanner,
  embedId,
}) {
  const { data, loading } = useQuery(SponsorPlayerLogoQuery, {
    skip: !process.browser || !!previewBanner,
    fetchPolicy: "cache-and-network",
    variables: {
      platformId,
      contentId,
      rightholderId,
      place,
      embedId: embedId || undefined,
    },
  });

  if (previewBanner) {
    return (
      <StyledButtonBase
        className={classNames({
          [classes.root]: true,
          [classes.small]: true,
          [classes.topLeft]: true,
          [classes.visible]: visible,
          [classes.footerVisible]: footerVisible,
        })}
      >
        <img src={previewBanner} alt={place} className={classes.image} />
      </StyledButtonBase>
    );
  }

  if (loading) return null;
  if (!data?.sponsorCampaign) return null;

  const {
    id,
    link,
    token,
    size,
    position,
    thumbnails,
    isClickable,
    clickPixel,
    impressionPixel,
  } = data.sponsorCampaign.playerLogo;

  const onClick = async (event) => {
    event.preventDefault();

    if (isClickable) {
      if (clickPixel) {
        trackAdPixel(clickPixel);
      }

      const clickId = nanoid();
      const url = link.replace("{clickId}", clickId);

      window.open(url, "_blank");

      await createSponsorClick(token, clickId);
    }
  };
  const onLoad = () => {
    createSponsorImpression(token, "Player Logo", IMPRESSION);

    if (impressionPixel) {
      trackAdPixel(impressionPixel);
    }
  };

  const srcSet = getLogoSrcSet(thumbnails);

  const src = thumbnails?.find((t) => t.format === MAXRES)?.url;

  // const Component = isClickable ? ButtonBase : "div";

  return (
    <StyledButtonBase
      onClick={onClick}
      className={classNames({
        [classes.root]: true,
        [classes.small]: size === SMALL,
        [classes.medium]: size === MEDIUM,
        [classes.large]: size === LARGE,
        [classes.topLeft]: position === TOP_LEFT,
        [classes.topRight]: position === TOP_RIGHT,
        [classes.bottomLeft]: position === BOTTOM_LEFT,
        [classes.bottomRight]: position === BOTTOM_RIGHT,
        [classes.visible]: visible,
        [classes.footerVisible]: footerVisible,
      })}
      key={id}
    >
      <img
        src={src}
        srcSet={srcSet}
        alt={place}
        className={classes.image}
        onLoad={onLoad}
      />
    </StyledButtonBase>
  );
}

SponsorPlayerLogo.propTypes = {
  place: PropTypes.oneOf([
    VIDEO_IN_EMBED,
    STREAM_IN_EMBED,
    VIDEO_ON_SITE,
    STREAM_ON_SITE,
  ]).isRequired,
  previewBanner: PropTypes.string,
  platformId: PropTypes.string,
  contentId: PropTypes.string,
  rightholderId: PropTypes.string,
  visible: PropTypes.bool,
  footerVisible: PropTypes.bool,
  embedId: PropTypes.string,
};
