import React from "react";
import { styled } from "@mui/material/styles";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import ArenaContents from "arena/components/ArenaContents";
import { useTranslation } from "react-i18next";
import { useArena } from "arena/components/ArenaProvider";
import { useQuery } from "@apollo/client";
import { ProductContentsQuery } from "arena/queries";
import { Link, useLocation } from "react-router-dom";
import MuiLink from "@mui/material/Link";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
  },
}));

const VIDEO = "VIDEO";
const STREAM = "STREAM";
const COLLECTION = "COLLECTION";
const LIMIT = 12; // кол-во контентов на страницу

function renderTitle(product, title) {
  return (
    <>
      <MuiLink
        component={Link}
        to={product.siteUrl}
        color="textPrimary"
        underline="hover"
      >
        {product.title}
      </MuiLink>
      {title && " / " + title}
    </>
  );
}

function getTitle(title, type, t) {
  if (title) {
    return title;
  }

  switch (type) {
    case VIDEO:
      return t("contents.videos");
    case STREAM:
      return t("contents.streams");
    case COLLECTION:
      return t("contents.collections");
    default:
      return t("contents.all");
  }
}

function getBlock(pages, blockId) {
  let block = null;

  pages.forEach((page) => {
    const _block = page.blocks.find((block) => block.id === blockId);

    if (_block) {
      block = _block;
    }
  });

  return block;
}

export default function Contents() {
  const arena = useArena();
  const location = useLocation();

  const { t } = useTranslation("arena");

  usePageview(location.pathname, "Contents");
  useScrollToTop();

  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status")?.toUpperCase();
  const type = searchParams.get("type")?.toUpperCase();
  const period = searchParams.get("period");
  const blockId = searchParams.get("blockId");
  const productId = searchParams.get("productId");
  const tagIds = searchParams.getAll("tagId");
  const rightholderIds = searchParams.getAll("rightholderId");

  const { data } = useQuery(ProductContentsQuery, {
    skip: !productId,
    variables: {
      id: productId,
    },
  });

  const block = getBlock(arena.pages, blockId);
  let title = getTitle(block?.props?.title, type, t);

  if (data?.product) {
    title = renderTitle(data.product, title);
  }

  return (
    <Root>
      <ArenaContents
        arena={arena}
        view="GRID"
        type={type}
        sort="LATEST"
        limit={LIMIT}
        title={title}
        tagIds={tagIds}
        rightholderIds={rightholderIds}
        productId={productId}
        period={period}
        status={status}
        noMore
        loadMore
        inView
      />
    </Root>
  );
}
