const COLLECTION = "COLLECTION";
const VIDEO = "VIDEO";
const STREAM = "STREAM";
const STARTED = "STARTED";
const PENDING = "PENDING";
const FINISHED = "FINISHED";

export default function getContentMeta(t, content) {
  if (content.type === STREAM) {
    switch (content.streamData.status) {
      case PENDING:
        if (content.startAt) {
          return t("contentListItem.meta.scheduled", {
            viewers: content.viewersOnline,
            date: content.startAt,
          });
        }
        return t("contentListItem.meta.soon", {
          viewers: content.viewersOnline,
          date: content.publishAt,
        });

      case STARTED:
        return t("contentListItem.meta.started", {
          count: content.viewersOnline,
          date: content.streamData.startAt,
        });

      case FINISHED:
        return t("contentListItem.meta.finished", {
          viewers: content.viewersOnline,
          date: content.streamData.finishAt,
        });
    }
  }

  if (content.type === VIDEO || content.type === COLLECTION) {
    return t("contentListItem.meta.video", {
      count: content.totalViews,
      date: content.publishAt,
    });
  }
}
