import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PostItem({ post }) {
  const { t } = useTranslation("arena");
  return (
    <Card sx={{ display: "flex" }}>
      <CardActionArea component={Link} to={post.url}>
        <CardMedia
          component="img"
          image={post?.image?.url}
          alt={post.title}
          height="300"
        />

        <CardHeader
          title={post.title}
          subheader={post.description}
          titleTypographyProps={{
            variant: "h6",

            sx: { fontWeight: 600, m: 0, mb: 1, lineHeight: 1.3 },
          }}
          // subheader={t("publishAt", { date: post.publishAt })}
          sx={{ position: "relative", mt: "auto" }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ pl: 2, pr: 2, pb: 2 }}
        >
          {t("publishAt", { date: post.publishAt })}
        </Typography>
      </CardActionArea>
    </Card>
  );
}

PostItem.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    publishAt: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
  }).isRequired,
};
