import React from "react";
import { styled } from "@mui/material/styles";
import ContentPlaylist from "arena/components/ContentPlaylist";
import ContentPlayer from "arena/components/ContentPlayer";
import ContentBody from "arena/components/ContentBody";
import NextPlaylistContentButton from "arena/components/NextPlaylistContentButton";
import ErrorScreen from "core/components/ErrorScreen";
import useScrollToTop from "core/hooks/useScrollToTop";
import { ContentQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { createEvent, setToken } from "core/utils/tracker";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import NoMatchScreen from "arena/components/NoMatchScreen";
import ContentHelmet from "arena/components/ContentHelmet";
import { useArena } from "arena/components/ArenaProvider";

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(0),
}));

const Section = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const Inner = styled("div", {
  shouldForwardProp: (prop) => !["playlist"].includes(prop),
})(({ theme, playlist }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginRight: playlist ? 400 : 0,
  },
}));

const Playlist = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
  flexShrink: 0,
  [theme.breakpoints.up("md")]: {
    width: 400,
  },
}));

const VIDEO = "VIDEO";
const VIDEO_ON_SITE = "VIDEO_ON_SITE";

function useQueryParams() {
  const [searchParams] = useSearchParams();

  const productId = searchParams.get("productId");
  const collectionId = searchParams.get("collectionId");

  const start = searchParams.has("start")
    ? parseInt(searchParams.get("start"), 10)
    : null;

  const finish = searchParams.has("finish")
    ? parseInt(searchParams.get("finish"), 10)
    : null;

  return { collectionId, productId, start, finish };
}

export default function Video() {
  const arena = useArena();
  const location = useLocation();
  const { contentId } = useParams();
  const { collectionId, productId, start, finish } = useQueryParams();

  useScrollToTop();

  const onCompleted = (data) => {
    if (data.content) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.content.title,
      });

      setToken(data.content.access.token);
      createEvent("page", "load");
    }
  };

  const { data, loading, error, refetch } = useQuery(ContentQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      contentId,
    },
    onCompleted,
  });

  const content = data?.content;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!content && !loading) {
    return <NoMatchScreen />;
  }

  if (content && content.type !== VIDEO) {
    return (
      <Navigate
        to={content.siteUrl.replace("video", content.type.toLowerCase())}
      />
    );
  }

  return (
    <>
      <Root data-testid="Video">
        <ContentHelmet content={content} arena={arena} />

        <Section>
          <Inner key={contentId} playlist={Boolean(productId)}>
            <ContentPlayer
              sponsorPlayerLogoPlace={VIDEO_ON_SITE}
              sponsorPlayerButtonPlace={VIDEO_ON_SITE}
              sponsorVideoPlace={VIDEO_ON_SITE}
              sponsorInStreamBannerPlace={VIDEO_ON_SITE}
              sponsorCornerBannerPlace={VIDEO_ON_SITE}
              hideTitle
              autoPlay
              content={content}
              loading={loading}
              start={start}
              finish={finish}
              refetch={refetch}
              nextPlaylistContentButton={({
                videoState: { duration, currentTime },
                visible,
              }) => {
                if (!duration) return null;
                if (!currentTime) return null;
                if (!productId) return null;

                const timeLeft = duration - currentTime;

                return (
                  <NextPlaylistContentButton
                    visible={visible}
                    timeLeft={timeLeft}
                    contentId={contentId}
                    productId={productId}
                    collectionId={collectionId}
                  />
                );
              }}
            />
          </Inner>
        </Section>

        <Section>
          <Inner key={contentId} playlist={Boolean(productId)}>
            <ContentBody content={content} loading={loading} padding />
          </Inner>
        </Section>

        {productId && (
          <Playlist>
            <ContentPlaylist
              productId={productId}
              contentId={contentId}
              collectionId={collectionId}
              type="VIDEO"
            />
          </Playlist>
        )}
      </Root>
    </>
  );
}
