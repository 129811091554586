import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ArenaContents from "arena/components/ArenaContents";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { useQuery } from "@apollo/client";
import { ContentPlaylistQuery } from "arena/queries";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: 400,
    position: "fixed",
    top: 64,
    bottom: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  width: "100%",
  height: "100%",
  padding: 0,

  margin: 0,
  overflowX: "hidden",
  overflowY: "auto",
  zIndex: 1,
  scrollBehavior: "smooth",
  msOverflowStyle: "none", // IE 10+
  scrollbarWidth: "none", // Firefox
  "&::-webkit-scrollbar": {
    display: "none", // Safari and Chrome
  },
  "&:last-child": {
    paddingBottom: 0,
  },
}));

export default function ContentPlaylist({
  productId,
  contentId,
  collectionId,
  type,
}) {
  const { data } = useQuery(ContentPlaylistQuery, {
    variables: {
      productId,
    },
  });

  const title = data?.product?.title;
  const link = data?.product?.siteUrl;

  return (
    <StyledCard square>
      <CardHeader
        title={title}
        titleTypographyProps={{ sx: { fontWeight: 800 } }}
        avatar={
          link && (
            <IconButton component={Link} to={link}>
              <ArrowBackIcon />
            </IconButton>
          )
        }
      />
      <StyledCardContent>
        <ArenaContents
          productId={productId}
          selectedContentId={contentId}
          relatedContentId={collectionId}
          view="LIST"
          type={type}
          limit={6}
          loadMore
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inView
        />
      </StyledCardContent>
    </StyledCard>
  );
}

ContentPlaylist.propTypes = {
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  contentId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
