import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ArenaLogosQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import HomepageTitle from "arena/components/HomepageTitle";
import ButtonBase from "@mui/material/ButtonBase";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useCarousel from "core/hooks/useCarousel";
import Item from "./Item";
import { alpha } from "@mui/system";

const PREFIX = "ArenaPartners";

const classes = {
  scrollable: `${PREFIX}-scrollable`,
  item: `${PREFIX}-item`,
  prev: `${PREFIX}-prev`,
  next: `${PREFIX}-next`,
  btnRoot: `${PREFIX}-btnRoot`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",

  [`& .${classes.scrollable}`]: {
    padding: theme.spacing(0, 2),

    overflowY: "hidden",
    overflowX: "auto",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 3),
    },
  },

  [`& .${classes.item}`]: {},

  [`& .${classes.prev}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      left: 0,
      backgroundImage: `linear-gradient(90deg, ${
        theme.palette.background.body
      }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.next}`]: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      right: 0,
      backgroundImage: `linear-gradient(-90deg, ${
        theme.palette.background.body
      }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.btnRoot}`]: {
    pointerEvents: "all",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 60,
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 0.3)",
    "&:hover": {
      color: "#fff",
    },

    [theme.breakpoints.up("sm")]: {
      width: 90,
    },
    [theme.breakpoints.up("md")]: {
      width: 100,
    },
  },

  [`& .${classes.icon}`]: {
    fontSize: 60,
  },
}));

const StyledHomepageTitle = styled(HomepageTitle)(({ theme }) => ({
  padding: theme.spacing(5, 2, 1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 3, 1),
  },
}));

const indexBy = (items) =>
  items.reduce((all, item) => {
    all[item.id] = item.url;
    return all;
  }, {});

export default function ArenaPartners({ logos, title }) {
  const { data } = useQuery(ArenaLogosQuery, {
    variables: {
      ids: logos.map((logo) => logo.logoId),
    },
  });

  const { rootRef, onScroll, prev, next, onClickNext, onClickPrev } =
    useCarousel();

  if (!data?.logos?.length) return null;

  const logoImageUrlById = indexBy(data.logos);
  const nodes = logos.map((logo) => {
    return {
      id: logo.logoId,
      title: logo.title,
      url: logo.url,
      src: logoImageUrlById[logo.logoId],
      openInNewWindow: logo.openInNewWindow,
    };
  });

  return (
    <>
      {title && <StyledHomepageTitle title={title} />}
      <Root>
        <div className={classes.scrollable} ref={rootRef} onScroll={onScroll}>
          <Grid container spacing={3} wrap="nowrap">
            {nodes.map((logo) => (
              <Grid item key={logo.id} className={classes.item}>
                <Item logo={logo} />
              </Grid>
            ))}
          </Grid>
        </div>

        {prev && (
          <div className={classes.prev}>
            <ButtonBase
              classes={{ root: classes.btnRoot }}
              onClick={onClickPrev}
            >
              <ChevronLeftIcon className={classes.icon} />
            </ButtonBase>
          </div>
        )}
        {next && (
          <div className={classes.next}>
            <ButtonBase
              classes={{ root: classes.btnRoot }}
              onClick={onClickNext}
            >
              <ChevronRightIcon className={classes.icon} />
            </ButtonBase>
          </div>
        )}
      </Root>
    </>
  );
}

ArenaPartners.propTypes = {
  title: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      logoId: PropTypes.string.isRequired,
    })
  ),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
