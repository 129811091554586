import moment from "moment-timezone";
import "moment/locale/ru";
import "moment/locale/pl";
import "moment/locale/es";

export const setTimeZone = (timeZone) => {
  moment.tz.setDefault(timeZone);
};

export const getTimeZone = () => {
  return moment.tz.guess();
};

export default moment;
