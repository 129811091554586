import React from "react";
import PropTypes from "prop-types";
import SponsorVideo from "core/components/SponsorVideo";
import PlayerPreview from "core/components/PlayerPreview";
import PlayerFooter from "core/components/PlayerFooter";
import Vast from "core/components/SponsorVideo/Vast";

export default function SponsorPreAdv({
  embedId,
  platformId,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  fullscreenTarget,
  onChatAdv,
  muted,
  onStart,
  onFinish,
  onNotFound,
  content,
  hideShare,
  hideLogo,
  dubugVastUrl,
}) {
  if (!process.browser) return null;

  const videoPlace = "PREROLL_IN_" + sponsorVideoPlace;
  const chatMessagePlace = sponsorChatMessagePlace
    ? "PREROLL_IN_" + sponsorChatMessagePlace
    : null;

  if (dubugVastUrl) {
    const vast = {
      url: dubugVastUrl,
      adLabel: true,
      timeout: 60000,
    };

    return (
      <Vast
        vast={vast}
        vastPlace={videoPlace}
        fullscreenTarget={fullscreenTarget}
        onFinish={onFinish}
        onStart={onStart}
        muted={muted}
      />
    );
  }

  return (
    <PlayerPreview preview={content.preview}>
      <SponsorVideo
        shareUrl={content?.shareUrl}
        embedId={embedId}
        chatMessagePlace={chatMessagePlace}
        videoPlace={videoPlace}
        contentId={content.id}
        sportId={content?.sport?.id}
        rightholderId={content.rightholder.id}
        platformId={platformId}
        fullscreenTarget={fullscreenTarget}
        onStart={onStart}
        onFinish={onFinish}
        onNotFound={onNotFound}
        onChatAdv={onChatAdv}
        muted={muted}
      />
      <PlayerFooter
        content={content}
        position="absolute"
        visible
        hideShare={hideShare}
        hideLogo={hideLogo}
        fullscreenTarget={fullscreenTarget}
      />
    </PlayerPreview>
  );
}

SponsorPreAdv.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    preview: PropTypes.string,
    shareUrl: PropTypes.string,
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    sport: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  embedId: PropTypes.string,
  muted: PropTypes.bool,
  onChatAdv: PropTypes.func,
  platformId: PropTypes.string,
  sponsorChatMessagePlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string.isRequired,
  fullscreenTarget: PropTypes.string.isRequired,
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  onNotFound: PropTypes.func,
  dubugVastUrl: PropTypes.string,
};
