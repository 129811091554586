import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ToggleRenewProductPurchase from "arena/components/ToggleRenewProductPurchase";
import formatCurrency from "core/utils/currency";

const PREFIX = "ProductPurchaseListItem";

const classes = {
  root: `${PREFIX}-root`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardContent: `${PREFIX}-cardContent`,
  media: `${PREFIX}-media`,
  deleted: `${PREFIX}-deleted`,
  icon: `${PREFIX}-icon`,
  button: `${PREFIX}-button`,
  iconButton: `${PREFIX}-iconButton`,
  iconButtonActive: `${PREFIX}-iconButtonActive`,
  active: `${PREFIX}-active`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "relative",
    width: "100%",
    // display: "flex",
    [theme.breakpoints.up("sm")]: {
      transition: "transform .15s ease-in-out",
      transform: "scale(1)",
      "&:hover": {
        transform: "scale(1.03)",
      },
    },
  },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    flex: 1,
    alignItems: "flex-start",
    color: "rgba(255,255,255,1)",
  },
  [`& .${classes.cardContent}`]: {
    width: "100%",
    flex: 1,
    alignItems: "flex-start",
    paddingTop: 0,
  },

  [`& .${classes.media}`]: {
    width: "100%",
    position: "relative",
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundColor: "black",
  },

  [`& .${classes.deleted}`]: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    right: 8,
    top: 8,
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.button}`]: {
    whiteSpace: "nowrap",
    // width: 126
  },

  [`& .${classes.iconButton}`]: {
    backgroundColor: "rgba(0,0,0,0.9)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:focus": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
  },

  [`& .${classes.iconButtonActive}`]: {
    backgroundColor: "#e91e63",
    "&:hover": {
      backgroundColor: "#e91e63",
    },
    "&:focus": {
      backgroundColor: "#e91e63",
    },
    "&:active": {
      backgroundColor: "#e91e63",
    },
  },

  [`& .${classes.active}`]: {
    backgroundColor: "rgba(255,255,255,0.1)",
    color: "rgba(255,255,255,1)",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    "&:focus": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    "&:active": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
}));

export default function ProductPurchaseListItem({ productPurchase }) {
  const { t } = useTranslation("arena");

  const deleted = !productPurchase.product;

  return (
    <StyledCard className={classes.root} style={{ opacity: deleted ? 0.6 : 1 }}>
      <CardMedia
        component={Link}
        to={productPurchase?.product?.siteUrl}
        image={productPurchase?.preview?.url}
        className={classes.media}
      >
        {deleted && (
          <Typography className={classes.deleted}>
            {t("productPurchaseListItem.deleted")}
          </Typography>
        )}
      </CardMedia>

      <div className={classes.icon}>
        <IconButton
          size="small"
          classes={{
            root: classNames(classes.iconButton, {
              [classes.iconButtonActive]: !productPurchase.expired,
            }),
          }}
        >
          <AttachMoneyIcon fontSize="small" />
        </IconButton>
      </div>

      <CardHeader
        component={Link}
        to={productPurchase?.product?.siteUrl}
        className={classes.cardHeader}
        title={productPurchase.title}
        titleTypographyProps={{
          variant: "h6",
          gutterBottom: true,
        }}
        subheader={
          productPurchase.expired
            ? t("productPurchaseListItem.expired")
            : t("productPurchaseListItem.period", {
                count: productPurchase.daysLeft,
              })
        }
        subheaderTypographyProps={{
          variant: "body2",
        }}
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h5">
              {formatCurrency(
                productPurchase.amount.value,
                productPurchase.amount.currency
              )}
            </Typography>
          </Grid>
          {productPurchase.recurrent && !productPurchase.expired && (
            <Grid item>
              <ToggleRenewProductPurchase productPurchase={productPurchase} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

ProductPurchaseListItem.propTypes = {
  productPurchase: PropTypes.shape({
    provider: PropTypes.string,
    renewAvailable: PropTypes.bool,
    recurrent: PropTypes.bool,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      value: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    daysLeft: PropTypes.number.isRequired,
    expiredAt: PropTypes.string.isRequired,
    renewSubscription: PropTypes.bool,
    expired: PropTypes.bool,
    preview: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      siteUrl: PropTypes.string.isRequired,
    }),
  }),
};
