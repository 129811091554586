import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import formatCurrency from "core/utils/currency";
import { useTranslation } from "react-i18next";
import moment from "core/utils/moment";

export default function DonationProgress({ product }) {
  const { t } = useTranslation("arena");

  const { donationProgress, rightholder } = product;

  if (!donationProgress) return null;

  const { targetAmount, currentAmount, currency, percent, count, dateTo } =
    donationProgress;

  const value = percent > 100 ? 100 : percent;
  const days = moment(dateTo).diff(moment(), "days");
  const isKrasava = [
    "IRWkErZeFY7y4r2uMcd8SQ",
    "9eUR7a99Y0te3Ci-fhYjJA",
  ].includes(rightholder.id);

  return (
    <Grid container spacing={1}>
      {isKrasava && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">Бюджет клуба: </Typography>
        </Grid>
      )}
      <Grid item xs={6}>
        <Typography variant="subtitle2">
          {t("donationProgress.label", {
            currentAmount: formatCurrency(currentAmount, currency, {
              precision: 0,
            }),
            targetAmount: formatCurrency(targetAmount, currency, {
              precision: 0,
            }),
          })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2" align="right">
          {t("donationProgress.percent", {
            percent: percent.toFixed(2),
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            color="primary"
            value={value}
            variant="determinate"
            sx={{ height: 24, borderRadius: 3, overflow: "hidden" }}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("donationProgress.backers", { count })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary" align="right">
          {t("donationProgress.days", { count: days })}
        </Typography>
      </Grid>
    </Grid>
  );
}

DonationProgress.propTypes = {
  product: PropTypes.shape({
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    donationProgress: PropTypes.shape({
      targetAmount: PropTypes.number.isRequired,
      currentAmount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      percent: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      dateTo: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
