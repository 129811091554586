import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

const TEXT = "TEXT";
const NUMBER = "NUMBER";
const BOOLEAN = "BOOLEAN";

export default function CustomField({ type, label, required, value, field }) {
  switch (type) {
    case TEXT:
    case NUMBER:
      return (
        <TextField
          fullWidth
          label={label}
          type={type}
          {...field}
          required={required}
        />
      );
    case BOOLEAN:
      return (
        <FormControlLabel
          control={
            <Checkbox color="default" defaultChecked={value} {...field} />
          }
          label={
            <Typography
              display="inline"
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          }
        />
      );
    default:
      return null;
  }
}

CustomField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  field: PropTypes.object,
  value: PropTypes.any,
};
