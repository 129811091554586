import React from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import usePageview from "core/hooks/usePageview";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import Button from "@mui/material/Button";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  minHeight: "60vh",
  display: "flex",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3),
  },
}));

export default function InvalidToken() {
  const { t } = useTranslation("site");

  const location = useLocation();

  usePageview(location.pathname, "InvalidToken");

  const onClick = () => {
    return document.querySelector(".js-sign-in").click();
  };

  return (
    <Root>
      <ErrorScreen
        icon={NoMeetingRoomIcon}
        button={
          <Button color="primary" variant="contained" onClick={onClick}>
            {t("invalidToken.button")}
          </Button>
        }
        title={t("invalidToken.title")}
      />
    </Root>
  );
}
