import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { SponsorChatTopBannerQuery } from "core/queries";
import { useQuery } from "@apollo/client";
import {
  createSponsorImpression,
  createSponsorClick,
  trackAdPixel,
} from "core/utils/tracker";
import ButtonBase from "@mui/material/ButtonBase";
import { getThumbnailSrcSet } from "core/utils/getSrcSet";
import { nanoid } from "nanoid";

const StyledImage = styled("img")({
  width: "100%",
  display: "block",
  margin: 0,
});

const Root = styled("div")({
  display: "block",
});

const StyledButtonBase = styled(ButtonBase)(() => ({
  display: "block",
}));

const EMBED = "EMBED";
const SITE = "SITE";
const IMPRESSION = "IMPRESSION";
const MAXRES = "MAXRES";

export default function SponsorChatTopBanner({
  place,
  platformId,
  contentId,
  rightholderId,
  previewBanner,
}) {
  const { data, loading } = useQuery(SponsorChatTopBannerQuery, {
    skip: !process.browser || !!previewBanner,
    fetchPolicy: "network-only",
    variables: {
      contentId,
      rightholderId,
      platformId,
      chatTopBannerPlace: place,
    },
  });

  if (previewBanner) {
    return (
      <Root>
        <StyledImage src={previewBanner} alt={place} />
      </Root>
    );
  }

  if (loading) return null;
  if (!data?.sponsorCampaign) return null;

  const {
    id,
    thumbnails,
    link,
    token,
    isClickable,
    clickPixel,
    impressionPixel,
  } = data.sponsorCampaign.chatTopBanner;

  const onClick = async (event) => {
    event.preventDefault();

    if (isClickable) {
      if (clickPixel) {
        trackAdPixel(clickPixel);
      }

      const clickId = nanoid();
      const url = link.replace("{clickId}", clickId);

      window.open(url, "_blank");

      await createSponsorClick(token, clickId);
    }
  };

  const onLoad = () => {
    createSponsorImpression(token, "ChatTopBanner", IMPRESSION);

    if (impressionPixel) {
      trackAdPixel(impressionPixel);
    }
  };

  const srcSet = getThumbnailSrcSet(thumbnails);

  const src = thumbnails?.find((t) => t.format === MAXRES)?.url;

  return (
    <StyledButtonBase onClick={onClick} key={id}>
      <StyledImage src={src} srcSet={srcSet} alt={place} onLoad={onLoad} />
    </StyledButtonBase>
  );
}

SponsorChatTopBanner.propTypes = {
  place: PropTypes.oneOf([EMBED, SITE]).isRequired,
  rightholderId: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  platformId: PropTypes.string,
  previewBanner: PropTypes.string,
};
