import currencyJs from "currency.js";

export default function formatCurrency(value, c, options = {}) {
  let precision = 2;

  if (value % 1 === 0) precision = 0;

  switch (c) {
    case "TRY":
      return currencyJs(value, {
        pattern: `#!`,
        symbol: "₺",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "KRW":
      return currencyJs(value, {
        pattern: `#!`,
        symbol: "₩",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "AED":
      return currencyJs(value, {
        pattern: `!#`,
        symbol: "د.إ",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "KZT":
      return currencyJs(value, {
        pattern: `#!`,
        symbol: "₸",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "BRL":
      return currencyJs(value, {
        pattern: `!#`,
        symbol: "R$",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "USDT":
      return currencyJs(value, {
        pattern: `!#`,
        symbol: "₮",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "NZD":
      return currencyJs(value, {
        pattern: `!# NZ`,
        symbol: "$",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "CAD":
      return currencyJs(value, {
        pattern: `!# CA`,
        symbol: "$",
        decimal: ".",
        separator: " ",
        precision,
      }).format();
    case "GBP":
      return currencyJs(value, {
        pattern: `!#`,
        symbol: "£",
        decimal: ".",
        separator: " ",
        precision,
      }).format();

    case "RUB":
      return currencyJs(value, {
        symbol: "₽",
        precision,
        pattern: `#!`,
        separator: " ",
        decimal: ".",
        ...options,
      }).format();

    case "PLN":
      return currencyJs(value, {
        symbol: "zł",
        precision,
        pattern: `#!`,
        separator: " ",
        decimal: ".",
        ...options,
      }).format();

    case "EUR":
      return currencyJs(value, {
        symbol: "€",
        precision,
        pattern: `#!`,
        separator: " ",
        decimal: ".",
        ...options,
      }).format();

    case "THB":
      return currencyJs(value, {
        symbol: "฿",
        precision,
        pattern: `#!`,
        separator: " ",
        decimal: ".",
        ...options,
      }).format();

    case "USD":
    default:
      return currencyJs(value, {
        symbol: "$",
        precision,
        pattern: `!#`,
        separator: " ",
        decimal: ".",
        ...options,
      }).format();
  }
}
