/**
 * Сформировать embed код для контента
 *
 * @param {String} url - Адрес страницы с плеером
 * @param {Object} options
 * @param {Boolean} options[.responsive]
 * @param {Boolean} options[.chat]
 */
export default function embed(url, options) {
  if (options) {
    if (options.responsive) {
      let paddingTop = "56.25%";

      if (options.chat) {
        paddingTop = "calc(56.25% + 350px)";
      }

      return `<div style="overflow:hidden;padding-top:${paddingTop};position:relative;width:100%;"><iframe src='${url}' referrerpolicy="unsafe-url" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen style='height:100%;left:0;position:absolute;top:0;width:100%;'></iframe></div>`;
    }
  }

  let height = "480px";

  if (options.chat) {
    height = "830px";
  }

  return `<iframe style='width:854px;height:${height};' src='${url}' referrerpolicy="unsafe-url" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>`;
}
