import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import GoogleIcon from "core/components/GoogleIcon";
import AppleIcon from "@mui/icons-material/Apple";
import Grid from "@mui/material/Grid";
import { useApolloClient, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { SignInProvidersQuery } from "arena/queries";

const PREFIX = "SignInProviders";

const classes = {
  dividerContainer: `${PREFIX}-dividerContainer`,
  divider: `${PREFIX}-divider`,
  dividerText: `${PREFIX}-dividerText`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.dividerContainer}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.divider}`]: {
    borderBottom: "2px solid #797979",
    width: "100%",
  },

  [`& .${classes.dividerText}`]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    textAlign: "center",
    flexShrink: 0,
    color: "#797979",
  },

  [`& .${classes.button}`]: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
    "&:focus": {
      backgroundColor: "#fff",
      color: "#000",
    },
    "&.Mui-disabled": {
      backgroundColor: "#fff",
      color: "#000",
      opacity: 0.5,
    },
  },
}));

export default function SignInProviders({ disabled }) {
  const client = useApolloClient();
  const { t } = useTranslation("core");

  const { data } = useQuery(SignInProvidersQuery);

  const arenaAuth = data?.arena?.arenaAuth;

  useEffect(() => {
    const listener = (event) => {
      if (event.data?.oauthLogin || event.data?.oauthConnect) {
        client.resetStore();
      }
    };

    window.addEventListener("message", listener, false);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  const providers = [];

  if (arenaAuth?.google?.enabled) {
    providers.push({
      icon: <GoogleIcon />,
      text: t("signIn.loginWithGoogle"),
      url: "/oauth/login/google",
    });
  }

  if (arenaAuth?.apple?.enabled) {
    providers.push({
      icon: <AppleIcon />,
      text: t("signIn.loginWithAppleId"),
      url: "/oauth/login/apple",
    });
  }

  const openAuthWindow = (event, url) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(url, "Auth", "width=800, height=600");
    ReactGA.event({
      category: "SignInProviders",
      action: "Open auth window",
      label: url,
    });
  };

  if (providers.length === 0) return null;

  return (
    <Root className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <div className={classes.dividerContainer}>
            <div className={classes.divider} />
            <span className={classes.dividerText}>{t("signIn.orSocial")}</span>
            <div className={classes.divider} />
          </div>
        </Grid>

        {providers.map(({ icon, text, url }, key) => {
          return (
            <Grid item xs={12} key={key}>
              <Button
                variant="outlined"
                disabled={disabled}
                fullWidth
                onClick={(event) => openAuthWindow(event, url)}
                href={url}
                startIcon={icon}
                size="large"
                color="inherit"
                className={classes.button}
              >
                {text}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Root>
  );
}

SignInProviders.propTypes = {
  disabled: PropTypes.bool,
};
