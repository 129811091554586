import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { CreateViewMutation } from "core/mutations";
import { useMutation } from "@apollo/client";
import useClientIp from "core/utils/useClientIp";
import Video from "core/components/Video";
import ReactGA from "react-ga4";
import Fade from "@mui/material/Fade";
import { InView } from "react-intersection-observer";
import { getUtmFromLocalStorage } from "core/utils/utm";

const Root = styled("div")({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  width: "100%",
  height: "100%",
});

export default function ContentPreview({
  token,
  title,
  className,
  start,
  finish,
  getIpUrl,
}) {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [createView, { data, loading }] = useMutation(CreateViewMutation, {
    onCompleted() {
      ReactGA.event({
        category: "User",
        action: "Preview content in list",
        label: title,
      });
    },
  });
  const { waitIp, clientIp } = useClientIp({ url: getIpUrl });

  useEffect(() => {
    if (waitIp) {
      return;
    }

    const variables = { token, utm: getUtmFromLocalStorage(), clientIp };

    createView({
      variables,
    });
  }, [waitIp, token]);

  if (loading) return null;
  if (!data) return null;

  const live = data.createView?.streams?.length > 0;
  const record = data.createView?.records?.length > 0;

  const hlsUrl = live
    ? data.createView?.streams[0]?.playlist
    : record
    ? data.createView?.records[0]?.playlist
    : data.createView?.video?.playlist;

  if (!hlsUrl) return null;

  const onCanPlay = () => {
    setVideoVisible(true);
  };

  return (
    <Video
      hlsUrl={hlsUrl}
      live={live}
      onCanPlay={onCanPlay}
      className={className}
      quality="360p"
      autoPlay
      loop
      muted
      start={start}
      finish={finish}
    >
      {(videoEl, videoState, videoActions) => (
        <InView
          as={Root}
          onChange={(inView) => {
            if (inView) {
              videoActions.play();
            } else {
              videoActions.pause();
            }
          }}
        >
          <Fade in={isVideoVisible} timeout={1500}>
            {videoEl}
          </Fade>
        </InView>
      )}
    </Video>
  );
}

ContentPreview.propTypes = {
  start: PropTypes.number,
  finish: PropTypes.number,
  className: PropTypes.string,
  token: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  getIpUrl: PropTypes.string,
};
