import { useContext } from "react";
import PropTypes from "prop-types";
import HttpContext from "core/utils/httpContext";

export default function Status({ code, children }) {
  let context = useContext(HttpContext);

  context.status = code;

  return children;
}

Status.propTypes = {
  code: PropTypes.oneOf([404, 403, 500]).isRequired,
  children: PropTypes.node,
};
