import React, { useState } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { SignInMutation } from "core/mutations";
import SignInProviders from "core/components/SignInProviders";
import { getUtmFromLocalStorage } from "core/utils/utm";
import getBellatorCheckboxLabel from "./getBellatorCheckboxLabel";
import { isBellator } from "core/utils/hardcode";

export default function SignInForm({
  onCompleted,
  onError,
  email,
  setEmail,
  arena,
}) {
  const { t } = useTranslation("core");

  const _isBellator = isBellator(arena.id);

  const [paramountCheckbox, setParamountCheckbox] = useState(!_isBellator);

  const [signIn, { loading }] = useMutation(SignInMutation, {
    variables: {
      email,
      utm: getUtmFromLocalStorage(),
    },
    onCompleted,
    onError,
  });

  const onSubmit = (event) => {
    event.preventDefault();
    signIn();
  };

  const onChange = (event) => setEmail(event.target.value.trim());
  const onChangeCheckbox = (event) =>
    setParamountCheckbox(event.target.checked);

  const isValid =
    !!email && email.includes("@") && email.includes(".") && paramountCheckbox;

  return (
    <Grid container spacing={4} component="form" onSubmit={onSubmit}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          required
          fullWidth
          variant="outlined"
          label="Email"
          disabled={loading}
          helperText={t("signIn.emailText")}
          name="email"
          onChange={onChange}
        />
      </Grid>

      {_isBellator && (
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="default" onChange={onChangeCheckbox} />}
            label={<>{getBellatorCheckboxLabel(arena.viewerCountry)}</>}
            componentsProps={{
              typography: { variant: "caption", color: "textSecondary" },
            }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Button
          disableElevation
          component="button"
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          disabled={loading || !isValid}
        >
          {t("signIn.submit")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <SignInProviders disabled={!paramountCheckbox} />
      </Grid>

      {!_isBellator && (
        <Grid item xs={12}>
          {arena.termsOfServiceUrl && arena.privacyPolicyUrl && (
            <Typography align="center" variant="caption" color="textSecondary">
              {t("signIn.accept1")}
              <Link
                color="textPrimary"
                href={arena.termsOfServiceUrl}
                target="_blank"
                underline="hover"
              >
                {t("signIn.accept2")}
              </Link>
              {t("signIn.accept3")}
              <Link
                color="textPrimary"
                href={arena.privacyPolicyUrl}
                target="_blank"
                underline="hover"
              >
                {t("signIn.accept4")}
              </Link>
              {"."}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

SignInForm.propTypes = {
  arena: PropTypes.shape({
    id: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    termsOfServiceUrl: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
    viewerCountry: PropTypes.string,
    arenaAuth: PropTypes.shape({
      id: PropTypes.string,
      google: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      apple: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
    }),
  }).isRequired,
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};
