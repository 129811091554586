import { loader } from "graphql.macro";

export const DisconnectDeviceMutation = loader(
  "./DisconnectDeviceMutation.graphql"
);
export const ConnectDeviceMutation = loader("./ConnectDeviceMutation.graphql");

export const CreateOnboardingAnswerMutation = loader(
  "./CreateOnboardingAnswerMutation.graphql"
);
export const SignOutMutation = loader("./SignOutMutation.graphql");

export const UpdateSettingsMutation = loader(
  "./UpdateSettingsMutation.graphql"
);

export const UploadAvatarMutation = loader("./UploadAvatarMutation.graphql");

export const CreateProductFeedbackMutation = loader(
  "./CreateProductFeedbackMutation.graphql"
);
export const CreatePaymentMutation = loader("./CreatePaymentMutation.graphql");
export const CompletePaymentMutation = loader(
  "./CompletePaymentMutation.graphql"
);

export const DeletePaymentMethodMutation = loader(
  "./DeletePaymentMethodMutation.graphql"
);

export const UnsubscribeMutation = loader("./UnsubscribeMutation.graphql");
export const DisableSubscriptionMutation = loader(
  "./DisableSubscriptionMutation.graphql"
);
export const UpdateProductPurchaseMutation = loader(
  "./UpdateProductPurchaseMutation.graphql"
);
