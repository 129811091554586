import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ArenaPostsQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import HomepageTitle from "arena/components/HomepageTitle";
import PostItem from "./PostItem";
import { alpha } from "@mui/system";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useCarousel from "core/hooks/useCarousel";
import HeaderMoreLink from "arena/components/HeaderMoreLink";

const PREFIX = "ArenaPosts";

const classes = {
  scrollable: `${PREFIX}-scrollable`,
  item: `${PREFIX}-item`,
  prev: `${PREFIX}-prev`,
  next: `${PREFIX}-next`,
  btnRoot: `${PREFIX}-btnRoot`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",

  [`& .${classes.scrollable}`]: {
    padding: theme.spacing(0, 2),

    overflowY: "hidden",
    overflowX: "auto",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 3),
    },
  },

  [`& .${classes.prev}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      left: 0,
      backgroundImage: `linear-gradient(90deg, ${
        theme.palette.background.body
      }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.next}`]: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      right: 0,
      backgroundImage: `linear-gradient(-90deg, ${
        theme.palette.background.body
      }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.btnRoot}`]: {
    pointerEvents: "all",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 60,
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 0.3)",
    "&:hover": {
      color: "#fff",
    },

    [theme.breakpoints.up("sm")]: {
      width: 90,
    },
    [theme.breakpoints.up("md")]: {
      width: 100,
    },
  },

  [`& .${classes.icon}`]: {
    fontSize: 60,
  },
}));

const StyledHomepageTitle = styled(HomepageTitle)(({ theme }) => ({
  padding: theme.spacing(5, 2, 1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 3, 1),
  },
}));

export default function ArenaPosts({
  title,
  limit = 12,
  tagIds,
  rightholderIds,
}) {
  const variables = {
    rowsPerPage: limit,
    tagIds,
  };

  if (rightholderIds?.length) {
    variables.rightholderIds = rightholderIds;
  }

  const { data } = useQuery(ArenaPostsQuery, {
    variables,
  });

  const { rootRef, onScroll, prev, next, onClickNext, onClickPrev } =
    useCarousel();

  const count = data?.posts?.count || 0;
  const nodes = data?.posts?.nodes || [];

  if (!count) return null;

  let moreLink = null;

  if (count > limit) {
    moreLink = `/posts`;
  }

  return (
    <>
      {title && (
        <StyledHomepageTitle
          title={title}
          action={moreLink && <HeaderMoreLink url={moreLink} />}
        />
      )}

      <Root>
        <div className={classes.scrollable} ref={rootRef} onScroll={onScroll}>
          <Grid container spacing={3} wrap="nowrap">
            {nodes.map((post) => {
              return (
                <Grid item key={post.id}>
                  <PostItem post={post} />
                </Grid>
              );
            })}
          </Grid>
        </div>

        {prev && (
          <div className={classes.prev}>
            <ButtonBase
              classes={{ root: classes.btnRoot }}
              onClick={onClickPrev}
            >
              <ChevronLeftIcon className={classes.icon} />
            </ButtonBase>
          </div>
        )}
        {next && (
          <div className={classes.next}>
            <ButtonBase
              classes={{ root: classes.btnRoot }}
              onClick={onClickNext}
            >
              <ChevronRightIcon className={classes.icon} />
            </ButtonBase>
          </div>
        )}
      </Root>
    </>
  );
}

ArenaPosts.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  tagIds: PropTypes.arrayOf(PropTypes.string),
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
