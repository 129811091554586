import React, { useEffect } from "react";
import PropTypes from "prop-types";

export default function ArenaHtml({
  code,
  paddingTop,
  paddingBottom,
  maxWidth,
}) {
  useEffect(() => {
    if (!process.browser) return;

    const el = document.createElement("div");
    el.innerHTML = code;

    el.querySelectorAll("script").forEach((element) => {
      const script = element.innerHTML;
      window.eval(script);
    });
  }, [code]);

  const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  const __html = code.replace(scriptRegex, "");

  return (
    <div
      style={{ paddingTop, paddingBottom, maxWidth, margin: "auto" }}
      dangerouslySetInnerHTML={{
        __html,
      }}
    />
  );
}

ArenaHtml.propTypes = {
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  maxWidth: PropTypes.string,
  code: PropTypes.string.isRequired,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
