import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DialogHeader from "core/components/DialogHeader";
import CardActionArea from "@mui/material/CardActionArea";
import getMinPrice from "core/utils/getMinPrice";
import ProductPriceButton from "arena/components/ProductPriceButton";
import ReactGA from "react-ga4";

const PREFIX = "ChooseProduct";

const classes = {
  content: `${PREFIX}-content`,
  cardContent: `${PREFIX}-cardContent`,
  button: `${PREFIX}-button`,
  oldPrice: `${PREFIX}-oldPrice`,
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1),

  [`& .${classes.cardContent}`]: {
    paddingTop: 0,
  },
}));

export default function ChooseProduct({ onClose, state, content, dispatch }) {
  const { t } = useTranslation("arena");

  const products = content.products.nodes.filter((product) => {
    return product.type === state.type;
  });

  return (
    <>
      <DialogHeader
        onClose={onClose}
        title={t("productDialog.chooseProduct.title")}
      />
      <StyledDialogContent>
        <Grid container spacing={1}>
          {products.map((product) => {
            const minPrice = getMinPrice(product);

            const onClick = (event) => {
              event.preventDefault();

              dispatch({ type: "product", productId: product.id });

              ReactGA.event({
                category: "ChooseProduct",
                action: "Click to product",
                label: product.title,
              });
            };

            return (
              <Grid item xs={12} key={product.id}>
                <Card>
                  <CardActionArea
                    href={product.siteUrl}
                    target="_blank"
                    component="a"
                    onClick={onClick}
                  >
                    <CardHeader title={product.title} />
                    <CardContent className={classes.cardContent}>
                      <ProductPriceButton
                        product={product}
                        price={minPrice}
                        disableElevation
                        disableRipple
                        variant="outlined-product"
                      />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </StyledDialogContent>
    </>
  );
}

ChooseProduct.propTypes = {
  dispatch: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
