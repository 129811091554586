import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import formatCurrency from "core/utils/currency";
import ReactGA from "react-ga4";

export default function StripeCheckout({
  setError,
  setLoading,
  loading,
  paymentData,
  returnUrl,
  recurrentDescComponent,
  paymentHasError,
  setPaymentHasError,
}) {
  const [stripeError, setStripeError] = useState(null);
  const [needBillingDetails, setNeedBillingDetails] = useState(false);
  const { t } = useTranslation("arena");
  const [loadingPayment, setLoadingPayment] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const onSubmit = (event) => {
    event.preventDefault();

    setStripeError(false);

    ReactGA.event({
      category: "Product",
      action: "Start pay",
      label: "stripe",
    });

    pay().catch((err) => {
      setError(err);
    });
  };

  const pay = async function () {
    setLoadingPayment(true);
    /*  eslint-disable camelcase */
    const result =
      paymentData.action === "confirmPaymentIntent"
        ? await stripe.confirmPayment({
            //
            elements,
            redirect: "if_required",
            ...paymentData.confirmPaymentExt,
            confirmParams: {
              ...paymentData.confirmPaymentExt.confirmParams,
              payment_method_data: {
                billing_details: {
                  address: {
                    city: null,
                    country: null,
                    line1: null,
                    line2: null,
                    postal_code: null,
                    state: null,
                  },
                  email: null,
                  name: null,
                  phone: null,
                },
              },
            },
          })
        : await stripe.confirmSetup({
            elements,
            redirect: "if_required",
            ...paymentData.confirmPaymentExt,
            confirmParams: {
              ...paymentData.confirmPaymentExt.confirmParams,
              payment_method_data: {
                billing_details: {
                  address: {
                    city: null,
                    country: null,
                    line1: null,
                    line2: null,
                    postal_code: null,
                    state: null,
                  },
                  email: null,
                  name: null,
                  phone: null,
                },
              },
            },
          });
    /*  eslint-enable camelcase */

    setLoadingPayment(false);

    if (result.error) {
      if (
        !needBillingDetails &&
        result.error.message.includes("We are unable to authenticate")
      ) {
        return setNeedBillingDetails(true);
      }

      if (result.error.type !== "validation_error") {
        setPaymentHasError(true);
        ReactGA.event({
          category: "Product",
          action: "Pay error",
          label: "stripe",
        });

        // Show error to your customer
        return setStripeError(result.error.message);
      }
    } else {
      if (paymentHasError) {
        ReactGA.event({
          category: "Product",
          action: "Success pay after error",
          label: "stripe",
        });
      } else {
        ReactGA.event({
          category: "Product",
          action: "Success pay",
          label: "stripe",
        });
      }

      window.location = returnUrl;
    }
  };

  const onReady = () => {
    setLoading(false);
  };

  const amount =
    paymentData.action === "confirmPaymentIntent" &&
    formatCurrency(paymentData.amount.value, paymentData.amount.currency);

  return (
    <Box component="form" sx={{ p: 2 }} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        {needBillingDetails && (
          <Grid item xs={12}>
            <Alert icon={false} severity="info">
              {t("stripePaymentForm.needBillingDetails")}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <PaymentElement
            onReady={onReady}
            options={{
              fields: { billingDetails: "auto" },
              terms: {
                // auBecsDebit: "never",
                // bancontact: "never",
                card: "never",
                // ideal: "never",
                // sepaDebit: "never",
                // sofort: "never",
                // usBankAccount: "never",
              },
            }}
          />
        </Grid>

        {needBillingDetails && (
          <Grid item xs={12}>
            <AddressElement options={{ mode: "billing" }} />
          </Grid>
        )}

        {recurrentDescComponent && (
          <Grid item xs={12}>
            {recurrentDescComponent}
          </Grid>
        )}

        {stripeError && (
          <Grid item xs={12}>
            <Alert severity="error">{stripeError}</Alert>
          </Grid>
        )}

        {!loading && (
          <Grid item xs={12}>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              startIcon={
                loadingPayment ? (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                ) : (
                  <SendIcon />
                )
              }
              disabled={loadingPayment}
            >
              {paymentData.action === "confirmSetupIntent"
                ? t("stripePaymentForm.connectCard")
                : t("stripePaymentForm.pay", { amount })}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

StripeCheckout.propTypes = {
  paymentHasError: PropTypes.bool,
  setPaymentHasError: PropTypes.func.isRequired,
  paymentData: PropTypes.shape({
    action: PropTypes.oneOf(["confirmSetupIntent", "confirmPaymentIntent"]),
    clientSecret: PropTypes.string.isRequired,
    publishableKey: PropTypes.string.isRequired,
    paymentRequest: PropTypes.object,
    confirmPaymentExt: PropTypes.object,
    amount: PropTypes.shape({
      value: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  }).isRequired,
  recurrentDescComponent: PropTypes.object,
  price: PropTypes.shape({
    trialPeriod: PropTypes.number,
    period: PropTypes.number.isRequired,
    originalValue: PropTypes.number,
    value: PropTypes.number,
    currency: PropTypes.string.isRequired,
  }),
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  setError: PropTypes.func,
  returnUrl: PropTypes.string.isRequired,
};
