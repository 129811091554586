import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import {
  ChatMessageCreatedSubscription,
  ChatMessageDeletedSubscription,
} from "core/subscriptions";
import { ChatMessagesQuery } from "core/queries";
import { useQuery } from "@apollo/client";
import { withSnackbar } from "notistack";
import ChatMessageListItemAdv from "core/components/ChatMessageListItemAdv";
import ChatMessageListItem from "core/components/ChatMessageListItem";
import LinearProgress from "@mui/material/LinearProgress";
// import Fab from "@mui/material/Fab";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import usePreviewBanner from "core/hooks/usePreviewBanner";

const PREFIX = "ChatMessageList";

const classes = {
  root: `${PREFIX}-root`,
  list: `${PREFIX}-list`,
  fab: `${PREFIX}-fab`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    // background: "blue",
    position: "absolute",
    top: 48,
    bottom: 56,
    left: 0,
    right: 0,
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: 1,

    scrollBehavior: "smooth",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
    [theme.breakpoints.up("lg")]: {
      bottom: 64,
    },
  },

  [`& .${classes.list}`]: {
    display: "flex",
    flexDirection: "column-reverse",
  },

  [`& .${classes.fab}`]: {
    position: "absolute",
    bottom: 80,
    left: "50%",
    zIndex: 1101,
    transform: "translateX(-50%)",
  },
}));

const SPONSOR_CHAT_MESSAGE_PREVIEW_URL =
  "https://st.spads.me/image/9HSKFYkcB.png";

function ChatMessageList({
  limit,
  enqueueSnackbar,
  viewerIsAdmin,
  chatRoomId,
  viewer,
}) {
  const previewBanner = usePreviewBanner(
    "sponsorChatMessagePreview",
    SPONSOR_CHAT_MESSAGE_PREVIEW_URL
  );

  // const [autoScroll, setAutoScroll] = useState(true);

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const { data, loading, error, subscribeToMore } = useQuery(
    ChatMessagesQuery,
    {
      fetchPolicy: "cache-and-network",
      skip: !process.browser,
      variables: {
        chatRoomId,
        limit,
      },
      onError,
    }
  );

  useEffect(() => {
    if (!chatRoomId) return;
    if (!process.browser) return;

    return subscribeToMore({
      document: ChatMessageCreatedSubscription,
      variables: {
        chatRoomId,
        userId: viewer?.id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        // const nodes = [...prev.chatMessages.nodes];
        // nodes.pop();

        return Object.assign({}, prev, {
          chatMessages: {
            ...prev.chatMessages,
            count: prev.chatMessages.count + 1,
            nodes: [
              subscriptionData.data.chatMessageCreated,
              ...prev.chatMessages.nodes,
            ],
          },
        });
      },
    });
  }, [chatRoomId]);

  useEffect(() => {
    if (!chatRoomId) return;
    if (!process.browser) return;

    return subscribeToMore({
      document: ChatMessageDeletedSubscription,
      variables: {
        chatRoomId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return Object.assign({}, prev, {
          chatMessages: {
            ...prev.chatMessages,
            nodes: [
              ...prev.chatMessages.nodes.filter(
                (m) => m.id !== subscriptionData.data.chatMessageDeleted.id
              ),
            ],
          },
        });
      },
    });
  }, [chatRoomId]);

  const updateCacheAfterDelete = (cache, { data: { deleteChatMessage } }) => {
    const { chatMessages } = cache.readQuery({
      query: ChatMessagesQuery,
      variables: {
        chatRoomId,
        limit,
      },
    });

    cache.writeQuery({
      query: ChatMessagesQuery,
      variables: {
        chatRoomId,
        limit,
      },
      data: {
        chatMessages: {
          ...chatMessages,
          nodes: chatMessages.nodes.filter(
            (m) => m.id !== deleteChatMessage.id
          ),
        },
      },
    });
  };

  const nodes = data?.chatMessages?.nodes || [];
  const count = data?.chatMessages?.count || 0;

  let list = null;

  const scrollToBottom = () => {
    // if (!autoScroll) return;
    if (list) {
      if (list.scroll) {
        list.scroll({
          top: list.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      } else {
        list.scrollTo(0, list.scrollHeight);
      }
    }
  };

  useEffect(scrollToBottom, [count]);

  // const onScroll = event => {
  //   const bottom =
  //     event.target.scrollHeight -
  //     event.target.scrollTop -
  //     event.target.clientHeight;

  //   console.log({ bottom });

  //   if (bottom) {
  //     if (!autoScroll) {
  //       setAutoScroll(true);
  //     }
  //   } else {
  //     if (autoScroll) {
  //       setAutoScroll(false);
  //     }
  //   }
  // };

  if (loading && !data) return <LinearProgress />;
  if (error) return null;

  return (
    <Root
      // onScroll={onScroll}
      className={classes.root}
      ref={(n) => {
        list = n;
      }}
    >
      <List className={classes.list}>
        {previewBanner && (
          <ChatMessageListItemAdv
            sponsorChatMessage={{
              id: 1,
              token: "123",
              text: "Example sponsor chat message",
              link: null,
              isClickable: false,
              impressionPixel: null,
              clickPixel: null,
              thumbnails: [
                {
                  format: "MAXRES",
                  url: previewBanner,
                },
              ],
            }}
          />
        )}

        {nodes.map((message) => {
          if (message.sponsorChatMessage) {
            return (
              <ChatMessageListItemAdv
                key={message.id}
                sponsorChatMessage={message.sponsorChatMessage}
              />
            );
          }

          return (
            <ChatMessageListItem
              key={message.id}
              message={message}
              viewerIsAdmin={viewerIsAdmin}
              viewerIsCreator={viewer && viewer.id === message.userId}
              updateCacheAfterDelete={updateCacheAfterDelete}
            />
          );
        })}
      </List>

      {/* {autoScroll && (
        <Fab
          className={classes.fab}
          size="small"
          color="primary"
          onClick={scrollToBottom}
        >
          <ArrowDownwardIcon />
        </Fab>
      )} */}
    </Root>
  );
}

ChatMessageList.defaultProps = {
  limit: 30,
};

ChatMessageList.propTypes = {
  limit: PropTypes.number.isRequired,
  enqueueSnackbar: PropTypes.func,
  viewerIsAdmin: PropTypes.bool,
  chatRoomId: PropTypes.string.isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default withSnackbar(ChatMessageList);
