import React from "react";
import ParamountPrivacyLink from "./ParamountPrivacyLink";

const PARAMOUNT_POLICY_URL = "https://privacy.paramount.com/policy";

export default function getBellatorCheckboxLabel(country) {
  switch (country) {
    // Filipino – Philippines
    case "PH":
      return (
        <>
          {
            "Tinatanggap ko na ang aking personal na impormasyon ay gagamitin alinsunod sa "
          }
          <ParamountPrivacyLink href={PARAMOUNT_POLICY_URL}>
            Patakaran sa Privacy ng Paramount
          </ParamountPrivacyLink>
        </>
      );

    // Turkish - Turkey
    case "TR":
      return (
        <>
          {"Kişisel bilgilerimin, "}
          <ParamountPrivacyLink href={PARAMOUNT_POLICY_URL}>
            Paramount Gizlilik Politikası’na
          </ParamountPrivacyLink>
          {" uygun olarak kullanılacağını kabul ediyorum"}
        </>
      );

    // Hindi - India
    case "IN":
      return (
        <>
          {
            "म अ भ वीकृ त देता हूँ क मेर नजी जानकार का उपयोग पैरामाउं ट गोपनीयता नी त के अनु सार कया जाएगा। ("
          }
          <ParamountPrivacyLink href={PARAMOUNT_POLICY_URL}>
            {PARAMOUNT_POLICY_URL}
          </ParamountPrivacyLink>
          {")"}
        </>
      );

    // Punjabi – Pakistan
    case "PK":
      return (
        <>
          {
            "ਮ ਸਵੀਕਾਰ ਕਰਦਾ/ਕਰਦੀ ਹ ਿਕ ਮੇਰੀ ਿਨਜੀ ਜਾਣਕਾਰੀ ਦੀ ਵਰਤ ਪੈਰਾਮਾਟ (Paramount) ਦੀ ਗੋਪਨੀਅਤਾ ਨੀਤੀ ਦੇ ਅਨੁਸਾਰ ਕੀਤੀ ਜਾਵੇਗੀ। ("
          }
          <ParamountPrivacyLink href={PARAMOUNT_POLICY_URL}>
            {PARAMOUNT_POLICY_URL}
          </ParamountPrivacyLink>
          {")"}
        </>
      );

    // Korean – South Korea
    case "KR":
      return (
        <>
          {
            "Paramount 개인 정보 보호 정책에 준하여 본인의 개인 정보가 사용되는 것에 동의합니다 ("
          }
          <ParamountPrivacyLink href={PARAMOUNT_POLICY_URL}>
            {PARAMOUNT_POLICY_URL}
          </ParamountPrivacyLink>
          {")"}
        </>
      );

    // Arabic – Qatar, Bahrain, United Arab Emirates
    case "QA":
    case "BH":
    case "AE":
      return (
        <>
          {
            " ً ٔ ا حكام سیاسة الخصوصیة لشركة راماونت. ٔن معلوماتي الشخصیة سوف ُستدم وفق"
          }{" "}
          <ParamountPrivacyLink href={PARAMOUNT_POLICY_URL}>
            {PARAMOUNT_POLICY_URL}
          </ParamountPrivacyLink>
        </>
      );

    default:
      return (
        <>
          {
            "I acknowledge that my personal information will be used in accordance with "
          }
          <ParamountPrivacyLink href={PARAMOUNT_POLICY_URL}>
            Paramount Privacy Policy
          </ParamountPrivacyLink>
          {"."}
        </>
      );
  }
}
