import React from "react";
import contentUrl from "core/utils/contentUrl";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Thumbnail from "core/components/Thumbnail";
import { Link } from "react-router-dom";

const PREFIX = "ContentListItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  primary: `${PREFIX}-primary`,
  item: `${PREFIX}-item`,
};

const StyledListItem = styled(ListItem)({
  [`& .${classes.avatar}`]: {
    width: 150,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 16,
  },
  [`& .${classes.primary}`]: {
    fontWeight: 500,
  },
  [`&.${classes.item}`]: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

function ContentListItem({ selected, content, onClickItem, productId }) {
  const to = contentUrl(content.siteUrl, { productId });

  return (
    <StyledListItem
      dense
      selected={selected}
      button
      component={Link}
      to={to}
      alignItems="center"
      onClick={onClickItem}
      className={classes.item}
    >
      <ListItemAvatar className={classes.avatar}>
        <Thumbnail content={content} small />
      </ListItemAvatar>

      <ListItemText
        primary={content.title}
        primaryTypographyProps={{
          color: "textPrimary",
          className: classes.primary,
        }}
      />
    </StyledListItem>
  );
}

ContentListItem.propTypes = {
  productId: PropTypes.string,
  onClickItem: PropTypes.func,
  selected: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
  }),
};

export default ContentListItem;
