import React from "react";
import { useArena } from "arena/components/ArenaProvider";
import NoMatchScreen from "arena/components/NoMatchScreen";
import { useLocation } from "react-router-dom";
import PageHelmet from "./PageHelmet";
import PageSection from "./PageSection";
import usePageview from "core/hooks/usePageview";

const COVER = "COVER";
const COVER_CAROUSEL = "COVER_CAROUSEL";

/**
 * Функция принимает массив блоков для страницы и возвращает массив блоков
 * в котором блоки типа "Обложка" сгруппированы в карусельку.
 */
function groupBlocks(blocks) {
  const result = [];

  blocks.forEach((block) => {
    const last = result[result.length - 1];

    if (block.component === COVER) {
      if (last?.component === COVER_CAROUSEL) {
        last.props.blocks.push(block);
      } else {
        result.push({
          id: block.id,
          component: COVER_CAROUSEL,
          props: {
            blocks: [block],
          },
        });
      }
    } else {
      result.push(block);
    }
  });

  return result;
}

/**
 * ищем страницу у которой указана текущая страна  и стоит галка isHomepage
 * если её нет
 * ищем страницу у которой стоит галка isHomepage
 * если её нет
 * ищем страницу у которой стоит path === /
 * если её нет, значит главной страницы нет
 */
function getPage(arena, pathname) {
  if (pathname === "/") {
    const homepageForViewerCountry = arena.pages.find(
      (page) =>
        page.isHomepage && page.countryCodes.includes(arena.viewerCountry)
    );

    if (homepageForViewerCountry) return homepageForViewerCountry;

    const homepage = arena.pages.find(
      (page) => page.isHomepage && page.countryCodes.length === 0
    );

    if (homepage) return homepage;
  }

  return arena.pages.find((page) => page.path === pathname);
}

export default function Page() {
  const location = useLocation();
  const arena = useArena();

  const page = getPage(arena, location.pathname);

  usePageview(location.pathname, page?.title);

  if (!page) {
    return <NoMatchScreen />;
  }

  const blocks = groupBlocks(page.blocks);

  return (
    <>
      <PageHelmet arena={arena} page={page} />

      {blocks.map((block, index) => {
        return (
          <PageSection
            block={block}
            arena={arena}
            index={index}
            first={index === 0}
            last={index + 1 === blocks.length}
            key={block.id}
          />
        );
      })}
    </>
  );
}
