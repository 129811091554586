import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ContentCarousel from "arena/components/ContentCarousel";
import HomepageTitle from "arena/components/HomepageTitle";
import HeaderMoreLink from "arena/components/HeaderMoreLink";
import { useArena } from "arena/components/ArenaProvider";
import { useQuery } from "@apollo/client";
import { ArenaContentsQuery } from "arena/queries";
import qs from "qs";

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  padding: theme.spacing(0, 0, 2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3, 2),
  },
}));

export default function ProductContents({
  title,
  type,
  productId,
  collectionId,
  sort,
  status,
  limit,
  moreUrl,
}) {
  const arena = useArena();

  const variables = {
    arenaId: arena.id,
    type,
    productId,
    relatedContentId: collectionId,
    limit,
    sort,
    status,
  };

  const { data } = useQuery(ArenaContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables,
  });

  const count = data?.arenaContents?.count || [];
  const nodes = data?.arenaContents?.nodes || [];

  if (nodes.length === 0) return null;

  const params = {
    type,
    productId,
    sort,
    status,
  };

  const query = qs.stringify(params, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  return (
    <Root>
      <HomepageTitle
        title={title}
        action={
          nodes.length < count && (
            <HeaderMoreLink url={moreUrl || `/contents${query}`} />
          )
        }
      />

      <ContentCarousel
        nodes={nodes}
        xs={12}
        sm={6}
        md={4}
        productId={productId}
        collectionId={collectionId}
      />
    </Root>
  );
}

ProductContents.propTypes = {
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  sort: PropTypes.string,
  status: PropTypes.string,
  moreUrl: PropTypes.string,
  limit: PropTypes.number,
};
