export function getBannerSrcSet(banner) {
  const formatToWidth = {
    DEFAULT: "600w",
    MEDIUM: "960w",
    HIGH: "1280w",
    MAXRES: "1920w",
  };

  return banner.map((t) => `${t.url} ${formatToWidth[t.format]}`).join(" ,");
}

export function getThumbnailSrcSet(thumbnails) {
  const formatToWidth = {
    DEFAULT: "600w",
    MEDIUM: "960w",
    HIGH: "1280w",
    MAXRES: "1920w",
  };

  return thumbnails
    .map((t) => `${t.url} ${formatToWidth[t.format]}`)
    .join(" ,");
}

export function getLogoSrcSet(thumbnails) {
  const formatToWidth = {
    DEFAULT: "30w",
    MEDIUM: "60w",
    HIGH: "120w",
    MAXRES: "240w",
  };

  return thumbnails
    .map((t) => `${t.url} ${formatToWidth[t.format]}`)
    .join(" ,");
}
