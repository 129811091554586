import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PlayerFooterButton from "core/components/PlayerFooterButton";
import Badge from "@mui/material/Badge";

const PREFIX = "QualityButton";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  anchorOriginTopRightRectangle: `${PREFIX}-anchorOriginTopRightRectangle`,
  badge: `${PREFIX}-badge`,
  badgeWrapper: `${PREFIX}-badgeWrapper`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  height: 36,

  [theme.breakpoints.up("sm")]: {
    height: 44,
  },
  [theme.breakpoints.up("md")]: {
    height: 48,
  },

  [`& .${classes.paper}`]: {
    backgroundColor: "rgba(0,0,0,0.75)",
    position: "absolute",
    bottom: 60,
    right: -12,
    overflow: "hidden",
  },
  [`& .${classes.anchorOriginTopRightRectangle}`]: {
    top: "4px",
    right: "-5px",
  },
  [`& .${classes.badge}`]: {
    height: "13px",
    minWidth: "13px",
    padding: "0 3px",
    borderRadius: "4px",
    fontSize: "0.55rem",
  },
  [`& .${classes.badgeWrapper}`]: {
    padding: "2px",
  },
}));

const AUTO = -1;

const LABELS = {
  "1080p": "HD",
  "1440p": "HD",
  "2160p": "4K",
};

function QualityButton({ videoActions, videoState }) {
  const { t } = useTranslation("core");

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((v) => !v);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClickItem = (index) => () => {
    videoActions.setLevel(index);
    setOpen(false);
  };

  if (!videoState) return null;

  const { levels = [], currentLevel, autoLevelEnabled } = videoState;

  if (levels.length < 2) return null;

  const levelItems = levels
    .map((level, index) => ({
      name: level.name,
      index,
    }))
    .sort((a, b) => parseInt(b.name, 10) - parseInt(a.name, 10));

  const currentLevelItem = levelItems.find(
    (level) => level.index === currentLevel
  );
  const currentBadge = currentLevelItem && LABELS[currentLevelItem.name];

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Root>
          <PlayerFooterButton
            onClick={handleClick}
            title={open ? "" : t("qualityButton.title")}
          >
            {currentBadge ? (
              <Badge
                classes={{
                  anchorOriginTopRightRectangular:
                    classes.anchorOriginTopRightRectangle,
                  badge: classes.badge,
                }}
                badgeContent={currentBadge}
                color="primary"
              >
                <SettingsIcon />
              </Badge>
            ) : (
              <SettingsIcon />
            )}
          </PlayerFooterButton>

          {open && (
            <Paper className={classes.paper}>
              <List disablePadding dense>
                {levelItems.map((level) => (
                  <ListItem
                    key={level.index}
                    button
                    selected={level.index === currentLevel}
                    onClick={handleClickItem(level.index)}
                  >
                    <div className={classes.badgeWrapper}>
                      {LABELS[level.name] ? (
                        <Badge
                          classes={{
                            anchorOriginTopRightRectangular:
                              classes.anchorOriginTopRightRectangle,
                            badge: classes.badge,
                          }}
                          badgeContent={LABELS[level.name]}
                          color="primary"
                        >
                          {level.name}
                        </Badge>
                      ) : (
                        level.name
                      )}
                    </div>
                  </ListItem>
                ))}
                <ListItem
                  button
                  selected={autoLevelEnabled}
                  disabled={autoLevelEnabled}
                  onClick={handleClickItem(AUTO)}
                >
                  {t("qualityButton.auto")}
                </ListItem>
              </List>
            </Paper>
          )}
        </Root>
      </ClickAwayListener>
    </>
  );
}

QualityButton.propTypes = {
  videoState: PropTypes.object,
  videoActions: PropTypes.object,
};

export default QualityButton;
