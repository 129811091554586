import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import moment from "core/utils/moment";
import format from "core/utils/interpolationFormat";

i18n.on("languageChanged", function (lng) {
  moment.locale(lng);
});

const options = {
  fallbackLng: "en",
  supportedLngs: ["en", "ru", "pl", "es"],
  load: "languageOnly",
  ns: ["core", "site", "player", "arena"],
  defaultNS: "core",
  saveMissing: true,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
    format,
  },
  backend: {
    loadPath: `/api/v1/translations/site/${process.env.RAZZLE_APP_TRANSLATION_VERSION}/{{ns}}/{{lng}}`,
    addPath: `/api/v1/translations/site/${process.env.RAZZLE_APP_TRANSLATION_VERSION}/{{ns}}/{{lng}}`,
    queryStringParams: { v: process.env.RAZZLE_SERVICE_VERSION || "dev" },
  },
  wait: process && !process.release,
};

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
  i18n.use(HttpBackend).use(initReactI18next).use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
