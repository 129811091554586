import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import ContentGridItem from "arena/components/ContentGridItem";
import Previewable from "arena/components/Previewable";
import { useMediaQuery } from "@mui/material";

export default function ContentGrid({ xs, sm, md, lg, xl, nodes }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      {nodes.map((content) => (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={content.id}>
          <Previewable isMobile={isMobile}>
            {(focused) => <ContentGridItem content={content} play={focused} />}
          </Previewable>
        </Grid>
      ))}
    </Grid>
  );
}

ContentGrid.propTypes = {
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  nodes: PropTypes.array,
};

ContentGrid.defaultProps = {
  xs: 12,
  sm: 6,
  md: 4,
  // lg: 3,
  // xl: 2,
  nodes: [],
};
