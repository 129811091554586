import React, { useState } from "react";
import PropTypes from "prop-types";
import StripePaymentForm from "arena/components/StripePaymentForm";
import YandexPaymentForm from "arena/components/YandexPaymentForm";
import PaypalPaymentForm from "arena/components/PaypalPaymentForm";
import RobokassaProcessor from "arena/components/RobokassaProcessor";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import apm from "core/utils/apm";
import { Typography } from "@mui/material";
import formatCurrency from "core/utils/currency";
import Alert from "@mui/material/Alert";

const SUBSCRIPTION = "SUBSCRIPTION";
const PPV = "PPV";
const DONATION = "DONATION";

export default function PaymentForm({
  productType,
  paymentHasError,
  setPaymentHasError,
  payment,
  onClose,
}) {
  const { t } = useTranslation("arena");
  const [error, setError] = useState(null);

  const {
    provider,
    recurrent,
    paymentData,
    returnUrl,
    originalAmount,
    amount,
    trialPeriod,
    period,
  } = payment;

  const onError = (error) => {
    setError(error);
    apm.captureError(error);
  };

  if (error) {
    return (
      <ErrorScreen
        title={t("paymentDialog.paymentError")}
        text={t("paymentDialog.paymentErrorDesc")}
      />
    );
  }

  let recurrentDescComponent;

  if (recurrent) {
    const pricePeriods = {
      7: t("productPayment.periodPerWeek"),
      30: t("productPayment.periodPerMonth"),
      90: t("productPayment.periodPer3Months"),
      180: t("productPayment.periodPer6Months"),
      365: t("productPayment.periodPerYear"),
    };

    const value = trialPeriod ? originalAmount.value : amount.value;
    const price = formatCurrency(value, amount.currency);
    const priceOnPeriod = `${price} ${pricePeriods[period].toLowerCase()}`;

    const descriptions = {
      SUBSCRIPTION: t("productPayment.subscriptionDescription", {
        priceOnPeriod,
      }),
      DONATION: t("productPayment.donationDescription", { priceOnPeriod }),
    };

    const titles = {
      SUBSCRIPTION: t("productPayment.subscriptionTitle"),
      DONATION: t("productPayment.donationTitle"),
    };

    recurrentDescComponent = (
      <Alert icon={false} severity="info">
        {provider !== "PAYPAL" && (
          <Typography variant="body2" gutterBottom>
            {titles[productType]}
          </Typography>
        )}
        <Typography variant="body2" gutterBottom>
          {descriptions[productType]}
        </Typography>
        <Typography variant="body2">{t("productPayment.canCancel")}</Typography>
      </Alert>
    );
  }

  if (!paymentData) {
    return null;
  }

  const baseProps = {
    paymentHasError,
    setPaymentHasError,
    setError: onError,
    recurrentDescComponent,
    returnUrl,
    amount,
  };

  if (provider === "ROBOKASSA") {
    return (
      <RobokassaProcessor
        paymentData={paymentData}
        onClose={onClose}
        {...baseProps}
      />
    );
  }

  if (provider === "YANDEX") {
    const { confirmationToken } = paymentData;

    if (confirmationToken) {
      return (
        <YandexPaymentForm
          confirmationToken={confirmationToken}
          {...baseProps}
        />
      );
    }
  }

  if (provider === "STRIPE") {
    return <StripePaymentForm paymentData={paymentData} {...baseProps} />;
  }

  if (provider === "PAYPAL") {
    return (
      <PaypalPaymentForm
        paymentId={payment.id}
        paymentData={paymentData}
        recurrentDescComponent={recurrentDescComponent}
        {...baseProps}
      />
    );
  }

  return null;
}

PaymentForm.propTypes = {
  productType: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
  paymentHasError: PropTypes.bool,
  setPaymentHasError: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentData: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired,
    recurrent: PropTypes.bool,
    trialPeriod: PropTypes.number,
    originalAmount: PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
    amount: PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
    period: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func,
};
