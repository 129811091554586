import { InMemoryCache } from "@apollo/client";

/**
 * Функция мерджа списков в кэше для запросов у которых пагинация реализована через skip, limit
 */
const connectionLimitMerge = (existing, incoming, { args: { skip = 0 } }) => {
  const { count, __typename } = incoming;

  let nodes = [];

  if (existing) {
    if (skip > 0) {
      nodes = nodes.concat(existing.nodes);
    }
  }

  nodes = nodes.concat(incoming.nodes);

  return {
    __typename,
    count,
    nodes,
  };
};

/**
 * Функция мерджа списков в кэше для запросов у которых пагинация реализована через page, rowsPerPage
 */
const connectionPageMerge = (existing, incoming, { args: { page = 0 } }) => {
  const { count, __typename } = incoming;

  let nodes = [];

  if (existing) {
    if (page > 0) {
      nodes = nodes.concat(existing.nodes);
    }
  }

  nodes = nodes.concat(incoming.nodes);

  return {
    __typename,
    count,
    nodes,
  };
};

export default function createApolloCache() {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          arenaContents: {
            keyArgs: [
              "productId",
              "status",
              "type",
              "sort",
              "period",
              "search",
              "relatedContentId",
              "tagIds",
              "limit",
              "rightholderIds",
            ],
            merge: connectionLimitMerge,
          },
          posts: {
            keyArgs: ["tagIds", "rowsPerPage", "rightholderIds"],
            merge: connectionPageMerge,
          },
          products: {
            keyArgs: [
              "rightholderSlug",
              "type",
              "types",
              "rowsPerPage",
              "rightholderIds",
            ],
            merge: connectionPageMerge,
          },
          productPurchases: {
            keyArgs: ["rowsPerPage"],
            merge: connectionPageMerge,
          },
          bans: {
            keyArgs: ["rowsPerPage"],
            merge: connectionPageMerge,
          },
          promocodes: {
            keyArgs: ["rowsPerPage"],
            merge: connectionPageMerge,
          },
          contents: {
            keyArgs: [
              "relatedContentId",
              "productId",
              "productIds",
              "search",
              "sportId",
              "sportIds",
              "sportSlug",
              "sportSlugs",
              "rightholderId",
              "rightholderIds",
              "rightholderSlug",
              "rightholderSlugs",
              "rightholderTagId",
              "rightholderTagIds",
              "rightholderTagSlug",
              "rightholderTagSlugs",
              "rightholderCountryCode",
              "tagId",
              "tagIds",
              "type",
              "types",
              "country",
              "streamStatus",
              "autoPostSocial",
              "sortType",
              "withClips",
              "rowsPerPage",
            ],
            merge: connectionPageMerge,
          },
          topContents: {
            keyArgs: [
              "sortType",
              "sportId",
              "sportSlug",
              "rightholderId",
              "rightholderIds",
              "rightholderSlug",
              "countryCode",
              "type",
              "types",
              "dateFrom",
              "dateTo",
            ],
            merge: connectionLimitMerge,
          },
        },
      },
    },
  });
}
