import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { durationFormat } from "core/utils/string";
import { useTranslation } from "react-i18next";
import ContentPreview from "core/components/ContentPreview";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
import Filter5Icon from "@mui/icons-material/Filter5";
import Filter6Icon from "@mui/icons-material/Filter6";
import Filter7Icon from "@mui/icons-material/Filter7";
import Filter8Icon from "@mui/icons-material/Filter8";
import Filter9Icon from "@mui/icons-material/Filter9";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import getReferrer from "core/utils/getReferrer";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

const PREFIX = "Thumbnail";

const classes = {
  media: `${PREFIX}-media`,
  icon: `${PREFIX}-icon`,
  time: `${PREFIX}-time`,
  live: `${PREFIX}-live`,
  subscribe: `${PREFIX}-subscribe`,
  notAvailable: `${PREFIX}-notAvailable`,
  error: `${PREFIX}-error`,
  errorIcon: `${PREFIX}-errorIcon`,
  premiumButton: `${PREFIX}-premiumButton`,
  premiumButtonInStream: `${PREFIX}-premiumButtonInStream`,
  small: `${PREFIX}-small`,
};

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  [`&.${classes.media}`]: {
    width: "100%",
    position: "relative",
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundColor: "black",
    overflow: "hidden",
    borderRadius: 0, // mobile
    transition: "background-size ease .25s",
    backgroundSize: "100%",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 4, // desktop
    },
    "&:hover": {
      backgroundSize: "150%",
    },
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: 40,
    height: 40,
    marginLeft: -20,
    marginTop: -20,
    backgroundColor: "rgba(0,0,0,0.85)",
    color: "white",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.time}`]: {
    position: "absolute",
    right: 8,
    bottom: 8,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white",
    borderRadius: 4,
    padding: "0px 4px",
  },

  [`& .${classes.live}`]: {
    position: "absolute",
    right: 8,
    top: 8,
    backgroundColor: "#e91e63",
    color: "white",
    borderRadius: 4,
    padding: "0px 4px",
    height: 20,
  },

  [`& .${classes.subscribe}`]: {
    position: "absolute",
    right: 8,
    top: 8,
  },

  [`&.${classes.notAvailable}`]: {
    position: "relative",
    "&:before": {
      zIndex: 1,
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.75)",
    },
  },

  [`& .${classes.error}`]: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 2,
    transform: "translate(-50%, -50%)",
  },

  [`& .${classes.errorIcon}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.premiumButton}`]: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "white",
    backgroundColor: "rgba(0,0,0,0.9)",
    borderRadius: 4,
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:focus": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
  },

  [`& .${classes.premiumButtonInStream}`]: {
    right: 50,
  },

  [`&.${classes.small}`]: {
    [`& .${classes.time}`]: {
      right: 4,
      bottom: 4,
    },
    [`& .${classes.live}`]: {
      right: 4,
      top: 4,
    },
    [`& .${classes.subscribe}`]: {
      right: 4,
      top: 4,
    },
    [`& .${classes.premiumButton}`]: {
      right: 4,
      top: 4,
    },
  },
}));

const STARTED = "STARTED";
const PENDING = "PENDING";
const FINISHED = "FINISHED";
const DEFAULT = "DEFAULT";
const STREAM = "STREAM";
const COLLECTION = "COLLECTION";
const VIDEO = "VIDEO";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

function getThumbnail(content) {
  const { thumbnails } = content;

  const thumbmail =
    thumbnails.find((t) => t.format === HIGH) ||
    thumbnails.find((t) => t.format === MAXRES) ||
    thumbnails.find((t) => t.format === MEDIUM) ||
    thumbnails.find((t) => t.format === DEFAULT);

  if (thumbmail) {
    return thumbmail.url;
  }
}

function getIcon(content) {
  const count = content?.childrenContentsCount || 0;

  if (count > 9) {
    return <Filter9PlusIcon />;
  } else if (count === 9) {
    return <Filter9Icon />;
  } else if (count === 8) {
    return <Filter8Icon />;
  } else if (count === 7) {
    return <Filter7Icon />;
  } else if (count === 6) {
    return <Filter6Icon />;
  } else if (count === 5) {
    return <Filter5Icon />;
  } else if (count === 4) {
    return <Filter4Icon />;
  } else if (count === 3) {
    return <Filter3Icon />;
  } else if (count === 2) {
    return <Filter2Icon />;
  } else if (count === 1) {
    return <Filter1Icon />;
  }

  return <FilterNoneIcon />;
}

function getCanPlay(content, play) {
  let canPlay = play;

  if (content.access.error) {
    canPlay = false;
  }

  if (typeof window !== "undefined") {
    if (content.access.hostnames.length) {
      if (
        !content.access.hostnames.some((hostname) => url.includes(hostname))
      ) {
        canPlay = false;
      }
    }
  }

  if (content.type === STREAM) {
    if (content.streamData.status !== STARTED) {
      canPlay = false;
    }
  }

  if (content.type === COLLECTION || content.premium) {
    canPlay = false;
  }

  return canPlay;
}

const url = getReferrer();

function Thumbnail({ content, play, start, finish, small }) {
  const { t } = useTranslation("core");

  const isStream = content.type === STREAM;
  const isCollection = content.type === COLLECTION;

  const image = getThumbnail(content);

  const icon = isCollection ? getIcon(content) : null;

  const { isAvailableOnSportrecs = true } = content;

  let canPlay = getCanPlay(content, play);

  const isStarted = content?.streamData?.status === STARTED;
  const displayLiveIcon = isStream && isStarted;
  const displayPremiumIcon = content.premium && !small;

  return (
    <StyledCardMedia
      image={image}
      className={classNames(classes.media, {
        [classes.small]: small,
        [classes.notAvailable]: !canPlay && !isAvailableOnSportrecs,
      })}
    >
      {isCollection && !canPlay && <div className={classes.icon}>{icon}</div>}

      {canPlay && (
        <ContentPreview
          token={content.access.token}
          title={content.title}
          getIpUrl={content.getIpUrl}
          start={start}
          finish={finish}
        />
      )}

      {!canPlay && !isAvailableOnSportrecs && (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.error}
          spacing={1}
        >
          <VpnLockIcon className={classes.errorIcon} />
          <Typography
            style={{ maxWidth: "80%" }}
            align="center"
            variant="caption"
            color="textPrimary"
          >
            {t("contentIsNotAvailable.unavailableOnSportrecs.text")}
          </Typography>
        </Grid>
      )}

      {!canPlay && content.duration && content.type === VIDEO && (
        <Typography
          className={classes.time}
          variant="caption"
          color="textPrimary"
        >
          {durationFormat(content.duration)}
        </Typography>
      )}

      {displayLiveIcon && (
        <Typography
          className={classes.live}
          variant="caption"
          color="textPrimary"
        >
          LIVE
        </Typography>
      )}

      {displayPremiumIcon && (
        <Typography
          classes={{
            root: classNames(classes.premiumButton, {
              [classes.premiumButtonInStream]: displayLiveIcon,
            }),
          }}
          variant="caption"
          color="textPrimary"
        >
          {content.viewerPurchase ? (
            <LockOpenIcon sx={{ fontSize: "18px" }} />
          ) : (
            <LockIcon sx={{ fontSize: "18px" }} />
          )}
        </Typography>
      )}
    </StyledCardMedia>
  );
}

Thumbnail.propTypes = {
  play: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    getIpUrl: PropTypes.string,
    duration: PropTypes.number,
    type: PropTypes.oneOf([STREAM, VIDEO, COLLECTION]).isRequired,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    access: PropTypes.shape({
      token: PropTypes.string,
      error: PropTypes.string,
      hostnames: PropTypes.arrayOf(PropTypes.string),
    }),
    video: PropTypes.shape({
      id: PropTypes.string.isRequired,
      duration: PropTypes.number,
    }),
    streamData: PropTypes.shape({
      status: PropTypes.oneOf([STARTED, PENDING, FINISHED]).isRequired,
    }),
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
        url: PropTypes.string.isRequired,
      })
    ),

    childrenContentsCount: PropTypes.number,
    isAvailableOnSportrecs: PropTypes.bool,
  }),
  start: PropTypes.number,
  finish: PropTypes.number,
  small: PropTypes.bool,
};

export default React.memo(Thumbnail);
