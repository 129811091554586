import React, { useState } from "react";
import PropTypes from "prop-types";
import { postMessage } from "core/utils/tracker";
import { useQuery } from "@apollo/client";
import { SponsorVideoQuery } from "core/queries";
import Video from "./Video";
import Vast from "./Vast";
import Yandex from "./Yandex";
import AdfoxBanner from "./AdfoxBanner";

const NOT_FOUND = "NOT_FOUND";

export default function SponsorVideo({
  embedId,
  contentId,
  sportId,
  rightholderId,
  platformId,
  videoPlace,
  chatMessagePlace,
  fullscreenTarget,
  onFinish,
  onStart,
  onChatAdv,
  onNotFound,
  muted,
  shareUrl,
}) {
  const [excludeCreativeIds, setExcludeCreativeIds] = useState([]);

  const { data, loading } = useQuery(SponsorVideoQuery, {
    skip: !process.browser,
    fetchPolicy: "network-only",
    variables: {
      contentId,
      rightholderId,
      platformId,
      adfoxBannerPlace: videoPlace,
      vastPlace: videoPlace,
      videoPlace,
      chatMessagePlace,
      embedId: embedId || undefined,
      excludeCreativeIds,
    },
    onCompleted(data) {
      // console.log("Sponsor Campaign", data?.sponsorCampaign);

      const notFound =
        !data?.sponsorCampaign?.video &&
        !data?.sponsorCampaign?.vast &&
        !data?.sponsorCampaign?.adfoxBanner &&
        !data?.sponsorCampaign?.chatMessage;

      if (notFound) {
        onNotFound();
        postMessage({
          eventName: NOT_FOUND,
          creative: "SponsorVideo",
          place: videoPlace,
        });
      }

      if (data?.sponsorCampaign?.chatMessage) {
        onChatAdv(data.sponsorCampaign.chatMessage);
      }
    },
  });

  if (loading) return null;
  if (!data?.sponsorCampaign) return null;
  if (!process.browser) return null;

  const { video, vast, adfoxBanner } = data.sponsorCampaign;

  if (adfoxBanner) {
    const onError = () => {
      setExcludeCreativeIds((ids) => [...ids, adfoxBanner.id]);
    };

    return (
      <AdfoxBanner
        adfoxBanner={adfoxBanner}
        place={videoPlace}
        onStart={onStart}
        onFinish={onFinish}
        onError={onError}
      />
    );
  }

  if (video) {
    const onError = () => {
      setExcludeCreativeIds((ids) => [...ids, video.id]);
    };

    // hardcode for test period with wrestlingtv
    const isDisableable =
      videoPlace.endsWith("ON_SITE") &&
      rightholderId === "5V2a_7I3_bzIVThUb5_R5g";

    return (
      <Video
        key={video.id}
        video={video}
        videoPlace={videoPlace}
        fullscreenTarget={fullscreenTarget}
        onFinish={onFinish}
        onStart={onStart}
        onError={onError}
        muted={muted}
        isDisableable={isDisableable}
      />
    );
  }

  if (vast) {
    const onError = () => {
      // console.log("onError", error);
      // console.log("exclude creative", vast.id);
      setExcludeCreativeIds((ids) => [...ids, vast.id]);
    };

    if (vast.yandex) {
      return (
        <Yandex
          key={vast.id}
          vast={vast}
          vastPlace={videoPlace}
          fullscreenTarget={fullscreenTarget}
          onFinish={onFinish}
          onStart={onStart}
          muted={muted}
          onError={onError}
          sportId={sportId}
          shareUrl={shareUrl}
        />
      );
    }

    const antiFrod = false;
    // data.sponsorCampaign.id === "09ed09zRvljGkGXz2Zz7fLJCh_2VdjlmvWyJLlSiT-g";

    return (
      <Vast
        key={vast.id}
        vast={vast}
        vastPlace={videoPlace}
        fullscreenTarget={fullscreenTarget}
        onFinish={onFinish}
        onStart={onStart}
        onError={onError}
        muted={muted}
        sportId={sportId}
        shareUrl={shareUrl}
        antiFrod={antiFrod}
      />
    );
  }

  return null;
}

SponsorVideo.defaultProps = {
  onFinish() {},
  onStart() {},
  onChatAdv() {},
  onNotFound() {},
};

SponsorVideo.propTypes = {
  embedId: PropTypes.string,
  contentId: PropTypes.string,
  sportId: PropTypes.string,
  rightholderId: PropTypes.string.isRequired,
  platformId: PropTypes.string,
  videoPlace: PropTypes.string.isRequired,
  chatMessagePlace: PropTypes.string,
  fullscreenTarget: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onChatAdv: PropTypes.func.isRequired,
  onNotFound: PropTypes.func.isRequired,
  muted: PropTypes.bool,
  shareUrl: PropTypes.string,
};
