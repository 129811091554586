import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import ChooseProductDialog from "core/components/ChooseProductDialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { truncate } from "core/utils/string";
import getMinPrice from "core/utils/getMinPrice";
import Grid from "@mui/material/Grid";
import formatCurrency from "core/utils/currency";
import moment from "core/utils/moment";

const SUBSCRIPTION = "SUBSCRIPTION";
const PPV = "PPV";
const DONATION = "DONATION";
const PREFIX = "PaymentScreen";

const classes = {
  button: `${PREFIX}-button`,
  title: `${PREFIX}-title`,
  root: `${PREFIX}-root`,
};

const StyledPlayerPreview = styled(PlayerPreview)(({ theme }) => ({
  [`& .${classes.button}`]: {
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  },

  [`& .${classes.title}`]: {
    fontSize: "1rem",
    lineHeight: "1",
    // fontWeight: 600,
    fontWeight: 800,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.2rem",
      lineHeight: "1.1",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
  },

  [`& .${classes.root}`]: {
    position: "relative",
    zIndex: 2,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 800,
    margin: "auto",
    padding: theme.spacing(0, 2),
  },
}));

/**
 * Получить текст для кнопки
 * @param {Function} t
 * @param {Object} button
 * @returns
 */
function getPriceButtonText(t, button) {
  const { product, type, minPrice } = button;

  const value = formatCurrency(minPrice.value, minPrice.currency);

  if (type === DONATION) {
    // if (product.donates.length > 1) {
    //   return t("paymentScreen.donatePrices", { value });
    // }

    // return t("paymentScreen.donatePrice", { value });
    return t("paymentScreen.donate");
  }

  if (type === SUBSCRIPTION) {
    if (minPrice.value === 0) {
      return t("paymentScreen.recurrentPricesFree");
    }

    if (product.prices.length > 1) {
      return t("paymentScreen.recurrentPrices", { value });
    }

    return t("paymentScreen.recurrentPrice", { value });
  }

  if (type === PPV) {
    if (product.prices.length > 1) {
      return t("paymentScreen.ppvPrices", { value });
    }

    return t("paymentScreen.ppvPrice", { value });
  }

  throw new Error("unknown button type"); // до сюда по идее никогда не дойдет, но на всякий случай сделаю эксепшн
}

function getButton(buttons, type) {
  const items = buttons.filter((b) => b.type === type);
  const isMany = items.length > 1;
  const item = items.pop();

  if (item) {
    return {
      type,
      isMany,
      product: item.product,
      minPrice: item.minPrice,
    };
  }
}

/**
 * Получить данные для отрисовки кнопок
 * @param {Array<Product>} products
 * @returns
 */
function getButtons(products = []) {
  const buttons = products
    .map((product) => {
      const minPrice = getMinPrice(product);
      const type = product.type;
      return {
        type,
        product,
        minPrice,
      };
    })
    .sort((a, b) => b.minPrice.value - a.minPrice.value);

  return [
    getButton(buttons, SUBSCRIPTION),
    getButton(buttons, PPV),
    getButton(buttons, DONATION),
  ].filter(Boolean);
}

function getSubtitle(t, content) {
  if (content.type === "VIDEO") {
    if (content.accessStartAt) {
      if (moment(content.accessStartAt).isAfter()) {
        return t("contentIsNotAvailable.accessStartAt.text", {
          date: content.accessStartAt,
        });
      }
    }
  }
  if (content.type === "STREAM") {
    switch (content.streamData.status) {
      case "STARTED":
        return t("streamPending.started");

      case "PENDING":
        if (content.startAt) {
          return t("streamPending.textWithDate", { date: content.startAt });
        }

        return null;

      case "FINISHED":
        return t("streamPending.finished", {
          date: content.streamData.finishAt,
        });

      default:
        return null;
    }
  }
}

export default function PaymentScreen({ content, onClickProductButton }) {
  const { t } = useTranslation("core");
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(false);

  const products = (content?.products?.nodes || []).filter(
    (product) => product.prices.length > 0 || product.donates.length > 0
  );

  const buttons = getButtons(products);

  if (buttons.length === 0) {
    return (
      <StyledPlayerPreview preview={content.preview} overlay>
        <ErrorScreen
          title={t("paymentScreen.productError.title")}
          text={t("paymentScreen.productError.text")}
        />
      </StyledPlayerPreview>
    );
  }

  const title = smUp ? content.title : truncate(content.title, 100);
  const subTitle = getSubtitle(t, content);

  const onClose = () => {
    setOpen(false);
  };

  const productsToDialog = products.filter((product) => {
    return product.type === open;
  });

  return (
    <StyledPlayerPreview preview={content.preview} overlay>
      <div className={classes.root}>
        <Grid container spacing={smUp ? 3 : 2} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              varianth="h1"
              color="textPrimary"
              align="center"
              className={classes.title}
            >
              {title}
            </Typography>

            {subTitle && (
              <Typography
                varianth="body1"
                color="textSecondary"
                align="center"
                sx={{ mt: 1 }}
              >
                {subTitle}
              </Typography>
            )}
          </Grid>

          {buttons.map((button) => {
            const { isMany, product, type } = button;

            const text = getPriceButtonText(t, button);

            const onClick = (event) => {
              if (onClickProductButton) {
                event.preventDefault();
                event.stopPropagation();

                if (isMany) {
                  switch (type) {
                    case SUBSCRIPTION:
                      return onClickProductButton({
                        type: "choose_subscriptions",
                      });
                    case PPV:
                      return onClickProductButton({ type: "choose_ppv" });
                    case DONATION:
                      return onClickProductButton({ type: "choose_donates" });
                    default:
                      return; // это никогда не будет достигнуто
                  }
                }

                return onClickProductButton({
                  type: "product",
                  productId: product.id,
                });
              }

              if (isMany) {
                event.preventDefault();
                event.stopPropagation();

                return setOpen(type);
              }
            };

            return (
              <Grid item key={product.id}>
                <Button
                  variant="contained"
                  color="primary"
                  size={xs ? "small" : "medium"}
                  href={product.shareUrl}
                  target="_blank"
                  className={classes.button}
                  onClick={onClick}
                >
                  {text}
                </Button>
              </Grid>
            );
          })}
        </Grid>

        <ChooseProductDialog
          open={!!open}
          onClose={onClose}
          products={productsToDialog}
        />
      </div>
    </StyledPlayerPreview>
  );
}

PaymentScreen.propTypes = {
  onClickProductButton: PropTypes.func,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    preview: PropTypes.string,
    startAt: PropTypes.string,
    premium: PropTypes.bool,
    shareUrl: PropTypes.string.isRequired,
    products: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          shareUrl: PropTypes.string.isRequired,
          prices: PropTypes.array.isRequired,
        })
      ),
    }),
  }).isRequired,
};
