import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { CardActionArea } from "@mui/material";
import ReactGA from "react-ga4";

const PREFIX = "Item";

const classes = {
  root: `${PREFIX}-root`,
  actionArea: `${PREFIX}-actionArea`,
  avatar: `${PREFIX}-avatar`,
  img: `${PREFIX}-img`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.03)",

  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.actionArea}`]: {
    // padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.avatar}`]: {
    width: 160,
    height: 90,
    [theme.breakpoints.up("sm")]: {
      width: 240,
      height: 135,
    },
    [theme.breakpoints.up("md")]: {
      width: 320,
      height: 180,
    },
  },

  [`& .${classes.img}`]: {
    objectFit: "contain",
  },
}));

export default function Item({ logo }) {
  const onClick = () => {
    ReactGA.event({
      category: "ArenaPartners",
      action: "Click to logo",
      label: logo.title,
    });
  };

  return (
    <StyledCard className={classes.root}>
      <Tooltip title={logo.title || ""}>
        <CardActionArea
          className={classes.actionArea}
          href={logo.url}
          disabled={!logo.url}
          target={logo.openInNewWindow ? "_blank" : ""}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          <Avatar
            variant="rounded"
            src={logo.src}
            alt={logo.title || "partner logo"}
            classes={{ root: classes.avatar, img: classes.img }}
          />
        </CardActionArea>
      </Tooltip>
    </StyledCard>
  );
}

Item.propTypes = {
  logo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    url: PropTypes.string,
    src: PropTypes.string.isRequired,
    openInNewWindow: PropTypes.bool,
  }),
};
