import { init as initApm } from "@elastic/apm-rum";

let config = {
  serverUrl: process.env.RAZZLE_ELASTIC_APM_FRONTEND_SERVER_URL,
  serviceName: process.env.RAZZLE_ELASTIC_APM_SERVICE_NAME,
  serviceVersion: process.env.RAZZLE_ELASTIC_APM_SERVICE_VERSION,
  environment: process.env.RAZZLE_ELASTIC_APM_ENVIRONMENT,
  pageLoadTransactionName: "serverPageLoadTransactionName",
  active: process.env.RAZZLE_ELASTIC_APM_ACTIVE === "true",
  ignoreTransactions: [/hls*/],
  disableInstrumentations: [
    "page-load",
    "history",
    "eventtarget",
    "click",
    "xmlhttprequest",
    "fetch",
    "error",
  ],
  flushInterval: 2000,
};

if (process.browser) {
  Object.assign(config, {
    serverUrl: window.location.origin,
    pageLoadTransactionName: window.location.pathname,
    serviceVersion: window.__APM_SERVICE_VERSION__,
  });
}

const apm = initApm(config);

export default apm;
