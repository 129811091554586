import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UpdateProductPurchaseMutation } from "arena/mutations";
import { useMutation } from "@apollo/client";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { withSnackbar } from "notistack";

function ToggleRenewProductPurchase({ productPurchase, enqueueSnackbar }) {
  const { t } = useTranslation("arena");

  const [updateProductPurchase, { loading }] = useMutation(
    UpdateProductPurchaseMutation,
    {
      refetchQueries: ["Product"],
      onError: (err) => {
        if (err.message.includes("Update paypal subscription error")) {
          enqueueSnackbar(t("productPurchase.paypalUpdateError"), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("productPurchase.updateError"), {
            variant: "error",
          });
        }
      },
    }
  );

  const onChangeSendNotifications = (event) => {
    event.preventDefault();
    event.stopPropagation();

    updateProductPurchase({
      variables: {
        id: productPurchase.id,
        input: {
          renewSubscription: !productPurchase.renewSubscription,
        },
      },
    });
  };

  if (!productPurchase.renewAvailable) {
    const unavailableDesc =
      productPurchase.provider === "PAYPAL"
        ? t("productPurchase.paypalCanceled")
        : t("productPurchase.paymentMethodRemoved");

    return (
      <Tooltip title={unavailableDesc}>
        <div>
          <FormControlLabel
            control={<Switch disabled={true} checked={false} />}
            label={t("productPurchase.renewSubscription")}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        productPurchase.renewSubscription
          ? t("productPurchase.renewSubscriptionEnabled")
          : t("productPurchase.renewSubscriptionDisabled")
      }
    >
      <div>
        <FormControlLabel
          control={
            <Switch
              disabled={loading}
              checked={productPurchase.renewSubscription}
              onChange={onChangeSendNotifications}
            />
          }
          label={t("productPurchase.renewSubscription")}
        />
      </div>
    </Tooltip>
  );
}

ToggleRenewProductPurchase.propTypes = {
  enqueueSnackbar: PropTypes.func,
  productPurchase: PropTypes.shape({
    id: PropTypes.string.isRequired,
    provider: PropTypes.string,
    renewAvailable: PropTypes.bool,
    renewSubscription: PropTypes.bool,
  }),
};

export default withSnackbar(ToggleRenewProductPurchase);
