import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { withSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";
import SettingsProfile from "arena/components/SettingsProfile";
import SettingsPromocodes from "arena/components/SettingsPromocodes";
import SettingsPayment from "arena/components/SettingsPayment";
import SettingsAuth from "arena/components/SettingsAuth";
import SettingsDevices from "arena/components/SettingsDevices";
import SettingsTabs from "arena/components/SettingsTabs";
import { useQuery } from "@apollo/client";
import { SettingsQuery } from "arena/queries";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PROFILE = "PROFILE";
const PAYMENT = "PAYMENT";
const AUTH = "AUTH";
const DEVICES = "DEVICES";
const PROMOCODES = "PROMOCODES";

function SettingsDialog({
  open,
  onClose,
  oauthConnect,
  addDevice,
  hasAddDevice,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [tab, setTab] = useState(
    oauthConnect ? AUTH : hasAddDevice ? DEVICES : PROFILE
  );

  const { data } = useQuery(SettingsQuery, {
    skip: !open,
  });

  const userFields = data?.arena?.userFields || [];
  const arenaAuth = data?.arena?.arenaAuth;

  const onChangeTab = (event, tab) => {
    event.preventDefault();
    setTab(tab);
  };

  const enableOauth =
    [
      arenaAuth?.google?.enabled, //
      arenaAuth?.apple?.enabled,
    ].filter(Boolean).length > 0;

  const tabs = <SettingsTabs tab={tab} onChangeTab={onChangeTab} />;

  return (
    <Dialog
      fullWidth
      disableScrollLock
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="body"
    >
      {tab === PROFILE && (
        <SettingsProfile
          tabs={tabs}
          onClose={onClose}
          userFields={userFields}
        />
      )}

      {tab === PAYMENT && <SettingsPayment tabs={tabs} onClose={onClose} />}

      {tab === DEVICES && (
        <SettingsDevices
          tabs={tabs}
          onClose={onClose}
          addDevice={addDevice}
          hasAddDevice={hasAddDevice}
        />
      )}

      {tab === PROMOCODES && (
        <SettingsPromocodes tabs={tabs} onClose={onClose} />
      )}

      {enableOauth && tab === AUTH && (
        <SettingsAuth
          oauthConnect={oauthConnect}
          arenaAuth={arenaAuth}
          tabs={tabs}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
}

SettingsDialog.propTypes = {
  open: PropTypes.bool,
  hasAddDevice: PropTypes.bool,
  addDevice: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  oauthConnect: PropTypes.string,
};

export default withSnackbar(SettingsDialog);
