import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import contentUrl from "core/utils/contentUrl";
import Thumbnail from "core/components/Thumbnail";
import ThumbnailProgress from "core/components/ThumbnailProgress";
import { useArena } from "arena/components/ArenaProvider";
import getContentMeta from "site/utils/getContentMeta";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const PREFIX = "ContentGridItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  mediaLink: `${PREFIX}-mediaLink`,
  body: `${PREFIX}-body`,
  cardHeader: `${PREFIX}-cardHeader`,
  link: `${PREFIX}-link`,
  notAvailable: `${PREFIX}-notAvailable`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    backgroundColor: "#151515",
  },

  [`& .${classes.mediaLink}`]: {
    position: "relative",
    display: "block",
    color: "inherit",
    // [theme.breakpoints.up("sm")]: {
    //   transition: "transform .15s ease-in-out",
    //   transform: "scale(1)",
    //   "&:hover": {
    //     transform: "scale(1.03)",
    //   },
    // },
  },

  [`& .${classes.body}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    alignItems: "flex-start",
    padding: "16px 0",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    fontSize: "1.1rem",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem",
    },
  },

  [`& .${classes.notAvailable}`]: {
    cursor: "auto",
    pointerEvents: "none",
    userSelect: "none",
    // [theme.breakpoints.up("sm")]: {
    //   "&:hover": {
    //     transform: "scale(1)",
    //   },
    // },
  },
}));

export default function ContentGridItem({
  start,
  finish,
  content,
  play,
  className,
  productId,
  collectionId,
}) {
  const arena = useArena();
  const { t } = useTranslation("arena");

  const to = contentUrl(content.siteUrl, {
    start,
    finish,
    productId,
    collectionId,
  });

  const { isAvailableOnSportrecs = true } = content;

  const subheader = arena.showViewsCount ? (
    <Typography variant="body2" color="textSecondary">
      {getContentMeta(t, content)}
    </Typography>
  ) : null;

  return (
    <Root className={className}>
      <Link
        to={to}
        className={classNames(classes.mediaLink, {
          [classes.notAvailable]: !isAvailableOnSportrecs,
        })}
      >
        <Thumbnail
          content={content}
          play={play}
          start={start}
          finish={finish}
        />

        <ThumbnailProgress content={content} />
      </Link>

      <CardHeader
        className={classes.cardHeader}
        title={content.title}
        subheader={subheader}
        titleTypographyProps={{
          color: "textPrimary",
          className: classNames(classes.link, {
            [classes.notAvailable]: !isAvailableOnSportrecs,
          }),
          gutterBottom: true,
          component: Link,
          to,
        }}
      />
    </Root>
  );
}

ContentGridItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    isAvailableOnSportrecs: PropTypes.bool,
  }),
  play: PropTypes.bool,
  className: PropTypes.string,
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  start: PropTypes.number,
  finish: PropTypes.number,
};
