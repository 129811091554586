import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CustomField from "arena/components/CustomField";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LanguageField from "arena/components/LanguageField";
import AvatarField from "arena/components/AvatarField";
import TimeZoneField from "arena/components/TimeZoneField";
import CountryField from "arena/components/CountryField";
import {
  getTimeZone,
  setTimeZone as setGlobalTimeZone,
} from "core/utils/moment";

function CreateProfile({
  viewer,
  onUpdateSettings,
  loading,
  onError,
  userFields,
}) {
  const { t } = useTranslation("arena");
  const [language, setLanguage] = useState(viewer.language);
  const [country, setCountry] = useState(viewer.country);
  const [timeZone, setTimeZone] = useState(viewer.timeZone || getTimeZone());

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      firstName: viewer.firstName,
      lastName: viewer.lastName,
    },
  });

  const onSubmit = (input) => {
    const { photoId, firstName, lastName, ...customFields } = input;

    setGlobalTimeZone(timeZone);

    onUpdateSettings({
      photoId,
      firstName,
      lastName,
      language,
      timeZone,
      country,
      customFields,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {t("onboardingDialog.profile.title")}
          </Typography>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {t("save")}
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} container justifyContent="center">
            <AvatarField
              image={viewer?.photo?.url}
              onError={onError}
              onChange={(photoId) => setValue("photoId", photoId)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("onboardingDialog.profile.firstName")}
              {...register("firstName")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("onboardingDialog.profile.lastName")}
              {...register("lastName")}
            />
          </Grid>
          <Grid item xs={12}>
            <CountryField
              required
              label={t("onboardingDialog.profile.country")}
              value={country}
              onChange={(country) => {
                setCountry(country);
                setTimeZone("");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <LanguageField
              required
              label={t("onboardingDialog.profile.language")}
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TimeZoneField
              required
              country={country}
              label={t("onboardingDialog.profile.timeZone")}
              value={timeZone}
              onChange={setTimeZone}
            />
          </Grid>

          {userFields.map((userField, index) => (
            <Grid item xs={12} key={index}>
              <CustomField
                {...userField}
                field={register(userField.name, {
                  required: userField.required,
                })}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </form>
  );
}

CreateProfile.propTypes = {
  loading: PropTypes.bool,
  onUpdateSettings: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  userFields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      required: PropTypes.bool,
    })
  ),
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    country: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isOnboarded: PropTypes.bool,
    login: PropTypes.string,
    email: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
    language: PropTypes.string,
    timeZone: PropTypes.string,
  }),
};

export default CreateProfile;
