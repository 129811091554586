import React from "react";
import PropTypes from "prop-types";
import { SponsorMultiAdvQuery } from "core/queries";
import { useQuery } from "@apollo/client";
import SponsorInStreamBanner from "core/components/SponsorInStreamBanner";
import SponsorCornerBanner from "core/components/SponsorCornerBanner";

const VIDEO_IN_EMBED = "VIDEO_IN_EMBED";
const STREAM_IN_EMBED = "STREAM_IN_EMBED";
const VIDEO_ON_SITE = "VIDEO_ON_SITE";
const STREAM_ON_SITE = "STREAM_ON_SITE";

export default function SponsorMultiAdv({
  inStreamBannerPlace,
  cornerBannerPlace,
  contentId,
  rightholderId,
  platformId,
  onStart,
  onFinish,
  setInStreamBannerHeight,
  setCornerBannerHeight,
  onCompleted,
  embedId,
}) {
  const { data, loading } = useQuery(SponsorMultiAdvQuery, {
    skip: !process.browser,
    fetchPolicy: "network-only",
    variables: {
      contentId,
      rightholderId,
      platformId,
      inStreamBannerPlace,
      cornerBannerPlace,
      embedId: embedId || undefined,
    },
    onCompleted,
  });

  if (loading) return null;
  if (!data?.sponsorCampaign) return null;

  const { inStreamBanner, cornerBanner } = data.sponsorCampaign;

  if (cornerBanner) {
    return (
      <SponsorCornerBanner
        cornerBanner={cornerBanner}
        setCornerBannerHeight={setCornerBannerHeight}
        onStart={onStart}
        onFinish={onFinish}
      />
    );
  }

  if (inStreamBanner) {
    return (
      <SponsorInStreamBanner
        inStreamBanner={inStreamBanner}
        setInStreamBannerHeight={setInStreamBannerHeight}
        onStart={onStart}
        onFinish={onFinish}
      />
    );
  }
}

SponsorMultiAdv.defaultProps = {
  onCompleted() {},
  onStart() {},
  onFinish() {},
  setInStreamBannerHeight() {},
  setCornerBannerHeight() {},
};

SponsorMultiAdv.propTypes = {
  inStreamBannerPlace: PropTypes.oneOf([
    VIDEO_IN_EMBED,
    STREAM_IN_EMBED,
    VIDEO_ON_SITE,
    STREAM_ON_SITE,
  ]),
  cornerBannerPlace: PropTypes.oneOf([
    VIDEO_IN_EMBED,
    STREAM_IN_EMBED,
    VIDEO_ON_SITE,
    STREAM_ON_SITE,
  ]),
  platformId: PropTypes.string,
  contentId: PropTypes.string.isRequired,
  rightholderId: PropTypes.string.isRequired,
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  setInStreamBannerHeight: PropTypes.func,
  setCornerBannerHeight: PropTypes.func,
  onCompleted: PropTypes.func,
  embedId: PropTypes.string,
};
