import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useQuery } from "@apollo/client";
import { PostQuery } from "arena/queries";
import PostHelmet from "./PostHelmet";
import ErrorScreen from "core/components/ErrorScreen";
import NoMatchScreen from "arena/components/NoMatchScreen";
import useScrollToTop from "core/hooks/useScrollToTop";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Text = styled(Typography)({
  "& img": {
    maxWidth: "100%",
  },
});

export default function Product() {
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation("arena");
  const { postId } = params;

  const onCompleted = (data) => {
    if (data.post) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.post.title,
      });
    }
  };

  const { data, loading, error } = useQuery(PostQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: postId,
    },
    onCompleted,
  });

  const post = data?.post;

  useScrollToTop();

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!post && !loading) {
    return <NoMatchScreen />;
  }

  return (
    <>
      {post && <PostHelmet post={post} />}

      {post && (
        <Container maxWidth="md" sx={{ p: 0 }}>
          <Card sx={{ backgroundColor: "rgba(255,255,255,0.03)" }}>
            <CardMedia
              component="img"
              image={post.image.url}
              alt={post.title}
            />
            <CardHeader
              title={post.title}
              subheader={t("publishAt", { date: post.publishAt })}
              titleTypographyProps={{ sx: { mb: 2 } }}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="subheader1" color="textPrimary">
                {post.description}
              </Typography>
              <Text
                variant="body1"
                dangerouslySetInnerHTML={{ __html: post.text }}
              />

              <Grid container spacing={1}>
                {post.tags.map((tag) => (
                  <Grid item key={tag.id}>
                    <Chip
                      label={tag.title}
                      variant="outlined"
                      component={Link}
                      clickable
                      to={`/posts?tagId=${tag.id}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
}

Product.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      postId: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
