import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ButtonBase from "@mui/material/ButtonBase";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import { alpha } from "@mui/system";

const PREFIX = "ContentCarouselPrevButton";

const classes = {
  next: `${PREFIX}-next`,
  btnRoot: `${PREFIX}-btnRoot`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.next}`]: {
    position: "absolute",
    left: 0,
    top: 4,
    bottom: 0,
    zIndex: 1,
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
    pointerEvents: "none",

    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 12px)",
      paddingTop: "27.3%",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(33.333333% - 16px)",
      paddingTop: "18.2%",
    },
  },

  [`& .${classes.btnRoot}`]: {
    pointerEvents: "all",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: 60,

    backgroundColor: "transparent",
    backgroundImage: `linear-gradient(90deg, ${
      theme.palette.background.body
    }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    color: "rgba(255, 255, 255, 0.3)",

    "&:hover": {
      color: "#fff",
    },

    [theme.breakpoints.up("sm")]: {
      width: 90,
      left: -24,
    },
    [theme.breakpoints.up("md")]: {
      width: 100,
    },
  },

  [`& .${classes.icon}`]: {
    fontSize: 60,
  },
}));

export default function ContentCarouselPrevButton({ disabled, onClick }) {
  if (disabled) return null;

  return (
    <Root className={classes.next}>
      <ButtonBase
        size="small"
        classes={{ root: classes.btnRoot }}
        onClick={onClick}
        aria-label="Prev"
      >
        <NavigateBeforeRoundedIcon color="inherit" className={classes.icon} />
      </ButtonBase>
    </Root>
  );
}

ContentCarouselPrevButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
