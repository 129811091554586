import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomField from "arena/components/CustomField";
import LanguageField from "arena/components/LanguageField";
import { useMutation } from "@apollo/client";
import { UpdateSettingsMutation } from "arena/mutations";
import AvatarField from "arena/components/AvatarField";
import TimeZoneField from "arena/components/TimeZoneField";
import CountryField from "arena/components/CountryField";
import SettingsHeader from "arena/components/SettingsHeader";
import { useViewer } from "core/components/ViewerProvider";
import { useForm } from "react-hook-form";
import { setTimeZone as setGlobalTimeZone } from "core/utils/moment";

const PREFIX = "SettingsProfile";

const classes = {
  content: `${PREFIX}-content`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.content}`]: {
    overflow: "hidden",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
}));

function SettingsProfile({ onClose, enqueueSnackbar, userFields, tabs }) {
  const { t } = useTranslation("arena");

  const viewer = useViewer();
  const [language, setLanguage] = useState(viewer.language);
  const [country, setCountry] = useState(viewer.country);
  const [timeZone, setTimeZone] = useState(viewer.timeZone);

  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      firstName: viewer.firstName,
      lastName: viewer.lastName,

      ...viewer.customFields,
    },
  });

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const onCompleted = () => {
    enqueueSnackbar(t("settingsProfile.updated"), {
      variant: "success",
    });
    onClose();
  };

  const [updateSettings, { loading }] = useMutation(UpdateSettingsMutation, {
    onError,
    onCompleted,
  });

  const onSubmit = (input) => {
    const { photoId, firstName, lastName, ...customFields } = input;

    setGlobalTimeZone(timeZone);

    updateSettings({
      variables: {
        input: {
          photoId,
          firstName,
          lastName,
          language,
          timeZone,
          country,
          customFields,
        },
      },
    });
  };

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <SettingsHeader
        onClose={onClose}
        title={t("settingsProfile.title")}
        action={
          <Button
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {t("save")}
          </Button>
        }
        tabs={tabs}
      />

      <DialogContent className={classes.content}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} container justifyContent="center">
            <AvatarField
              image={viewer?.photo?.url}
              onError={onError}
              onChange={(photoId) => setValue("photoId", photoId)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("settingsProfile.firstName")}
              {...register("firstName")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("settingsProfile.lastName")}
              {...register("lastName")}
            />
          </Grid>
          <Grid item xs={12}>
            <CountryField
              required
              label={t("settingsProfile.country")}
              value={country}
              onChange={(country) => {
                setCountry(country);
                setTimeZone("");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <LanguageField
              required
              label={t("onboardingDialog.profile.language")}
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TimeZoneField
              required
              country={country}
              label={t("settingsProfile.timeZone")}
              value={timeZone}
              onChange={setTimeZone}
            />
          </Grid>

          {userFields.map((userField, index) => (
            <Grid item xs={12} key={index}>
              <CustomField
                {...userField}
                value={getValues(userField.name)}
                field={register(userField.name, {
                  required: userField.required,
                })}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Root>
  );
}

SettingsProfile.propTypes = {
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  tabs: PropTypes.node,
  userFields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      required: PropTypes.bool,
    })
  ),
};

export default withSnackbar(SettingsProfile);
