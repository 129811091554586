import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: "calc(100vw - 32px)",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  [theme.breakpoints.up("sm")]: {
    minWidth: 400,
    width: 400,
  },
}));

const StyledCardMedia = styled(CardMedia)({
  width: "100%",
  height: "100%",
  minHeight: 300,
  display: "flex",
  alignItems: "stretch",

  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    pointerEvents: "none",
    backgroundImage: `linear-gradient(transparent, rgba(16,16,16,0.2), rgba(16,16,16,0.8), #000)`,
  },
});

export default function PostItem({ post }) {
  const { t } = useTranslation("arena");
  return (
    <StyledCard>
      <CardActionArea
        sx={{ display: "flex", height: "100%" }}
        component={Link}
        to={post.url}
      >
        <StyledCardMedia image={post?.image?.url} alt={post.title}>
          <CardHeader
            title={post.title}
            titleTypographyProps={{
              variant: "h6",

              sx: { fontWeight: 600, m: 0, mb: 1, lineHeight: 1.3 },
            }}
            subheader={t("publishAt", { date: post.publishAt })}
            sx={{ position: "relative", mt: "auto" }}
          />
        </StyledCardMedia>
      </CardActionArea>
    </StyledCard>
  );
}

PostItem.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    publishAt: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
  }).isRequired,
};
