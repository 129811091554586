import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SponsorVideo from "core/components/SponsorVideo";
import LiveEvents from "core/components/LiveEvents";

const SHOW_AD = "SHOW_AD";

export default function SponsorLiveAdv({
  embedId,
  liveSeekBar,
  videoState,
  videoActions,
  content,
  rightholderId,
  platformId,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  fullscreenTarget,
  onChatAdv,
  impressionInterval,
  disabled,
  muted,
}) {
  const [playedTime, setPlayedTime] = useState(0);

  useEffect(() => {
    if (!process.browser) return;
    if (videoState.status !== "playing") return;

    const update = () => {
      setPlayedTime((t) => t + 1);
    };

    const timer = setInterval(update, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [videoState.status]);

  if (!videoState.rawProgramDateTime) return null;
  if (videoState.isEnded) return null;
  if (!videoState.isStarted) return null;
  if (!process.browser) return null;

  const onStart = () => {
    videoActions.toggleMute();
  };

  const onFinish = () => {
    setPlayedTime(0);

    videoActions.toggleMute();
  };

  const onNotFound = () => {
    setPlayedTime(0);
  };

  const onEvent = (e) => {
    if (e.event === SHOW_AD) {
      setPlayedTime(impressionInterval + 1);
    }
  };

  const videoPlace = "MIDROLL_IN_" + sponsorVideoPlace;
  const chatMessagePlace = sponsorChatMessagePlace
    ? "MIDROLL_IN_" + sponsorChatMessagePlace
    : null;

  const showVideoAd = playedTime > impressionInterval && !disabled;

  return (
    <>
      {liveSeekBar && (
        <LiveEvents
          key={liveSeekBar.offset}
          contentId={content.id}
          currentTime={videoState.rawProgramDateTime}
          onEvent={onEvent}
        />
      )}
      {showVideoAd && (
        <SponsorVideo
          shareUrl={content?.shareUrl}
          embedId={embedId}
          chatMessagePlace={chatMessagePlace}
          videoPlace={videoPlace}
          contentId={content.id}
          sportId={content?.sport?.id}
          rightholderId={rightholderId}
          platformId={platformId}
          fullscreenTarget={fullscreenTarget}
          onStart={onStart}
          onFinish={onFinish}
          onNotFound={onNotFound}
          onChatAdv={onChatAdv}
          muted={muted}
        />
      )}
    </>
  );
}

SponsorLiveAdv.propTypes = {
  embedId: PropTypes.string,
  muted: PropTypes.bool,
  disabled: PropTypes.bool,
  onChatAdv: PropTypes.func,
  liveSeekBar: PropTypes.shape({
    offset: PropTypes.number.isRequired,
  }),
  videoState: PropTypes.shape({
    status: PropTypes.oneOf(["playing", "paused"]).isRequired,
    rawProgramDateTime: PropTypes.string,
    isEnded: PropTypes.bool,
    isStarted: PropTypes.bool,
  }).isRequired,
  videoActions: PropTypes.shape({
    toggleMute: PropTypes.func.isRequired,
  }).isRequired,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    sport: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  rightholderId: PropTypes.string.isRequired,
  platformId: PropTypes.string,
  sponsorChatMessagePlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string.isRequired,
  chatMessagePlace: PropTypes.string,
  fullscreenTarget: PropTypes.string.isRequired,
  impressionInterval: PropTypes.number.isRequired,
};
