import moment from "core/utils/moment";
import { numberFormat } from "core/utils/string";

export default function interpolationFormat(value, format, lng) {
  moment.locale(lng);

  if (format === "forStream") {
    if (lng === "ru") {
      return moment(value).format("DD MMMM [в] HH:mm");
    }

    if (lng === "pl") {
      return moment(value).format("DD MMMM [o godzinie] HH:mm");
    }

    return moment(value).format("[on] MMMM[,] D YYYY [at] hh:mm A");
  }

  if (format === "dateMonth") {
    return moment(value).format("DD MMM YYYY");
  }

  if (format === "onlyDate") {
    return moment(value).format("D MMMM YYYY");
  }

  if (format === "short") {
    if (lng === "en") {
      return moment(value).format("MM/DD/YYYY hh:mm A");
    } else {
      return moment(value).format("DD.MM.YYYY HH:mm");
    }
  }

  if (format === "full") {
    if (lng === "en") {
      return moment(value).format("DD MMMM YYYY hh:mm A");
    } else {
      return moment(value).format("DD MMMM YYYY HH:mm");
    }
  }

  if (format === "time") {
    if (lng === "en") {
      return moment(value).format("hh:mm A");
    } else {
      return moment(value).format("HH:mm");
    }
  }

  if (format === "dateMonthFull") {
    if (lng === "en") {
      return moment(value).format("MMMM[,] D YYYY");
    } else {
      return moment(value).format("D MMMM YYYY");
    }
  }

  if (format === "scheduled") {
    if (lng === "ru") {
      return moment(value).format("DD MMMM [в] HH:mm");
    }

    if (lng === "pl") {
      return moment(value).format("DD MMMM HH:mm");
    }

    return moment(value).format("DD MMMM [at] hh:mm А");
  }

  if (format === "local") {
    return moment(value).format("LLL");
  }

  if (format === "fromNow") {
    return moment(value).fromNow(true); // 4 years
  }

  if (format === "fromNowAgo") {
    return moment(value).fromNow(); // 4 years ago
  }

  if (format === "numberFormat") {
    if (lng === "ru") {
      let si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: " тыс." },
        { value: 1e6, symbol: " млн" },
        { value: 1e9, symbol: " млрд" },
        { value: 1e12, symbol: " трлн" },
      ];

      return numberFormat(value, 1, si);
    } else {
      let si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
      ];

      return numberFormat(value, 1, si);
    }
  }
  return value;
}
