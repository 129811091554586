import React, { useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  createSponsorImpression,
  createSponsorClick,
  trackAdPixel,
} from "core/utils/tracker";
import ButtonBase from "@mui/material/ButtonBase";
import { getThumbnailSrcSet } from "core/utils/getSrcSet";
import { nanoid } from "nanoid";

const StyledButtonBase = styled(ButtonBase)({
  display: "block",
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  zIndex: 100,
});

const StyledImage = styled("img")({
  width: "100%",
  display: "block",
  margin: 0,
});

const SHOW_BANNER_TIMEOUT = 10000; // Через сколько мс закрывать баннер
const ANIMATION_DURATION = 1000; // Длительность анимации

const IMPRESSION = "IMPRESSION";
const DEFAULT = "DEFAULT";
const MEDIUM = "MEDIUM";
const HIGH = "HIGH";
const MAXRES = "MAXRES";

export default function SponsorInStreamBanner({
  onStart,
  onFinish,
  inStreamBanner,
  setInStreamBannerHeight,
  previewBanner,
}) {
  const imageRef = useRef();

  const onWindowResize = () => {
    if (imageRef.current) {
      setInStreamBannerHeight(imageRef.current.height);
    }
  };

  useEffect(() => {
    if (previewBanner) return null;

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [imageRef.current, setInStreamBannerHeight]);

  if (previewBanner) {
    const onLoad = () => {
      if (imageRef.current) {
        setInStreamBannerHeight(imageRef.current.height);
      }

      setTimeout(() => {
        setInStreamBannerHeight(null);
      }, SHOW_BANNER_TIMEOUT - ANIMATION_DURATION);
    };

    return (
      <StyledButtonBase>
        <StyledImage
          src={previewBanner}
          alt=""
          onLoad={onLoad}
          ref={imageRef}
        />
      </StyledButtonBase>
    );
  }

  const {
    id,
    thumbnails,
    link,
    token,
    isClickable,
    clickPixel,
    impressionPixel,
  } = inStreamBanner;

  const onClick = async (event) => {
    event.preventDefault();

    if (isClickable) {
      if (clickPixel) {
        trackAdPixel(clickPixel);
      }

      const clickId = nanoid();
      const url = link.replace("{clickId}", clickId);

      window.open(url, "_blank");

      await createSponsorClick(token, clickId);
    }
  };

  const onLoad = () => {
    createSponsorImpression(token, "In-Stream Banner", IMPRESSION);

    if (impressionPixel) {
      trackAdPixel(impressionPixel);
    }

    onStart();

    if (imageRef.current) {
      setInStreamBannerHeight(imageRef.current.height);
    }

    setTimeout(() => {
      setInStreamBannerHeight(null);
    }, SHOW_BANNER_TIMEOUT - ANIMATION_DURATION);

    setTimeout(() => {
      setInStreamBannerHeight(null);
      onFinish();
    }, SHOW_BANNER_TIMEOUT);
  };

  const srcSet = getThumbnailSrcSet(thumbnails);

  const src = thumbnails?.find((t) => t.format === MAXRES)?.url;

  return (
    <StyledButtonBase onClick={onClick} key={id}>
      <StyledImage
        src={src}
        srcSet={srcSet}
        alt=""
        onLoad={onLoad}
        ref={imageRef}
      />
    </StyledButtonBase>
  );
}

SponsorInStreamBanner.propTypes = {
  inStreamBanner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
    link: PropTypes.string,
    token: PropTypes.string.isRequired,
    isClickable: PropTypes.bool,
    clickPixel: PropTypes.string,
    impressionPixel: PropTypes.string,
  }),
  setInStreamBannerHeight: PropTypes.func,
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  previewBanner: PropTypes.string,
};
