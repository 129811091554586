import React from "react";
import PropTypes from "prop-types";
import ContentGrid from "arena/components/ContentGrid";
import ContentCarousel from "arena/components/ContentCarousel";
import ContentList from "arena/components/ContentList";
import HomepageTitle from "arena/components/HomepageTitle";
import HeaderMoreLink from "arena/components/HeaderMoreLink";
import LoadMore from "core/components/LoadMore";
import { useQuery } from "@apollo/client";
import { ArenaContentsQuery } from "arena/queries";
import ReactGA from "react-ga4";

const components = {
  CAROUSEL: ContentCarousel,
  GRID: ContentGrid,
  LIST: ContentList,
};

const STREAM = "STREAM";

export default function ArenaContents({
  blockId,
  productId,
  search,
  title,
  view,
  skip,
  sort,
  period,
  limit = 12,
  type,
  status,
  tagIds,
  xs,
  sm,
  md,
  lg,
  xl,
  noMore,
  onCompleted,
  relatedContentId,
  selectedContentId,
  loadMore,
  rightholderIds,
  inView,
}) {
  const variables = {
    search,
    sort,
    type,
    relatedContentId,
    productId,
    tagIds,
  };

  if (skip) {
    variables.skip = skip;
  }

  if (limit) {
    variables.limit = limit;
  }

  if (period) {
    variables.period = period;
  }

  if (type === STREAM) {
    variables.status = status;
  }

  if (rightholderIds?.length) {
    variables.rightholderIds = rightholderIds;
  }

  const { data, loading, fetchMore, called } = useQuery(ArenaContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables,
    onCompleted,
    skip: !inView,
  });

  const Component = components[view];

  let moreLink = null;

  if (!noMore) {
    if (sort === "LATEST") {
      if (data?.arenaContents?.count > limit) {
        const searchParams = new URLSearchParams();

        if (status) searchParams.set("status", status);
        if (period) searchParams.set("period", period);
        if (type) searchParams.set("type", type);
        if (blockId) searchParams.set("blockId", blockId);

        if (tagIds) {
          tagIds.forEach((tagId) => {
            searchParams.append("tagId", tagId);
          });
        }

        if (rightholderIds) {
          rightholderIds.forEach((rightholderId) => {
            searchParams.append("rightholderId", rightholderId);
          });
        }

        moreLink = `/contents?${searchParams.toString()}`;
      }
    }
  }

  const count = data?.arenaContents?.count || 0;
  const nodes = data?.arenaContents?.nodes || [];

  if (nodes.length === 0) {
    if (called) {
      if (!loading) {
        return null;
      }
    }

    // пустой блок, чтобы работала загрузка по доскролу
    return <div style={{ height: "50vh", width: "100%" }} />;
  }

  const hasMore = nodes.length < count;
  const showLoadMore = loadMore && hasMore && !loading && view !== "CAROUSEL";
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    ReactGA.event({
      category: "ArenaContents",
      action: "Fetch more contents",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        skip: nextPage * limit,
      },
    });
  };

  return (
    <>
      {title && (
        <HomepageTitle
          title={title}
          action={moreLink && <HeaderMoreLink url={moreLink} />}
        />
      )}

      <Component
        nodes={nodes}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        productId={productId}
        contentId={selectedContentId}
      />

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

ArenaContents.defaultProps = {
  xs: 12,
  sm: 6,
  md: 4,
};

ArenaContents.propTypes = {
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  search: PropTypes.string,
  title: PropTypes.string,
  view: PropTypes.string,
  tagIds: PropTypes.arrayOf(PropTypes.string),
  sort: PropTypes.string,
  period: PropTypes.number,
  limit: PropTypes.number,
  skip: PropTypes.number,
  type: PropTypes.string,
  status: PropTypes.string,
  noMore: PropTypes.bool,
  onCompleted: PropTypes.func,
  relatedContentId: PropTypes.string,
  blockId: PropTypes.string,
  productId: PropTypes.string,
  selectedContentId: PropTypes.string,
  loadMore: PropTypes.bool,
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  inView: PropTypes.bool,
};
