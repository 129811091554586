import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getMinPrice from "core/utils/getMinPrice";
import ProductPriceButton from "arena/components/ProductPriceButton";
import renderEventDate from "core/utils/renderEventDate";
import Chip from "@mui/material/Chip";
import DonationProgress from "arena/components/DonationProgress";

const DONATION = "DONATION";
const PPV = "PPV";
const SUBSCRIPTION = "SUBSCRIPTION";
const PREFIX = "ProductCarouselItem";

const classes = {
  root: `${PREFIX}-root`,
  actionArea: `${PREFIX}-actionArea`,
  cardHeader: `${PREFIX}-cardHeader`,
  media: `${PREFIX}-media`,
  content: `${PREFIX}-content`,
  icon: `${PREFIX}-icon`,
  iconButton: `${PREFIX}-iconButton`,
  iconButtonActive: `${PREFIX}-iconButtonActive`,
  active: `${PREFIX}-active`,
  composition: `${PREFIX}-composition`,
  li: `${PREFIX}-li`,
  footer: `${PREFIX}-footer`,
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
  oldPrice: `${PREFIX}-oldPrice`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    // width: "100%",
    display: "flex",
    alignItems: "flex-start",
    // backgroundColor: "#191919",
    backgroundColor: "rgba(255, 255, 255, 0.03)",
    // backgroundColor: "rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
    // "&:hover": {
    //   backgroundColor: "rgba(255, 255, 255, 0.05)",
    // },
    width: "100%",
    height: "100%",
    // display: "flex",
    // alignItems: "flex-start",
    flexDirection: "column",
    position: "relative",
  },

  // [`& .${classes.actionArea}`]: {
  //   width: "100%",
  //   height: "100%",
  //   display: "flex",
  //   alignItems: "flex-start",
  //   flexDirection: "column",
  //   position: "relative",
  // },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    flex: 1,
    alignItems: "flex-start",
  },

  [`& .${classes.media}`]: {
    width: "100%",
    position: "relative",
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundColor: "black",
  },

  [`& .${classes.content}`]: {
    width: "100%",
    marginBottom: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    right: 8,
    top: 8,
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.iconButton}`]: {
    color: "white",
    backgroundColor: "rgba(0,0,0,0.9)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:focus": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    "&:active": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
  },

  [`& .${classes.iconButtonActive}`]: {
    backgroundColor: "#e91e63",
    "&:hover": {
      backgroundColor: "#e91e63",
    },
    "&:focus": {
      backgroundColor: "#e91e63",
    },
    "&:active": {
      backgroundColor: "#e91e63",
    },
  },

  [`& .${classes.active}`]: {
    backgroundColor: "rgba(255,255,255,0.1)",
    color: "rgba(255,255,255,1)",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    "&:focus": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    "&:active": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },

  [`& .${classes.composition}`]: {
    margin: 0,
    padding: theme.spacing(2, 0),
  },

  [`& .${classes.li}`]: {
    marginBottom: "0em",
    listStyle: "none",
    position: "relative",
    margin: 0,
    padding: 0,
    paddingLeft: 20,
    "&:before": {
      content: '""',
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: "rgba(255,255,255,0.4)",
      fontWeight: "bold",
      display: "block",
      left: 0,
      top: "0.5em",
      position: "absolute",
    },
  },

  [`& .${classes.footer}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2, 2, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 3, 3),
    },
  },

  [`& .${classes.title}`]: {
    lineHeight: 1.2,
    fontWeight: 600,
  },
}));

export default function ProductCarouselItem({
  product,
  onClickPromoVideoButton,
}) {
  const { t } = useTranslation("arena");

  const minPrice = getMinPrice(product);
  const priceButton = (
    <ProductPriceButton
      product={product}
      price={minPrice}
      component={Link}
      to={product.siteUrl}
    />
  );

  const eventTime = renderEventDate({
    eventStartAt: product.eventStartAt,
    eventFinishAt: product.eventFinishAt,
    t,
  });

  return (
    <StyledCard className={classes.root} elevation={0}>
      <CardActionArea component={Link} to={product.siteUrl}>
        <CardMedia image={product?.preview?.url} className={classes.media} />
      </CardActionArea>
      <CardContent className={classes.content}>
        <Typography variant="h5" color="textPrimary" className={classes.title}>
          {product.title}
        </Typography>

        {eventTime && <Chip label={eventTime} sx={{ mt: 2 }} />}

        <ul className={classes.composition}>
          {product.composition.map((item, index) => (
            <Typography
              variant="body1"
              color="textPrimary"
              key={index}
              component="li"
              className={classes.li}
            >
              {item}
            </Typography>
          ))}
        </ul>

        {product.type === DONATION && <DonationProgress product={product} />}
      </CardContent>

      <Stack spacing={2} className={classes.footer}>
        {product.video && (
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            size="large"
            onClick={() => onClickPromoVideoButton(product)}
          >
            {t("watchTrailer")}
          </Button>
        )}

        {priceButton}
      </Stack>
    </StyledCard>
  );
}

ProductCarouselItem.propTypes = {
  onClickPromoVideoButton: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    eventStartAt: PropTypes.string,
    eventFinishAt: PropTypes.string,
    composition: PropTypes.arrayOf(PropTypes.string).isRequired,
    donationProgress: PropTypes.object,
    type: PropTypes.oneOf([DONATION, PPV, SUBSCRIPTION]).isRequired,
    video: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        preOrder: PropTypes.bool,
        oldValue: PropTypes.number,
        value: PropTypes.number.isRequired,
        period: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      })
    ),
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    preview: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
};
