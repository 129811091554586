import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ArenaArchive from "arena/components/ArenaArchive";
import ArenaButton from "arena/components/ArenaButton";
import ArenaBanner from "arena/components/ArenaBanner";
import ArenaProducts from "arena/components/ArenaProducts";
import ArenaHtml from "arena/components/ArenaHtml";
import ArenaText from "arena/components/ArenaText";
import ArenaMarkdown from "arena/components/ArenaMarkdown";
import ArenaCover from "arena/components/ArenaCover";
import ArenaCoverCarousel from "arena/components/ArenaCoverCarousel";
import ArenaTv from "arena/components/ArenaTv";
import ArenaContents from "arena/components/ArenaContents";
import ArenaContinueWatchingContents from "arena/components/ArenaContinueWatchingContents";
import ArenaDonationProgress from "arena/components/ArenaDonationProgress";
import ArenaPartners from "arena/components/ArenaPartners";
import ArenaPosts from "arena/components/ArenaPosts";
import ArenaFaq from "arena/components/ArenaFaq";
import ArenaReviews from "arena/components/ArenaReviews";
import ArenaRedirect from "arena/components/ArenaRedirect";
import { useInView } from "react-intersection-observer";

const LIST_BLOCK_TYPES = [
  "CONTENTS",
  "CONTINUE_WATCHING_CONTENTS",
  "PRODUCTS",
  "ARCHIVE",
];

const TEXT_BLOCK_TYPES = ["TEXT", "MARKDOWN"];

const COLORED_BLOCK_TYPES = ["TEXT", "MARKDOWN", "HTML", "BUTTON"];

const Section = styled("div", {
  shouldForwardProp: (prop) =>
    !["isList", "isText", "backgroundColor", "textColor", "isColored"].includes(
      prop
    ),
})(({ theme, isList, isText, backgroundColor, textColor, isColored }) => {
  const styles = {
    position: "relative",

    backgroundColor: isColored ? backgroundColor : undefined,
    color: isColored ? textColor : undefined,
  };

  if (isList) {
    Object.assign(styles, {
      overflow: "hidden",
      padding: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 3),
      },
    });
  }

  if (isText) {
    Object.assign(styles, {
      overflow: "hidden",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 3),
      },
    });
  }

  return styles;
});

const blockTypes = {
  ARCHIVE: ArenaArchive,
  PARTNERS: ArenaPartners,
  BANNER: ArenaBanner,
  PRODUCTS: ArenaProducts,
  HTML: ArenaHtml,
  TV_PLAYER: ArenaTv,
  CONTENTS: ArenaContents,
  TEXT: ArenaText,
  MARKDOWN: ArenaMarkdown,
  COVER: ArenaCover,
  COVER_CAROUSEL: ArenaCoverCarousel,
  POSTS: ArenaPosts,
  BUTTON: ArenaButton,
  FAQ: ArenaFaq,
  REVIEWS: ArenaReviews,
  CONTINUE_WATCHING_CONTENTS: ArenaContinueWatchingContents,
  DONATION_PROGRESS: ArenaDonationProgress,
  REDIRECT: ArenaRedirect,
};

export default function PageSection({ block, arena, index, first, last }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const Component = blockTypes[block.component];

  if (!Component) return "BLOCK NOT FOUND";

  const isList = LIST_BLOCK_TYPES.includes(block.component);
  const isText = TEXT_BLOCK_TYPES.includes(block.component);
  const isColored = COLORED_BLOCK_TYPES.includes(block.component);

  return (
    <Section
      isList={isList}
      isText={isText}
      isColored={isColored}
      backgroundColor={block.props.backgroundColor}
      textColor={block.props.textColor}
      id={block.id}
      key={block.id}
      ref={ref}
    >
      <Component
        arena={arena}
        {...block.props}
        blockId={block.id}
        index={index}
        first={first}
        last={last}
        inView={index < 2 || inView} // первые 2 блока на странице загружаем всегда, остальные по доскролу (тк они уже будут не в 1 экране)
      />
    </Section>
  );
}

PageSection.propTypes = {
  arena: PropTypes.object.isRequired,
  block: PropTypes.shape({
    id: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
    props: PropTypes.object.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
};
