import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ArenaLogosQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import HomepageTitle from "arena/components/HomepageTitle";
import ButtonBase from "@mui/material/ButtonBase";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useCarousel from "core/hooks/useCarousel";
import ArenaReview from "./ArenaReview";
import { alpha } from "@mui/system";

const PREFIX = "ArenaReviews";

const classes = {
  scrollable: `${PREFIX}-scrollable`,
  item: `${PREFIX}-item`,
  prev: `${PREFIX}-prev`,
  next: `${PREFIX}-next`,
  btnRoot: `${PREFIX}-btnRoot`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",

  [`& .${classes.scrollable}`]: {
    padding: theme.spacing(0, 2),

    overflowY: "hidden",
    overflowX: "auto",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 3),
    },
  },

  [`& .${classes.item}`]: {
    display: "flex",
  },

  [`& .${classes.prev}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      left: 0,
      backgroundImage: `linear-gradient(90deg, ${
        theme.palette.background.body
      }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.next}`]: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    // background: "#ffff0066",
    width: 0,
    height: "100%",
    pointerEvents: "none",
    "& button": {
      right: 0,
      backgroundImage: `linear-gradient(-90deg, ${
        theme.palette.background.body
      }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.btnRoot}`]: {
    pointerEvents: "all",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 60,
    backgroundColor: "transparent",
    color: "rgba(255, 255, 255, 0.3)",
    "&:hover": {
      color: "#fff",
    },

    [theme.breakpoints.up("sm")]: {
      width: 90,
    },
    [theme.breakpoints.up("md")]: {
      width: 100,
    },
  },

  [`& .${classes.icon}`]: {
    fontSize: 60,
  },
}));

const StyledHomepageTitle = styled(HomepageTitle)(({ theme }) => ({
  padding: theme.spacing(5, 2, 1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 3, 1),
  },
}));

const indexBy = (items) =>
  items.reduce((all, item) => {
    all[item.id] = item.url;
    return all;
  }, {});

export default function ArenaReviews({
  reviews,
  title,
  paddingTop,
  paddingBottom,
}) {
  const { data } = useQuery(ArenaLogosQuery, {
    variables: {
      ids: reviews.map((logo) => logo.photoId),
    },
  });

  const { rootRef, onScroll, prev, next, onClickNext, onClickPrev } =
    useCarousel();

  if (!data?.logos?.length) return <p>no logo</p>;

  const reviewImageUrlById = indexBy(data.logos);
  const nodes = reviews.map((review) => {
    return {
      id: review.photoId,
      text: review.text,
      author: review.author,
      position: review.position,
      src: reviewImageUrlById[review.photoId],
    };
  });

  return (
    <>
      <Root style={{ paddingTop, paddingBottom }}>
        {title && <StyledHomepageTitle title={title} />}
        <div className={classes.scrollable} ref={rootRef} onScroll={onScroll}>
          <Grid container spacing={3} wrap="nowrap">
            {nodes.map((review) => (
              <Grid item key={review.id} className={classes.item}>
                <ArenaReview review={review} />
              </Grid>
            ))}
          </Grid>
        </div>

        {prev && (
          <div className={classes.prev}>
            <ButtonBase
              classes={{ root: classes.btnRoot }}
              onClick={onClickPrev}
            >
              <ChevronLeftIcon className={classes.icon} />
            </ButtonBase>
          </div>
        )}
        {next && (
          <div className={classes.next}>
            <ButtonBase
              classes={{ root: classes.btnRoot }}
              onClick={onClickNext}
            >
              <ChevronRightIcon className={classes.icon} />
            </ButtonBase>
          </div>
        )}
      </Root>
    </>
  );
}

ArenaReviews.propTypes = {
  title: PropTypes.string,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      photoId: PropTypes.string.isRequired,
    })
  ),
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
