import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";

const PREFIX = "TagList";

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  chip: `${PREFIX}-chip`,
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    overflow: "hidden",
  },
  [`& .${classes.label}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.chip}`]: {
    textOverflow: "ellipsis",
    maxWidth: 260,
  },
});

export default function TagList({ tags, link }) {
  const nodes = tags;

  return (
    <Root className={classes.root}>
      <Grid container spacing={1}>
        {nodes.map((tag) => (
          <Grid item key={tag.id}>
            <Chip
              color="primary"
              label={tag.title}
              clickable
              component={Link}
              to={link(tag)}
              classes={{
                root: classes.chip,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Root>
  );
}

TagList.propTypes = {
  link: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};
