import "core/utils/polyfills";
import "core/utils/apm";
import "core/utils/i18n";

import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { useSSR } from "react-i18next";
import { HelmetProvider } from "react-helmet-async";
import { client } from "core/utils/apollo";
import LoadingScreen from "core/components/LoadingScreen";
import App from "arena/components/App";
import createEmotionCache from "core/utils/createEmotionCache";
import { CacheProvider } from "@emotion/react";
import { setUtmToLocalStorage, removeUtmOnPageLoad } from "core/utils/utm";

if (process.browser) {
  setUtmToLocalStorage();
  removeUtmOnPageLoad();
}

const cache = createEmotionCache();

const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);

  return (
    <CacheProvider value={cache}>
      <HelmetProvider>
        <Suspense fallback={<LoadingScreen />}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ApolloProvider>
        </Suspense>
      </HelmetProvider>
    </CacheProvider>
  );
};

const renderMethod = module.hot ? render : hydrate;

renderMethod(<BaseApp />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker
      .register("/arena-sw.js")
      .then(function (registration) {
        return console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      })
      .catch(function (err) {
        return console.log("ServiceWorker registration failed: ", err);
      });
  });
}
