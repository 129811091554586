import { useEffect } from "react";
import ReactGA from "react-ga4";

export default function usePageview(pathname, title) {
  useEffect(() => {
    if (!process.browser) return;

    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title,
    });
  }, [pathname, title, process.browser]);
}
