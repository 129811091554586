import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { useTranslation } from "react-i18next";
import { ChatMessagesQuery } from "core/queries";
import { CreateChatMessageMutation } from "core/mutations";
import {
  BanCreatedSubscription,
  BanDeletedSubscription,
} from "core/subscriptions";
import { useMutation, useSubscription } from "@apollo/client";
import { withSnackbar } from "notistack";

const StyledAppBar = styled(AppBar)({
  top: "auto",
  bottom: 0,
  zIndex: 2,
});

const ENTER_KEY = 13;
const MAX_MESSAGE_LENGTH = 200;

function ChatForm({
  chatRoom,
  rightholderId,
  enqueueSnackbar,
  viewer,
  onOpenSignIn,
}) {
  const { t } = useTranslation("core");

  const [message, setMessage] = useState("");

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const [createChatMessage, { loading }] = useMutation(
    CreateChatMessageMutation,
    {
      variables: {
        input: {
          chatRoomId: chatRoom.id,
          message,
        },
      },
      onError,
      optimisticResponse: {
        __typename: "Mutation",
        createChatMessage: {
          __typename: "ChatMessage",
          id: Date.now().toString(20),
          userId: viewer?.id,
          userDisplayName: viewer?.fullName,
          userAvatar: viewer?.photo?.url || null,
          userIsRightholder: chatRoom.viewerIsAdmin,
          userIsAdmin: viewer?.isAdmin,
          message,
          createdAt: new Date(),
          sponsorChatMessage: null,
        },
      },
      update: (proxy, { data: { createChatMessage } }) => {
        // Read the data from our cache for this query.
        const variables = {
          chatRoomId: chatRoom.id,
          limit: 30,
        };
        const data = proxy.readQuery({
          query: ChatMessagesQuery,
          variables,
        });
        // console.log('update', { data, createChatMessage });
        // Write our data back to the cache with the new comment in it
        proxy.writeQuery({
          query: ChatMessagesQuery,
          variables,
          data: Object.assign({}, data, {
            chatMessages: {
              ...data.chatMessages,
              count: data.chatMessages.count + 1,
              nodes: [createChatMessage, ...data.chatMessages.nodes],
            },
          }),
        });
      },
    }
  );

  const variables = viewer && {
    userId: viewer.id,
    rightholderId,
  };

  const banCreatedResult = useSubscription(BanCreatedSubscription, {
    skip: !process.browser || !viewer,
    variables,
  });

  const banDeletedResult = useSubscription(BanDeletedSubscription, {
    skip: !process.browser || !viewer,
    variables,
  });

  const onChange = (event) => {
    if (event.target.value.length < MAX_MESSAGE_LENGTH) {
      setMessage(event.target.value);
    }
  };
  const signIn = () => {
    if (!viewer) {
      if (onOpenSignIn) {
        onOpenSignIn();
      } else {
        if (document.querySelector(".js-sign-in")) {
          return document.querySelector(".js-sign-in").click();
        }
      }
    }
  };
  const onClick = () => {
    signIn();
  };

  const createMessage = () => {
    signIn();

    if (message.trim()) {
      createChatMessage();
      setMessage("");
      input.focus();
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      createMessage();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    createMessage();
  };

  let input = null;

  const disabled = !message.trim() || loading;

  let viewerBan = chatRoom.viewerBan;

  if (banCreatedResult.data) {
    viewerBan = banCreatedResult.data.banCreated;
  }

  if (viewerBan) {
    if (banDeletedResult.data) {
      if (banDeletedResult.data.banDeleted.id === viewerBan.id) {
        viewerBan = null;
      }
    }
  }

  return (
    <StyledAppBar
      color="default"
      position="absolute"
      elevation={5}
      component="form"
      onSubmit={onSubmit}
      onClick={onClick}
    >
      <Toolbar>
        {viewerBan ? (
          <Typography variant="caption">
            {t("chat.banned", {
              date: viewerBan.expiredAt,
            })}
          </Typography>
        ) : (
          <TextField
            inputRef={(n) => {
              input = n;
            }}
            fullWidth
            multiline
            variant="standard"
            minRows={1}
            maxRows={10}
            placeholder={t("chat.input")}
            value={message}
            onChange={onChange}
            InputProps={{
              style: { fontSize: 13 },
              onKeyDown,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    edge="end"
                    disabled={disabled}
                    size="large"
                  >
                    <SvgIcon>
                      <path d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z" />
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Toolbar>
    </StyledAppBar>
  );
}

ChatForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  onOpenSignIn: PropTypes.func,
  rightholderId: PropTypes.string.isRequired,
  chatRoom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    viewerIsAdmin: PropTypes.bool,
    viewerBan: PropTypes.shape({
      id: PropTypes.string.isRequired,
      expiredAt: PropTypes.string.isRequired,
    }),
  }),
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    photo: PropTypes.object,
    isAdmin: PropTypes.bool,
  }),
};

export default withSnackbar(ChatForm);
