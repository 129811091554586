import React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ContentListItem from "arena/components/ContentListItem";

function ContentList({ nodes, contentId, onClickItem, productId }) {
  return (
    <List disablePadding>
      {nodes.map((content) => (
        <ContentListItem
          key={content.id}
          selected={contentId === content.id}
          content={content}
          onClickItem={onClickItem}
          productId={productId}
        />
      ))}
    </List>
  );
}

ContentList.propTypes = {
  productId: PropTypes.string,
  onClickItem: PropTypes.func,
  contentId: PropTypes.string.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ContentList;
