import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Status from "site/components/Status";
import ErrorScreen from "core/components/ErrorScreen";
import { Helmet } from "react-helmet-async";
import BlockIcon from "@mui/icons-material/Block";

const Root = styled("div")({
  padding: 0,
  minHeight: "calc(90vh - 64px)",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function BanScreen() {
  const { t } = useTranslation("core");

  return (
    <Status code={404}>
      <Helmet>
        <title>{t("banScreen.title")}</title>
      </Helmet>

      <Root>
        <ErrorScreen
          icon={BlockIcon}
          title={t("banScreen.title")}
          text={t("banScreen.text")}
        />
      </Root>
    </Status>
  );
}
