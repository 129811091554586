import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ProductPayment from "arena/components/ProductPayment";
import ProductPurchase from "arena/components/ProductPurchase";
import ProductIcons from "arena/components/ProductIcons";
import { alpha } from "@mui/system";
import { useTranslation } from "react-i18next";
import renderEventDate from "core/utils/renderEventDate";
import Chip from "@mui/material/Chip";

const PREFIX = "ProductCover";

const classes = {
  root: `${PREFIX}-root`,
  mask: `${PREFIX}-mask`,
  avatar: `${PREFIX}-avatar`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  inner: `${PREFIX}-inner`,
  content: `${PREFIX}-content`,
  headerContent: `${PREFIX}-headerContent`,
  footerContent: `${PREFIX}-footerContent`,
  description: `${PREFIX}-description`,
};

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  // minHeight: "40vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  boxSizing: "border-box",
  marginTop: -64,
  marginBottom: -100,
  padding: "80px 16px 100px",

  [theme.breakpoints.up("sm")]: {
    padding: "80px 24px 100px",
  },

  [`& .${classes.mask}`]: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.7)",
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: 200,
      pointerEvents: "none",
      // backgroundImage:
      //   "linear-gradient(transparent, rgba(16,16,16,0.1), rgba(16,16,16,0.8), #101010)",
      backgroundImage: `linear-gradient(transparent, ${alpha(
        theme.palette.background.body,
        0.1
      )}, ${alpha(theme.palette.background.body, 0.5)}, ${
        theme.palette.background.body
      })`,
    },
  },

  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60,
  },

  [`& .${classes.title}`]: {
    fontWeight: 800,
    color: "white",
    textShadow: "0px 0px 20px black",
    fontSize: "1.8rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem",
    },
  },

  [`& .${classes.description}`]: {
    "& :first-of-type": {
      marginTop: 0,
    },
  },

  [`& .${classes.inner}`]: {
    position: "relative",
    maxWidth: 1200,
    width: "100%",
    // overflow: "hidden",
  },

  [`& .${classes.content}`]: {
    // padding: theme.spacing(0, 2),
    // [theme.breakpoints.up("sm")]: {
    //   padding: theme.spacing(0),
    // },
  },

  [`& .${classes.headerContent}`]: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.footerContent}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

function getStyle(product) {
  let banner = product?.backgroundPreview?.url;

  const style = {};

  if (banner) {
    style["backgroundImage"] = `url('${banner}')`;
  }

  return style;
}

export default function ProductCover({
  promocodeIds,
  setPromocodeIds,
  viewer,
  embed,
  product,
  loading,
  onClickHelpButton,
  onClickPriceButton,
}) {
  const { t } = useTranslation("arena");

  if (loading && !product) {
    return (
      <Root>
        <div className={classes.inner}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="textPrimary"
                className={classes.title}
                gutterBottom
              >
                <Skeleton variant="text" width={320} />
              </Typography>
              <Skeleton variant="text" width={260} />
              <Skeleton variant="text" width={280} />
              <Skeleton variant="text" width={240} />
            </Grid>

            <Grid item xs={12} md={5}>
              <Skeleton variant="rectangular" width={400} height={400} />
            </Grid>
          </Grid>
        </div>
      </Root>
    );
  }

  const style = getStyle(product);
  const viewerPurchase = product?.viewerPurchase;
  const showProductPayment = !viewerPurchase;
  const showProductPurchase = !!viewerPurchase && !product?.finished;
  const eventTime = renderEventDate({
    eventStartAt: product.eventStartAt,
    eventFinishAt: product.eventFinishAt,
    t,
  });

  const content = (
    <>
      {product.description && (
        <Grid item xs={12}>
          <Typography
            component="div"
            variant="body1"
            color="textPrimary"
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: product.html,
            }}
          />
        </Grid>
      )}
    </>
  );

  return (
    <Root style={style}>
      <div className={classes.mask} />
      <div className={classes.inner}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <div className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    component="h1"
                    variant="h3"
                    align="left"
                    color="textPrimary"
                    className={classes.title}
                  >
                    {product.title}
                  </Typography>
                </Grid>

                {eventTime && (
                  <Grid item xs={12}>
                    <Chip label={eventTime} />
                  </Grid>
                )}

                <Grid item xs={12} className={classes.headerContent}>
                  <Grid container spacing={3}>
                    {content}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={3} justifyContent="center">
              {showProductPayment && (
                <Grid item xs={12}>
                  <ProductPayment
                    promocodeIds={promocodeIds}
                    setPromocodeIds={setPromocodeIds}
                    viewer={viewer}
                    embed={embed}
                    product={product}
                    onClickHelpButton={onClickHelpButton}
                    onClickPriceButton={onClickPriceButton}
                  />
                </Grid>
              )}

              {showProductPurchase && (
                <Grid item xs={12}>
                  <ProductPurchase
                    product={product}
                    onClickHelpButton={onClickHelpButton}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ProductIcons />
          </Grid>
          <Grid item xs={12} className={classes.footerContent}>
            <div className={classes.content}>
              <Grid container spacing={3}>
                {content}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Root>
  );
}

ProductCover.propTypes = {
  onClickPriceButton: PropTypes.func,
  embed: PropTypes.bool,
  setPayment: PropTypes.func,
  viewer: PropTypes.object,
  promocodeIds: PropTypes.array,
  setPromocodeIds: PropTypes.func.isRequired,
  onClickHelpButton: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    video: PropTypes.object,
    description: PropTypes.string,
    html: PropTypes.string,
    shareUrl: PropTypes.string.isRequired,
    publishAt: PropTypes.string,
    eventStartAt: PropTypes.string,
    eventFinishAt: PropTypes.string,
    startAt: PropTypes.string,
    finishAt: PropTypes.string,
    sellerInfo: PropTypes.string,
    finished: PropTypes.bool,
    started: PropTypes.bool,
    viewerPurchase: PropTypes.object,
    backgroundPreview: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      sellerInfo: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    }),
    viewerPayment: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  loading: PropTypes.bool,
};
