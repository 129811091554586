import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import loadSDK from "core/utils/loadSDK";
import apm from "core/utils/apm";
import Grid from "@mui/material/Grid";
import formatCurrency from "core/utils/currency";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import { LinearProgress } from "@mui/material";

/* global Robokassa */
const SRC = "https://auth.robokassa.ru/Merchant/bundle/robokassa_iframe.js";
const ID = "robokassawidget";

/**
 * Компонент реализует подгрузку SDK робокассы и механизм создания платежа с помощью открытия iframe
 *
 * @param {Object} paymentData - Данные для создания платежа
 * @param {String} returnUrl - Адрес страницы на которую необходимо редиректнуть после закрытия айфрейма с формой платежа
 * @param {Object} amount - Сумма с валютой
 * @param {Object} recurrentDescComponent - Компонент описания подписки
 */
export default function RobokassaProcessor({
  recurrentDescComponent,
  paymentData,
  returnUrl,
  amount,
}) {
  const [loading, setLoading] = useState(true);

  const recurrent = paymentData.Recurring;
  const { t } = useTranslation("arena");

  const amountText = formatCurrency(amount.value, amount.currency);

  useEffect(() => {
    if (!process.browser) return;
    if (!paymentData) return;

    const handleClose = (e) => {
      if (e.data.action === "closeRobokassaFrame") {
        window.location = returnUrl;
      }
    };

    window.addEventListener("message", handleClose);

    loadSDK(SRC, ID)
      .then(() => {
        if (!recurrent) {
          return Robokassa.StartPayment(paymentData);
        } else {
          return setLoading(false);
        }
      })
      .catch((error) => {
        apm.captureError(error);
        console.log(error);
      });

    return () => {
      window.removeEventListener("message", handleClose);
    };
  }, [!!paymentData, returnUrl]);

  const onClick = () => {
    Robokassa.StartPayment(paymentData);
    setLoading(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {loading && <LinearProgress />}

        {recurrentDescComponent && (
          <Grid item xs={12}>
            {recurrentDescComponent}
          </Grid>
        )}

        {recurrent && (
          <Grid item xs={12}>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClick}
            >
              {t("stripePaymentForm.pay", { amount: amountText })}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

RobokassaProcessor.propTypes = {
  recurrentDescComponent: PropTypes.object,
  paymentData: PropTypes.object,
  returnUrl: PropTypes.string,
  amount: PropTypes.object.isRequired,
};
