import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SignInForm from "core/components/SignInForm";
import VerifyEmailForm from "core/components/VerifyEmailForm";
import { useApolloClient } from "@apollo/client";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const PREFIX = "SignInDialog";

const classes = {
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
  dividerVertical: `${PREFIX}-dividerVertical`,
  arena: `${PREFIX}-arena`,
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.content}`]: {
    overflow: "hidden",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.dividerVertical}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: -1,
  },

  [`& .${classes.arena}`]: {
    margin: "20px 0",
  },

  [`& .${classes.dialog}`]: {
    backdropFilter: "blur(12px)",
    backgroundColor: "rgba(0,0,0,.45)",
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  flexShrink: 0,
  flexGrow: 0,
  maxHeight: 54,
  maxWidth: 100,
  // width: "100%",
  borderRadius: 0,
  display: "block",
  margin: 0,
  border: 0,
  [theme.breakpoints.up("sm")]: {
    maxHeight: 64,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SignInDialog({ enqueueSnackbar, open, onClose, arena }) {
  const { t } = useTranslation("core");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [token, setToken] = useState();
  const [email, setEmail] = useState();
  const client = useApolloClient();

  const onError = (error) => {
    if (error.networkError) {
      if (error.networkError.result) {
        if (error.networkError.result.errors) {
          if (error.networkError.result.errors[0]) {
            if (
              error.networkError.result.errors[0].message.startsWith(
                'Variable "$email" got invalid value'
              )
            ) {
              return enqueueSnackbar(t("signIn.invalidEmail"), {
                variant: "error",
              });
            }
          }
        }
      }
    }

    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const onSignInSuccess = (data) => {
    if (data.signIn.authToken) {
      client.resetStore();
    } else {
      setToken(data.signIn.token);
      enqueueSnackbar(t("signIn.codeSent"), {
        variant: "success",
      });
    }
  };

  const onVerifyEmailSuccess = () => {
    client.resetStore();
    onClose();
  };

  const handleClose = () => {
    setToken(null);
    onClose();
  };

  return (
    <StyledDialog
      fullWidth
      // disableScrollLock
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      scroll="body"
    >
      <AppBar position="static" color="default">
        <Toolbar>
          {arena.logo ? (
            <StyledImage src={arena.logo.url} alt={arena.title} />
          ) : (
            <Typography variant="h5" className={classes.title}>
              {t("signIn.title")}
            </Typography>
          )}
          <Tooltip title={t("close")} arrow>
            <IconButton
              onClick={handleClose}
              edge="end"
              size="large"
              sx={{ ml: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      {open && (
        <DialogContent className={classes.content}>
          {token ? (
            <VerifyEmailForm
              onCompleted={onVerifyEmailSuccess}
              onError={onError}
              token={token}
              email={email}
            />
          ) : (
            <SignInForm
              onCompleted={onSignInSuccess}
              onError={onError}
              email={email}
              setEmail={setEmail}
              arena={arena}
            />
          )}
        </DialogContent>
      )}
    </StyledDialog>
  );
}

SignInDialog.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  arena: PropTypes.object,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withSnackbar(SignInDialog);
