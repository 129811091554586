import React from "react";
import Grid from "@mui/material/Grid";
import { ArenaPostsQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import PostItem from "./PostItem";
import LoadMore from "core/components/LoadMore";
import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import HomepageTitle from "arena/components/HomepageTitle";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useSearchParams, useLocation } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3),
  },
}));

export default function Posts() {
  const { t } = useTranslation("arena");
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const tagIds = searchParams.getAll("tagId");

  const page = 0;
  const rowsPerPage = 12;

  const { data, loading, fetchMore } = useQuery(ArenaPostsQuery, {
    variables: {
      tagIds,
      page,
      rowsPerPage,
    },
  });

  usePageview(location.pathname, "Posts");
  useScrollToTop();

  const count = data?.posts?.count || 0;
  const nodes = data?.posts?.nodes || [];

  if (!count) return null;

  const hasMore = nodes.length < count;
  const showLoadMore = hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / rowsPerPage);

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more posts",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  return (
    <Root>
      <HomepageTitle title={t("posts")} />

      <Helmet>
        <title>{t("posts")}</title>
      </Helmet>

      <Grid container spacing={3}>
        {nodes.map((post) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={post.id} container>
              <PostItem post={post} />
            </Grid>
          );
        })}
      </Grid>
      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </Root>
  );
}
