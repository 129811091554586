import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useArena } from "arena/components/ArenaProvider";

const PROFILE = "PROFILE";
const PAYMENT = "PAYMENT";
const AUTH = "AUTH";
const DEVICES = "DEVICES";
const PROMOCODES = "PROMOCODES";

function SettingsTabs({ tab, onChangeTab }) {
  const { t } = useTranslation("arena");
  const arena = useArena();

  const enableOauth =
    [
      arena.arenaAuth?.google?.enabled, //
      arena.arenaAuth?.apple?.enabled,
    ].filter(Boolean).length > 0;

  return (
    <Tabs value={tab} onChange={onChangeTab} indicatorColor="primary">
      <Tab label={t("settingsTabs.profile")} value={PROFILE} />
      <Tab label={t("settingsTabs.payment")} value={PAYMENT} />
      <Tab label={t("settingsTabs.promocodes")} value={PROMOCODES} />
      <Tab label={t("settingsTabs.devices")} value={DEVICES} />
      {enableOauth && <Tab label={t("settingsTabs.auth")} value={AUTH} />}
    </Tabs>
  );
}

SettingsTabs.propTypes = {
  tab: PropTypes.oneOf([PROFILE, PAYMENT, AUTH, DEVICES, PROMOCODES])
    .isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

export default withSnackbar(SettingsTabs);
