import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default function usePreviewBanner(key, value) {
  const location = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has(key)) {
      return value;
    }
  }, [location.search, key, value]);
}
