import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Grid from "@mui/material/Grid";
import DonationProgress from "arena/components/DonationProgress";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import {
  ArenaBannerQuery,
  FileQuery,
  ProductDonationProgressQuery,
} from "arena/queries";
import classNames from "classnames";
import ReactGA from "react-ga4";
import { getBannerSrcSet } from "core/utils/getSrcSet";
import { alpha } from "@mui/system";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PREFIX = "ArenaCover";

const classes = {
  image: `${PREFIX}-image`,
  inner: `${PREFIX}-inner`,
  left: `${PREFIX}-left`,
  center: `${PREFIX}-center`,
  right: `${PREFIX}-right`,
  first: `${PREFIX}-first`,
  last: `${PREFIX}-last`,
  textShadow: `${PREFIX}-textShadow`,
  title: `${PREFIX}-title`,
  header1: `${PREFIX}-header1`,
  header2: `${PREFIX}-header2`,
  header3: `${PREFIX}-header3`,
  subtitle1: `${PREFIX}-subtitle1`,
  subtitle2: `${PREFIX}-subtitle2`,
  subtitle3: `${PREFIX}-subtitle3`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  titleImage: `${PREFIX}-titleImage`,
  product: `${PREFIX}-product`,
  verticalTopDesktop: `${PREFIX}-verticalTopDesktop`,
  verticalCenterDesktop: `${PREFIX}-verticalCenterDesktop`,
  verticalBottomDesktop: `${PREFIX}-verticalBottomDesktop`,
  verticalTopMobile: `${PREFIX}-verticalTopMobile`,
  verticalCenterMobile: `${PREFIX}-verticalCenterMobile`,
  verticalBottomMobile: `${PREFIX}-verticalBottomMobile`,
};

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  height: "100vh",
  boxSizing: "border-box",
  display: "flex",
  padding: "80px 16px 120px",
  marginBottom: -100,
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
    padding: "100px 16px 160px",
  },
  // [theme.breakpoints.up("md")]: {
  //   padding: "180px 16px",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   padding: "200px 16px",
  // },
  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 200,
    pointerEvents: "none",
    // backgroundImage: `linear-gradient(transparent, rgba(16,16,16,0.1), rgba(16,16,16,0.8), ${theme.palette.background.body})`,
    backgroundImage: `linear-gradient(transparent, ${alpha(
      theme.palette.background.body,
      0.1
    )}, ${alpha(theme.palette.background.body, 0.5)}, ${
      theme.palette.background.body
    })`,
  },

  [`& .${classes.image}`]: {
    zIndex: -1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    "& img": {
      display: "block",
      objectPosition: "center center",
      objectFit: "cover",
      height: "auto",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        objectPosition: "center center !important",
      },
    },
    "&.left-image img": {
      objectPosition: "center right",
    },
    "&.center-image img": {
      objectPosition: "center center",
    },
    "&.right-image img": {
      objectPosition: "center left",
    },
  },

  [`& .${classes.inner}`]: {
    marginTop: "0",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },

  [`& .${classes.left}`]: {
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 150,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 150,
    },
  },

  [`& .${classes.center}`]: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  [`& .${classes.right}`]: {
    marginLeft: "auto",
    marginRight: 0,
    [theme.breakpoints.up("sm")]: {
      marginRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 150,
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 150,
    },
  },

  [`& .${classes.verticalTopDesktop}`]: {
    justifyContent: "flex-start",
  },

  [`& .${classes.verticalCenterDesktop}`]: {
    justifyContent: "center",
  },

  [`& .${classes.verticalBottomDesktop}`]: {
    justifyContent: "flex-end",
  },

  [`& .${classes.verticalTopMobile}`]: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },

  [`& .${classes.verticalCenterMobile}`]: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

  [`& .${classes.verticalBottomMobile}`]: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },

  [`&.${classes.first}`]: {
    marginTop: -54,
    [theme.breakpoints.up("sm")]: {
      marginTop: -64,
    },
  },

  [`&.${classes.last}`]: {
    marginBottom: 0,
  },

  [`& .${classes.textShadow}`]: {
    textShadow: "0px 0px 20px black",
  },

  [`& .${classes.title}`]: {
    lineHeight: 1.1,
  },

  [`& .${classes.header1}`]: {
    fontSize: "3rem",
    fontWeight: "800",
    lineHeight: "1.1",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "4.5rem",
    },
  },

  [`& .${classes.header2}`]: {
    fontSize: "2.5rem",
    fontWeight: "800",
    lineHeight: "1.1",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "4rem",
    },
  },

  [`& .${classes.header3}`]: {
    fontSize: "2rem",
    fontWeight: "800",
    lineHeight: "1.1",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3.5rem",
    },
  },

  [`& .${classes.subtitle1}`]: {
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.3",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem",
    },
  },

  [`& .${classes.subtitle2}`]: {
    fontSize: "0.9rem",
    fontWeight: "400",
    lineHeight: "1.4",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem",
    },
  },

  [`& .${classes.subtitle3}`]: {
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "1.5",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.1rem",
    },
  },

  [`& .${classes.small}`]: {
    fontWeight: 600,
  },

  [`& .${classes.medium}`]: {
    fontWeight: 600,
    fontSize: "1em",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem",
    },
  },

  [`& .${classes.large}`]: {
    fontWeight: 600,
    fontSize: "1em",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
  },

  [`& .${classes.titleImage}`]: {
    display: "block",
    maxWidth: "100%",
  },

  [`& .${classes.product}`]: {
    margin: "0 auto auto",

    width: "100%",
    maxWidth: 400,
  },
}));

const StyledVideo = styled("video")({
  zIndex: -1,
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  objectFit: "cover",
  background: "black",
  opacity: 0,
  transition: ".5s all",
});

const MAXRES = "MAXRES";

const justify = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

function useImage(imageId) {
  const { data } = useQuery(ArenaBannerQuery, {
    skip: !imageId,
    variables: {
      id: imageId,
    },
  });

  return data?.banner;
}

function useBanner(bannerId) {
  const { data } = useQuery(ArenaBannerQuery, {
    skip: !bannerId,
    variables: {
      id: bannerId,
    },
  });

  return data?.banner;
}

function useVideo(fileId) {
  const { data } = useQuery(FileQuery, {
    skip: !fileId,
    variables: {
      id: fileId,
    },
  });

  return data?.file;
}

function useProduct(productId) {
  const { data } = useQuery(ProductDonationProgressQuery, {
    skip: !productId,
    variables: {
      id: productId,
    },
  });

  return data?.product;
}

function getStyle(background) {
  const style = {};

  if (background) {
    if ("brightness" in background) {
      style["filter"] = `brightness(${background?.brightness})`;
    }
  }

  return style;
}

export default function ArenaCover({
  title,
  subTitle,
  buttons,
  background,
  align,
  verticalAlign,
  first,
  last,
  imageId,
  productId,
}) {
  const image = useImage(imageId);
  const banner = useBanner(background?.bannerId);
  const bannerMobile = useBanner(background?.bannerMobileId);
  const desktopVideo = useVideo(background?.desktopVideoId);
  const mobileVideo = useVideo(background?.mobileVideoId);
  const product = useProduct(productId);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const backgroundVideo = md ? desktopVideo : mobileVideo || desktopVideo;

  let defaultBanner = null;
  let bannerSrcSet = null;
  if (banner) {
    bannerSrcSet = getBannerSrcSet(banner);
    defaultBanner = banner.find((b) => b.format === MAXRES)?.url;
  }

  let bannerMobileSrcSet = null;
  if (bannerMobile) {
    bannerMobileSrcSet = getBannerSrcSet(bannerMobile);
    if (!defaultBanner) {
      defaultBanner = bannerMobile.find((b) => b.format === MAXRES)?.url;
    }
  }

  let imageSrc = null;
  let imageSrcSet = null;
  if (image) {
    imageSrc = image.find((b) => b.format === MAXRES)?.url;
    imageSrcSet = getBannerSrcSet(image);
  }

  const style = getStyle(background);

  const onClickButton = (event, button) => {
    ReactGA.event({
      category: "ArenaCover",
      action: "Click to button",
      label: button.text,
    });
  };

  const onCanPlay = (event) => {
    event.target.style.opacity = 1;
  };

  return (
    <Root
      className={classNames({
        [classes.first]: first,
        [classes.last]: last,
      })}
    >
      {defaultBanner && (
        <picture
          className={classNames(classes.image, `${align}-image`)}
          style={style}
        >
          {bannerMobileSrcSet && (
            <source
              media="(max-width: 599px)" // for mobile
              srcSet={bannerMobileSrcSet}
            />
          )}
          {bannerSrcSet && (
            <source
              media="(min-width: 600px)" // for desktop
              srcSet={bannerSrcSet}
            />
          )}
          <img
            src={defaultBanner}
            srcSet={bannerSrcSet || bannerMobileSrcSet}
            alt={title?.text}
          />
        </picture>
      )}

      {backgroundVideo && (
        <StyledVideo
          src={backgroundVideo.url}
          onCanPlay={onCanPlay}
          muted
          autoPlay
          playsInline
          loop
          style={style}
        />
      )}

      <div
        className={classNames({
          [classes.inner]: true,
          [classes.left]: align === "left",
          [classes.center]: align === "center",
          [classes.right]: align === "right",
          [classes.verticalTopMobile]: verticalAlign?.mobile === "top",
          [classes.verticalCenterMobile]: verticalAlign?.mobile === "center",
          [classes.verticalBottomMobile]: verticalAlign?.mobile === "bottom",
          [classes.verticalTopDesktop]: verticalAlign?.desktop === "top",
          [classes.verticalCenterDesktop]: verticalAlign?.desktop === "center",
          [classes.verticalBottomDesktop]: verticalAlign?.desktop === "bottom",
        })}
      >
        {product && (
          <div className={classes.product}>
            <Card
              sx={{
                backdropFilter: "blur(12px)",
                backgroundColor: "rgba(255,255,255,.05)",
              }}
            >
              <CardActionArea component={Link} to={product.siteUrl}>
                <AppBar
                  sx={{
                    backdropFilter: "blur(12px)",
                    backgroundColor: "rgba(0,0,0,.25)",
                  }}
                  color="default"
                  elevation={0}
                  position="static"
                >
                  <CardHeader
                    title={product.title}
                    titleTypographyProps={{ align: "center" }}
                  />
                </AppBar>
                <CardContent>
                  <DonationProgress product={product} />
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        )}

        <Grid
          container
          spacing={3}
          justifyContent={justify[align]}
          alignItems="center"
        >
          {imageSrc && (
            <Grid item>
              <img
                src={imageSrc}
                srcSet={imageSrcSet}
                className={classes.titleImage}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {title?.text && (
              <Typography
                align={align}
                color={title.color}
                gutterBottom
                className={classNames({
                  [classes.textShadow]: title.color === "textPrimary",
                  [classes.header1]: title.variant === "header1",
                  [classes.header2]: title.variant === "header2",
                  [classes.header3]: title.variant === "header3",
                  [classes.subtitle1]: title.variant === "subtitle1",
                  [classes.subtitle2]: title.variant === "subtitle2",
                  [classes.subtitle3]: title.variant === "subtitle3",
                })}
              >
                {title.text}
              </Typography>
            )}
            {subTitle?.text && (
              <Typography
                align={align}
                color={subTitle.color}
                className={classNames({
                  [classes.textShadow]: subTitle.color === "textPrimary",
                  [classes.header1]: subTitle.variant === "header1",
                  [classes.header2]: subTitle.variant === "header2",
                  [classes.header3]: subTitle.variant === "header3",
                  [classes.subtitle1]: subTitle.variant === "subtitle1",
                  [classes.subtitle2]: subTitle.variant === "subtitle2",
                  [classes.subtitle3]: subTitle.variant === "subtitle3",
                })}
              >
                {subTitle.text}
              </Typography>
            )}
          </Grid>
          {buttons
            .filter((button) => button.text)
            .map((button, i) => {
              const component = button?.url?.startsWith("/") ? Link : "a";
              return (
                <Grid item key={i}>
                  <Button
                    component={component}
                    to={button.url}
                    href={button.url}
                    variant={button.variant}
                    color={button.color}
                    size={button.size}
                    onClick={(event) => onClickButton(event, button)}
                    className={classNames({
                      [classes.small]: button.size === "small",
                      [classes.medium]: button.size === "medium",
                      [classes.large]: button.size === "large",
                    })}
                  >
                    {button.text}
                  </Button>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </Root>
  );
}
ArenaCover.defaultProps = {
  buttons: [],
};

ArenaCover.propTypes = {
  title: PropTypes.shape({
    variant: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  subTitle: PropTypes.shape({
    variant: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  verticalAlign: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  align: PropTypes.string,
  background: PropTypes.shape({
    bannerId: PropTypes.string,
    bannerMobileId: PropTypes.string,
    brightness: PropTypes.number,
    desktopVideoId: PropTypes.string,
    mobileVideoId: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  first: PropTypes.bool,
  last: PropTypes.bool,
  imageId: PropTypes.string,
  productId: PropTypes.string,
};
