import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function ArenaText({
  text,
  variant,
  color,
  align,
  paddingTop,
  paddingBottom,
  maxWidth,
}) {
  return (
    <Typography
      align={align}
      variant={variant}
      color={color}
      style={{ paddingTop, paddingBottom, maxWidth, margin: "auto" }}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
}

ArenaText.propTypes = {
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  align: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
