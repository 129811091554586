import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { useLocation } from "react-router-dom";
import getStreamPlaylistWithOffset from "core/utils/getStreamPlaylistWithOffset";

export const CastContext = React.createContext({ isSupported: false });

export function useCast() {
  return useContext(CastContext);
}

const SRC =
  "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";
const ID = "cast-sdk";

function loadSDK() {
  return new Promise((resolve, reject) => {
    const exists = document.getElementById(ID);
    const cast = window.cast;

    const loadCb = (isAvailable) => {
      if (isAvailable) {
        resolve();
      } else {
        reject(new Error("Not available"));
      }
    };

    if (exists && cast) {
      if (cast) {
        return resolve();
      } else {
        window["__onGCastApiAvailable"] = loadCb;
      }
    }

    const script = document.createElement("script");

    script.src = SRC;
    script.id = ID;

    window["__onGCastApiAvailable"] = loadCb;

    document.body.appendChild(script);

    script.onerror = reject;
  });
}

export default function CastProvider({ children }) {
  const [isSupported, setIsSupported] = useState(false);
  const [player, setPlayer] = useState();
  const [controller, setController] = useState();
  const [context, setContext] = useState();
  const [currentTime, setCurrentTime] = useState(0);
  const [currentVolume, setCurrentVolume] = useState(0);
  const [playerState, setPlayerState] = useState();
  const [nowSeek, setNowSeek] = useState(false);

  const mediaInfo = player?.mediaInfo;
  const session = context && context.getCurrentSession();

  const enabled = true;

  useEffect(() => {
    if (!process.browser) return;
    if (!enabled) return;

    loadSDK()
      .then(() => {
        const context = window.cast.framework.CastContext.getInstance();

        context.setOptions({
          receiverApplicationId:
            window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
          autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        });

        setIsSupported(true);
        setContext(context);

        return console.log("loaded cast api");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [enabled]);

  useEffect(() => {
    if (context) {
      context.addEventListener(
        window.cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        function (event) {
          if (event.castState === "CONNECTED") {
            initPlayer();
          }
        }
      );
    }
  }, [context]);

  useEffect(() => {
    if (controller) {
      let currentMedia;
      controller.addEventListener(
        window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
        function (event) {
          if (event.field === "currentTime") {
            setCurrentTime(event.value);
          } else if (event.field === "volumeLevel") {
            setCurrentVolume(event.value);
          } else if (event.field === "playerState") {
            setNowSeek(false);
            setPlayerState(event.value);

            if (event.value === "PLAYING") {
              const ms = session.getMediaSession();
              if (ms) {
                currentMedia = ms;
              }
            }

            if (event.value === "IDLE") {
              if (currentMedia?.idleReason === "FINISHED") {
                session.getSessionObj().stop();
                setPlayerState(null);
              }
            }
          }
        }
      );
    }
  }, [controller]);

  const initPlayer = () => {
    const newPlayer = new window.cast.framework.RemotePlayer();
    const newPlayerController =
      new window.cast.framework.RemotePlayerController(newPlayer);

    setPlayer(newPlayer);
    setController(newPlayerController);

    setCurrentTime(newPlayer.currentTime || 0);
    setCurrentVolume(newPlayer.volumeLevel || 0);
    setPlayerState(newPlayer.playerState);
  };

  const playMedia = ({
    hlsUrl,
    contentId,
    title,
    subtitle,
    preview,
    live,
    offset,
  }) => {
    const session = context && context.getCurrentSession();

    let url = hlsUrl.startsWith("http")
      ? hlsUrl
      : `${window.location.origin}${hlsUrl}`;

    if (live) {
      url = getStreamPlaylistWithOffset({
        playlist: url,
        offset: offset,
      });
    }

    const mediaInfo = new window.chrome.cast.media.MediaInfo(
      contentId,
      "application/x-mpegurl"
    );

    mediaInfo.contentUrl = url;

    mediaInfo.metadata = new window.chrome.cast.media.TvShowMediaMetadata();
    mediaInfo.metadata.title = title;
    mediaInfo.metadata.subtitle = subtitle;
    mediaInfo.metadata.images = [
      {
        url: preview,
      },
    ];

    if (live) {
      mediaInfo.streamType = window.chrome.cast.media.StreamType.LIVE;
      let currentTime = new Date() / 1000;
      mediaInfo.duration = -1;
      mediaInfo.startAbsoluteTime = currentTime - offset;
      mediaInfo.metadata.sectionStartAbsoluteTime = currentTime - offset;
      mediaInfo.metadata.sectionStartTimeInMedia = 0;
      mediaInfo.customData = {
        offset,
      };
    } else {
      mediaInfo.streamType = window.chrome.cast.media.StreamType.BUFFERED;
    }

    const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
    request.autoplay = true;

    session
      .loadMedia(request)
      .then(function () {
        initPlayer();

        return console.log("Load cast succeed");
      })
      .catch((error) => {
        console.log("Error loadMedia" + error);
      });
  };

  const startCast = (args) => {
    const session = context && context.getCurrentSession();
    if (!session) {
      console.log("Request cast session");
      window.cast.framework.CastContext.getInstance()
        .requestSession()
        .then(() => {
          return playMedia(args);
        })
        .catch((error) => {
          console.log("Error request cast session: " + error);
        });
    } else {
      playMedia(args);
    }
  };

  function playOrPause() {
    if (controller) {
      controller.playOrPause();
    }
  }

  function play() {
    if (player?.isPaused) {
      controller.playOrPause();
    }
  }

  function pause() {
    if (player?.isPaused === false) {
      controller.playOrPause();
    }
  }

  function muteOrUnmute() {
    if (controller) {
      controller.muteOrUnmute();
    }
  }

  function setVolumeLevel(volume) {
    if (controller) {
      player.volumeLevel = volume;
      controller.setVolumeLevel();
    }
  }

  function seek(time) {
    if (controller) {
      player.currentTime = time;
      controller.seek();
      setCurrentTime(time);
      setNowSeek(true);
    }
  }

  function requestSession() {
    if (context) {
      context.requestSession();
    }
  }

  const device = session?.getCastDevice();

  const value = {
    requestSession,
    startCast,
    isSupported,
    currentTime,
    duration: player?.duration,
    playerState,
    player,
    currentOffset: mediaInfo?.customData?.offset,
    currentContentId: mediaInfo?.contentId,
    playOrPause,
    idle: !playerState || playerState === "IDLE",
    isMuted: player?.isMuted,
    volume: currentVolume,
    muteOrUnmute,
    setVolumeLevel,
    device,
    play,
    pause,
    seek,
    nowSeek,
    enabled: true,
  };

  return <CastContext.Provider value={value}>{children}</CastContext.Provider>;
}

CastProvider.propTypes = {
  children: PropTypes.node,
};
