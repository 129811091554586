import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import LocalStorage from "core/utils/localstorage";
import Hls from "hls.js/dist/hls.light";
import classNames from "classnames";
import loadSDK from "core/utils/loadSDK";

/* global mux */

const lsApollo = new LocalStorage(`apollo`);

const PREFIX = "Video";

const classes = {
  video: `${PREFIX}-video`,
  blur: `${PREFIX}-blur`,
};

const StyledVideo = styled("video")({
  background: "black",
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  transition: "filter .3s linear",

  [`& .${classes.blur}`]: {
    filter: "blur(5px)",
  },
});

function log() {
  console.log("Video", ...arguments);
}

function getRange(range) {
  const result = [];

  for (let i = 0; i < range.length; i++) {
    result.push([range.start(i), range.end(i)]);
  }

  return result;
}

const ls = new LocalStorage("video-player");
const origin = typeof window !== "undefined" ? window?.location?.origin : "";

class Video extends Component {
  videoRef = React.createRef();

  state = {
    currentLevel: -1,

    isUserPause: false, // Пауза, которую инициировал пользователь (кликнул на паузу или хоткеем)
    isStarted: false,
    isEnded: false,
    isLoading: true,
    duration: 0,
    currentTime: 0,
    status: "paused",
    volume: 0,
    buffered: [],
    activeCues: [],
    textTracks: [],
  };

  static propTypes = {
    blur: PropTypes.bool,
    videoElementId: PropTypes.string,
    videoRef: PropTypes.object,
    mediaId: PropTypes.string,
    viewId: PropTypes.string,
    visible: PropTypes.bool,
    live: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    playsInline: PropTypes.bool,
    className: PropTypes.string,
    start: PropTypes.number,
    poster: PropTypes.string,
    autoPlay: PropTypes.bool,
    controls: PropTypes.bool,
    preload: PropTypes.string,
    volume: PropTypes.number,
    hlsUrl: PropTypes.string,
    children: PropTypes.func,
    onUpdate: PropTypes.func,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnded: PropTypes.func,
    onError: PropTypes.func,
    onUnmount: PropTypes.func,
    updateInterval: PropTypes.number,
    onCanPlay: PropTypes.func,
    onCanPlayThrough: PropTypes.func,
    onSeeked: PropTypes.func,
    onTimeUpdate: PropTypes.func,
    content: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      rightholder: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isMuxEnabled: PropTypes.bool,
      }).isRequired,
      captions: PropTypes.arrayOf(
        PropTypes.shape({
          language: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        })
      ),
    }),
  };

  static defaultProps = {
    updateInterval: 3000,
    sources: [],
    start: -1,
    playsInline: true,
    autoPlay: false,
    controls: false,
    preload: "auto",
    hlsOptions: {},
  };

  get fullHlsUrl() {
    return this.props.hlsUrl.startsWith("http")
      ? this.props.hlsUrl
      : `${origin}${this.props.hlsUrl}`;
  }

  get volume() {
    if ("volume" in this.props) {
      return this.props.volume;
    }
    return ls.getItem("volume") || 1;
  }

  /*************************************************************************
   * Componen lifecycle
   ************************************************************************/

  componentDidMount() {
    this.load();

    const muted =
      "muted" in this.props ? this.props.muted : ls.getItem("muted");

    this.setVolume(this.volume);

    if (muted) {
      this.mute();
    }

    this.updateInterval = setInterval(
      this.callListeners,
      this.props.updateInterval
    );

    window.addEventListener("unload", this.onWindowUnload);
  }

  componentDidUpdate(prevProps) {
    if (this.props.hlsUrl !== prevProps.hlsUrl) {
      if (this.hls) {
        this.hls.destroy();
      }

      this.load();
    }

    if (!prevProps.autoPlay && this.props.autoPlay) {
      this.play();
    }

    if (prevProps.autoPlay && !this.props.autoPlay) {
      this.pause();
    }
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);

    if (this.props.onUnmount) {
      this.props.onUnmount(this.videoState);
    }

    if (this.hls) {
      this.hls.destroy();
    }
  }

  /*************************************************************************
   * HLS JS Events
   ************************************************************************/

  load() {
    if (Hls.isSupported()) {
      this.hls = new Hls({
        // debug: document.location.hash === "#debug",
        startPosition: this.props.start || -1,
        capLevelToPlayerSize: true,
        capLevelOnFPSDrop: true,
        xhrSetup: (xhr) => {
          xhr.withCredentials = true; // do send cookies
        },
      });

      this.hls.on(Hls.Events.ERROR, this.handleHlsError);
      this.hls.on(Hls.Events.MEDIA_ATTACHED, this.handleHlsMediaAttached);
      this.hls.on(Hls.Events.MANIFEST_PARSED, this.handleHlsManifestParsed);
      this.hls.on(Hls.Events.LEVEL_SWITCHED, this.handleHlsLevelSwitched);
      this.hls.loadSource(this.fullHlsUrl);
      this.hls.attachMedia(this.player);
    } else if (this.player.canPlayType("application/vnd.apple.mpegurl")) {
      this.player.src = this.fullHlsUrl;

      const { start, live } = this.props;

      if (!live) {
        if (start) {
          this.player.currentTime = start;
        }
      }

      this.player.addEventListener("loadedmetadata", () => {
        if (live) {
          this.player.controller = new window.MediaController();
        }
        if (this.props.autoPlay || this.state.status === "playing") {
          this.play();
        }
      });
    }

    this.initTime = Date.now();

    this.initMuxMonitor();
    this.loadTextTracks();
  }

  initMuxMonitor = () => {
    const { content, viewId, hlsUrl, live } = this.props;

    if (!content) return;
    if (!live) return;

    const { rightholder } = content;

    if (!rightholder?.isMuxEnabled) return;

    const { hostname } = new URL(hlsUrl, "https://default");

    const viewerId = lsApollo.getItem("viewerId");
    const subPropertyId = content.rightholder.id;
    const videoId = content.id;
    const videoTitle = content.title;
    const pageType =
      window.location !== window.parent.location ? "iframe" : "watchpage";

    loadSDK("https://src.litix.io/core/4/mux.js")
      .then(() => {
        if (!mux) return console.log("mux not found");

        mux.monitor(this.player, {
          debug: false,
          disableCookies: true,
          // pass in the 'hls' instance and the 'Hls' constructor
          hlsjs: this.hls,
          Hls,
          data: {
            env_key: process.env.RAZZLE_MUX_ENV_KEY, // eslint-disable-line camelcase
            viewer_user_id: viewerId, // eslint-disable-line camelcase
            view_session_id: viewId, // eslint-disable-line camelcase
            page_type: pageType, // eslint-disable-line camelcase
            sub_property_id: subPropertyId, // eslint-disable-line camelcase
            player_init_time: this.initTime, // eslint-disable-line camelcase
            video_id: videoId, // eslint-disable-line camelcase
            video_title: videoTitle, // eslint-disable-line camelcase
            video_stream_type: "live", // eslint-disable-line camelcase
            video_cdn: hostname, // eslint-disable-line camelcase
          },
        });
        return;
      })
      .catch(console.log);
  };

  handleHlsLevelSwitched = (event, data) => {
    this.setState({ currentLevel: data.level });
  };

  loadTextTracks = () => {
    const textTracks = Array.from(this.player.textTracks).map((track) => ({
      mode: track.mode,
      language: track.language,
      label: track.label,
      kind: track.kind,
    }));

    this.setState({ textTracks });

    this.player.textTracks.onchange = () => {
      // console.log("onchange", event);
      Array.from(this.player.textTracks).forEach((track) => {
        if (track.mode === "disabled") {
          track.oncuechange = null;
        } else {
          track.mode = "hidden";
          track.oncuechange = (event) => {
            const activeCues = Array.from(event.target.activeCues).map(
              (c) => c.text
            );
            this.setState({ activeCues });
            // console.log("oncuechange", activeCues);
          };
        }
      });

      const textTracks = Array.from(this.player.textTracks).map((track) => ({
        mode: track.mode,
        language: track.language,
        label: track.label,
        kind: track.kind,
      }));

      this.setState({ textTracks });
    };
  };

  setTextTrack = (language) => {
    this.setState({ activeCues: [] });

    Array.from(this.player.textTracks).forEach((track) => {
      if (track.language === language) {
        track.mode = "hidden";
      } else {
        track.mode = "disabled";
      }
    });

    if (language) {
      ls.setItem("captions", language);
    } else {
      ls.removeItem("captions");
    }
  };

  /**
   * @param {String} event
   * @param {Object} data
   */
  handleHlsManifestParsed = (/* event, data */) => {
    const { autoPlay } = this.props;
    const { status } = this.state;

    if (this.hls.levels[this.state.currentLevel]) {
      this.hls.loadLevel = this.state.currentLevel;
    }

    if (autoPlay || status === "playing") {
      this.play();
    }
  };

  handleHlsMediaAttached = () => {};

  /**
   * Обработчик ошибок hls.js
   */
  handleHlsError = (error, data) => {
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          log("fatal network error encountered, try to recover");
          this.hls.startLoad();
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          log("fatal media error encountered, try to recover");
          this.hls.recoverMediaError();
          break;
        default:
          log("fatal unknown error encountered, cannot recover");
          this.hls.destroy();
          break;
      }
    }

    log("handleHlsError", error, data);

    if (this.props.onError) {
      this.props.onError();
    }
  };

  /*************************************************************************
   * Video Events
   ************************************************************************/

  /**
   * Fires when an error occurred during the loading of an audio/video
   *
   * Это обработчик ошибок на элементе video. Если ошибка случается в Safari, то hlsjs не используется.
   *
   */
  onError = (event) => {
    log("onError", event.nativeEvent, this.player);
    this.setVideoState({ isLoading: false, status: "error" });

    if (this.props.onError) {
      this.props.onError();
    }
  };

  /**
   * Fires when the loading of an audio/video is aborted
   */
  onAbort = () => {
    // log("onAbort");
    this.setVideoState();
  };

  /**
   * Fires when the browser can start playing the audio/video
   */
  onCanPlay = () => {
    // log("onCanPlay");
    this.setVideoState({
      isLoading: false,
    });

    if (this.props.onCanPlay) {
      this.props.onCanPlay();
    }
  };

  /**
   * Fires when the browser can play through the audio/video without stopping for buffering
   */
  onCanPlayThrough = () => {
    // log("onCanPlayThrough");
    this.setVideoState();

    if (this.props.onCanPlayThrough) {
      this.props.onCanPlayThrough();
    }
  };

  /**
   * Fires when the duration of the audio/video is changed
   */
  onDurationChange = () => {
    // log("onDurationChange");
    this.setVideoState();
  };

  /**
   * Fires when the current playlist is ended
   */
  onEnded = () => {
    // log("onEnded");
    this.setVideoState({ isEnded: true });
    this.player.currentTime = 0;
    if (this.props.onEnded) {
      this.props.onEnded(this);
    }
  };

  /**
   * Fires when the browser has loaded the current frame of the audio/video
   */
  onLoadedData = () => {
    // log("onLoadedData");
    this.setVideoState();
  };

  /**
   * Fires when the browser has loaded meta data for the audio/video
   */
  onLoadedMetadata = () => {
    // log("onLoadedMetadata");
    this.setVideoState({ isLoading: false });
  };

  /**
   * Fires when the browser starts looking for the audio/video
   */
  onLoadStart = () => {
    // log("onLoadStart");
    this.setVideoState();
  };

  /**
   * Fires when the audio/video has been pauseds
   */
  onPause = () => {
    // log("onPause");
    this.setVideoState({ status: "paused" });

    if (this.props.onPause) {
      this.props.onPause();
    }
  };

  /**
   * Fires when the audio/video has been started or is no longer paused
   */
  onPlay = () => {
    // // log("onPlay");
    // this.setVideoState({
    //   status: "playing",
    //   isEnded: false,
    //   isStarted: true,
    //   isUserPause: false
    // });
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  /**
   * Fires when the audio/video is playing after having been paused or stopped for buffering
   */
  onPlaying = () => {
    // log("onPlaying");
    this.setVideoState({ status: "playing", isUserPause: false });
  };

  /**
   * Fires when the browser is downloading the audio/video
   */
  onProgress = () => {
    // log("onProgress");
    this.setVideoState();
  };

  /**
   * Fires when the playing speed of the audio/video is changed
   */
  onRateChange = () => {
    // log("onRateChange");
    this.setVideoState();
  };

  /**
   * Fires when the user is finished moving/skipping to a new position in the audio/video
   */
  onSeeked = () => {
    // log("onSeeked");
    if (this.props.onSeeked) {
      this.props.onSeeked(this.videoState);
    }
    this.setVideoState();
  };

  /**
   * Fires when the user starts moving/skipping to a new position in the audio/video
   */
  onSeeking = () => {
    // log("onSeeking");
    this.setVideoState();
  };

  /**
   * Fires when the browser is trying to get media data, but data is not available
   */
  onStalled = () => {
    // log("onStalled");
    this.setVideoState();
  };

  /**
   * Fires when the browser is intentionally not getting media data
   */
  onSuspend = () => {
    // log("onSuspend");
    this.setVideoState();
  };

  /**
   * Fires when the current playback position has changed
   */
  onTimeUpdate = () => {
    // log("onTimeUpdate");
    if (this.props.onTimeUpdate) {
      this.props.onTimeUpdate(this.videoState);
    }
    this.setVideoState();
  };

  /**
   * Fires when the volume has been changed
   */
  onVolumeChange = ({ target: { volume } }) => {
    // log("onVolumeChange");
    this.setVideoState();
    ls.setItem("volume", volume);
  };

  /**
   * Fires when the video stops because it needs to buffer the next frame
   */
  onWaiting = () => {
    // log("onWaiting");
    this.setVideoState({ isLoading: true });
  };

  callListeners = () => {
    if (!this.props.onUpdate) return null;
    if (this.state.status !== "playing") return null;

    this.props.onUpdate(this.videoState);
  };

  play = () => {
    // log("play");
    if (this.player) {
      const promise = this.player.play();
      // console.log("Video.play()", {
      //   muted: this.player.muted,
      //   autoPlay: this.props.autoPlay
      // });

      if (promise !== undefined) {
        promise
          .then(() => {
            this.setVideoState({
              status: "playing",
              isEnded: false,
              isStarted: true,
              isUserPause: false,
            });
            // Automatic playback started!
            // Show playing UI.

            return;
          })
          .catch((error) => {
            // console.log("Video.play()", error.name, error.message);
            // console.dir(error);

            if (["AbortError", "NotAllowedError"].includes(error.name)) {
              console.log(
                "Video: Политика браузера не разрешает воспроизводить видео со звуком, отключаю звук"
              );

              if (!this.player) return; // колбек может сработать когда плеера уже нет

              this.mute();

              const p = this.player.play();

              if (p !== undefined) {
                // eslint-disable-next-line promise/no-nesting
                p.then(() => {
                  this.setVideoState({
                    status: "playing",
                    isEnded: false,
                    isStarted: true,
                    isUserPause: false,
                  });
                  // Automatic playback started!
                  // Show playing UI.

                  return;
                }).catch(() => {
                  // console.log(error);
                  console.log("Не удалось воспроизвести видео даже без звука");
                });
              }
            }

            // Auto-play was prevented
            // Show paused UI.
          });
      } else {
        this.setVideoState({
          status: "playing",
          isEnded: false,
          isStarted: true,
          isUserPause: false,
        });
      }
    }
  };

  pause = () => {
    // log("pause");
    if (this.player) {
      this.player.pause();
    }
  };

  togglePlay = () => {
    // log("togglePlay");
    if (this.player) {
      return this.state.status === "playing" ? this.pause() : this.play();
    }
  };

  togglePlayUser = () => {
    // log("togglePlayUser");
    if (this.state.status === "playing") {
      this.setState({ isUserPause: true });
    }
    this.togglePlay();
  };

  navigate = (currentTime) => {
    // log("navigate", currentTime);
    this.setState({ currentTime });
    if (this.player) {
      this.player.currentTime = currentTime;
    }
  };

  setVolume = (volume) => {
    // log("setVolume", volume);
    this.setState({ volume });
    if (this.player) {
      this.player.volume = volume;
    }
  };

  mute = () => {
    // console.log("Video.mute()", { muted: true });
    // log("mute");
    if (this.player) {
      this.player.muted = true;
    }
    // ls.setItem("muted", true);
  };

  unmute = () => {
    // console.log("Video.unmute()", { muted: false });
    // log("unmute");
    if (this.player) {
      this.player.muted = false;
    }
    // ls.setItem("muted", false);
  };

  toggleMute = () => {
    // log("toggleMute");
    const { volume, muted } = this.state;
    // console.log("Video.toggleMute()", { muted });

    if (volume > 0 && !muted) {
      this.mute();
      ls.setItem("muted", true);
    } else {
      this.unmute();
      ls.setItem("muted", false);
    }
  };

  /**
   * Установить качество воспроизведения
   *
   * @param {Number} index - Индекс качества (из hls.levels)
   */
  setLevel = (index) => {
    this.hls.loadLevel = index;
    this.setState({ currentLevel: index });
  };

  setVideoState = (state) => {
    if (!this.player) return null;

    const {
      controls,
      volume,
      muted,
      ended,
      error,
      networkState,
      currentTime,
      playbackRate,
      duration,
      paused,
      readyState,
      seeking,
      played,
      src,
      buffered,
    } = this.player;

    const calculatedPlayed = this.calculatePlayed();

    this.setState({
      controls,
      currentTime,
      duration: Number(duration) || 0,
      ended,
      networkState,
      playbackRate,
      error,
      volume,
      src,
      paused,
      played: getRange(played),
      readyState,
      seeking,
      muted,
      buffered: getRange(buffered),
      calculatedPlayed,
      ...state,
    });
  };

  calculatePlayed() {
    if (!this.props.live) {
      return null;
    }

    /**
     * Попробуем всегда считать
     */
    // if (this.hls) {
    //   return null;
    // }

    const isPlayed = this.player?.controller?.playbackState === "playing";

    const now = Date.now();

    const {
      played = [0, 0],
      lastCalc = Date.now(),
      prevIsPlayed = false,
    } = this.state.calculatedPlayed || {};

    /**
     * Добавляем время в calculatedPlayed если видео "проигрывается"
     * и прошлый стейт тоже был "проигрывается"
     */
    if (isPlayed && prevIsPlayed) {
      played[1] += (now - lastCalc) / 1000;
    }

    return {
      played,
      lastCalc: now,
      prevIsPlayed: isPlayed,
    };
  }

  get videoState() {
    const {
      currentLevel,
      currentTime,
      duration,
      ended,
      networkState,
      playbackRate,
      error,
      volume,
      src,
      paused,
      played,
      readyState,
      seeking,
      muted,
      buffered,
      isUserPause,
      isLoading,
      isStarted,
      isEnded,
      status,
      activeCues,
      textTracks,
      calculatedPlayed,
    } = this.state;

    const { live } = this.props;

    const levels = this?.hls?.levels;

    const autoLevelEnabled = Boolean(this?.hls?.autoLevelEnabled);

    const rawProgramDateTime =
      this?.hls?.streamController?.fragPlaying?.rawProgramDateTime;

    /**
     * В прямой трансляции в нативном плеере некорректно отдается played
     * Отдаем рассчитанный массив
     */
    const resultPlayed =
      live && calculatedPlayed?.played ? [calculatedPlayed.played] : played;

    return {
      rawProgramDateTime,
      autoLevelEnabled,
      currentLevel,
      levels,
      live,
      textTracks,
      activeCues,
      currentTime,
      duration,
      ended,
      networkState,
      playbackRate,
      error,
      volume,
      src,
      paused,
      played: resultPlayed,
      readyState,
      seeking,
      muted,
      buffered,
      isUserPause,
      isStarted,
      isEnded,
      isLoading,
      status,
    };
  }

  // Properties

  get player() {
    return this.videoRef.current;
  }

  get actions() {
    const {
      play,
      pause,
      togglePlay,
      togglePlayUser,
      navigate,
      setVolume,
      mute,
      unmute,
      toggleMute,
      setLevel,
      setTextTrack,
    } = this;

    return {
      togglePlayUser,
      togglePlay,
      play,
      pause,
      navigate,
      setVolume,
      mute,
      unmute,
      toggleMute,
      setLevel,
      setTextTrack,
    };
  }

  render() {
    const {
      loop,
      children,
      controls,
      preload,
      className,
      poster,
      playsInline,
      muted,
      autoPlay,
      blur,
      content,
    } = this.props;

    const tracks =
      content?.captions?.map((caption) => ({
        srcLang: caption.language,
        label: caption.title,
        src: caption.file.url,
      })) || [];

    return children(
      <StyledVideo
        data-testid="Video"
        crossOrigin="anonymous"
        muted={muted}
        loop={loop}
        playsInline={playsInline}
        poster={poster}
        ref={this.videoRef}
        preload={preload}
        controls={controls}
        className={classNames(className, {
          [classes.blur]: blur,
        })}
        autoPlay={autoPlay}
        onAbort={this.onAbort}
        onCanPlay={this.onCanPlay}
        onCanPlayThrough={this.onCanPlayThrough}
        onDurationChange={this.onDurationChange}
        onEnded={this.onEnded}
        onError={this.onError}
        onLoadedData={this.onLoadedData}
        onLoadedMetadata={this.onLoadedMetadata}
        onLoadStart={this.onLoadStart}
        onPause={this.onPause}
        onPlay={this.onPlay}
        onPlaying={this.onPlaying}
        onProgress={this.onProgress}
        onRateChange={this.onRateChange}
        onSeeked={this.onSeeked}
        onSeeking={this.onSeeking}
        onStalled={this.onStalled}
        onSuspend={this.onSuspend}
        onTimeUpdate={this.onTimeUpdate}
        onVolumeChange={this.onVolumeChange}
        onWaiting={this.onWaiting}
      >
        {tracks.map((track) => (
          <track
            key={track.srcLang}
            label={track.label}
            kind="subtitles"
            srcLang={track.srcLang}
            src={track.src}
            default={track.srcLang.toLowerCase() === "en"}
          />
        ))}
      </StyledVideo>,
      this.videoState,
      this.actions,
      this.videoRef
    );
  }
}

export default Video;
