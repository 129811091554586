import React from "react";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga4";
import { createTheme } from "core/utils/theme";
import { useQuery } from "@apollo/client";
import { ArenaQuery } from "arena/queries";
import LoadingScreen from "core/components/LoadingScreen";
import ErrorScreen from "core/components/ErrorScreen";
import ViewerProvider from "core/components/ViewerProvider";
import ArenaProvider from "arena/components/ArenaProvider";
import AppRoutes from "arena/components/AppRoutes";
import AppHelmet from "arena/components/AppHelmet";
import CookieBanner from "arena/components/CookieBanner";
import { useTranslation } from "react-i18next";
import CastProvider from "core/components/CastProvider";
import RetargetPixels from "core/components/RetargetPixels";
import { isBellator } from "core/utils/hardcode";

const anchorOrigin = {
  vertical: "top",
  horizontal: "center",
};

let initialized = false;

/* global cbsoptanon */

function initializeGa(arena) {
  if (!arena) return;
  if (!arena.gaId) return;
  if (initialized) return;
  if (!process.browser) return;

  if (isBellator(arena?.id)) {
    if (typeof cbsoptanon !== "undefined") {
      const check = () => {
        if (initialized) return;

        cbsoptanon.cmd.push((cmp) => {
          cmp.ot.performanceAllowed((allowed) => {
            if (initialized) return;
            if (!allowed) return;

            console.log("initialize GA");
            ReactGA.initialize(arena.gaId);
            initialized = true;
          });
        });
      };

      cbsoptanon.onScriptsReady((cmp) => {
        cmp.ot.awaitInitialConsent(check);
      });

      cbsoptanon.cmd.push((cmp) => {
        cmp.ot.addOnConsentChangedHandler(check);
      });
    }
  } else {
    ReactGA.initialize(arena.gaId);
    initialized = true;
  }
}

export default function App() {
  const { i18n } = useTranslation();

  const { data, loading, error } = useQuery(ArenaQuery, {
    onCompleted(data) {
      if (data.arena) {
        i18n.changeLanguage(data.arena.language);
      }
    },
  });

  initializeGa(data?.arena);

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen error={error} />;

  const { arena } = data;

  if (!arena) {
    return <ErrorScreen title="Arena not found" code={404} />;
  }

  const theme = createTheme(arena.theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppHelmet arena={arena} />
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={anchorOrigin}
        autoHideDuration={2000}
      >
        <ViewerProvider>
          <ArenaProvider value={arena}>
            <CastProvider>
              <AppRoutes />
              <RetargetPixels eventType="PageView" />
            </CastProvider>
          </ArenaProvider>
          <CookieBanner arena={arena} />
        </ViewerProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
